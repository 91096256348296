/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import AddInsuranceToCartPopup from "../../components/molecules/addInsuranceToCartPopup";
import BlackFilter from "../../components/molecules/blackFilter";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { GetApiTermDeathInsurancesInsuranceCode200Response } from "../../openApi/api";
import { useTermDeathInsuranceDetail } from "../../query/useTermDeathInsuranceDetail";
import "../../styles/pages/insuranceDetail.scss";
import {
  cartStorage,
  deathPDStorage,
  focusedInsuranceStorage,
  partnerDataStorage,
  searchConditionStorage,
} from "../../utils/storage";
import { InsuranceRankResponseInner } from "./comparison";
import EstimateResult from "./components/EstimateResult";
import { Header } from "./components/Header";
import { formatTextWithLineBreaks } from "./components/comparisonTable/utils";
import { addInsuranceToCart } from "./utils";
// import { TERM_DEATH_BENEFIT_OPTIONS } from "./components/comparisonTable/DropdownItem";
import { jpNumFormatter } from "../../utils/formatter";
import SalesPolicy from "../../components/molecules/SalesPolicy";

export type TermDeathInsuranceDetailProps = {
  totalInsuranceFee: number;
  age: number;
  sex: number;
  memo1: string;
  memo2: string;
  memo3: string;
  fee_memos: Array<string>;
  product_name: string;
  insurance_fee_codes: Array<string>;
  is_disp: boolean;
};

const TermDeathInsuranceDetail = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const fromComparison = queryParameters.get("fromComparison");
  const queryAge = queryParameters.get("age");
  const querySex = queryParameters.get("sex");
  const queryInsuranceFee = queryParameters.get("insurance_fee");
  // const queryBenefit = queryParameters.get("benefit");
  
  const queryMemo0 = queryParameters.get("memo0");
  const queryMemo1 = queryParameters.get("memo1");
  const queryMemo2 = queryParameters.get("memo2");
  const queryMemo3 = queryParameters.get("memo3");
  const queryMemo4 = queryParameters.get("memo4");
  const queryMemo5 = queryParameters.get("memo5");

  const cart = useMemo(() => cartStorage.get(), []);
  const deathDetailSerachCondition = useMemo(() => deathPDStorage.get(), []);

  const focusedInsurance = useMemo(() => focusedInsuranceStorage.get(), []);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isCartOpen, setIsCartOpen] = useState<boolean>(false);
  const [insuranceListInCart, setInsuranceListInCart] = useState<
    InsuranceRankResponseInner[]
  >(cart?.insuranceList ?? []);
  const [insuranceIdListInCart, setInsuranceIdListInCart] = useState<string[]>(
    cart?.insuranceIdList ?? []
  );
  const { insurance_code } = useParams();
  useDocumentTitle("保デジWEB-商品詳細");

  const [response, setResponse] =
    useState<GetApiTermDeathInsurancesInsuranceCode200Response>({});
  const { refetch: getTermDeathInsuranceDetail } = useTermDeathInsuranceDetail(
    insurance_code ?? "dummyCode",
    setResponse
  );

  useEffect(() => {
    getTermDeathInsuranceDetail();
  }, [getTermDeathInsuranceDetail]);

  //保険商品のご案内にあたって
  const searchCondition = searchConditionStorage.get();
  const [isSalesPolicyOpen, setIsSalesPolicyOpen] = useState<boolean>(false);
  
  const setSearchConditionNoticeCheckStorage = () => {
    searchConditionStorage.set({
      ...searchConditionStorage.get(),
      isOpenNotes: 1
    });
  };
  
  const addInsuranceToCartProps = useMemo(
    () => ({
      setIsPopupOpen,
      insurance_code,
      focusedInsurance,
      insuranceIdListInCart,
      setInsuranceIdListInCart,
      insuranceListInCart,
      setInsuranceListInCart,
    }),
    [
      setIsPopupOpen,
      insurance_code,
      focusedInsurance,
      insuranceIdListInCart,
      setInsuranceIdListInCart,
      insuranceListInCart,
      setInsuranceListInCart,
    ]
  );

  const userAge = useMemo(() => {
    if (fromComparison) {
      return deathDetailSerachCondition?.age;
    } else {
      return queryAge ? parseInt(queryAge) : 0;
    }
  }, [deathDetailSerachCondition, queryAge, fromComparison]);

  const userSex = useMemo(() => {
    if (fromComparison) {
      return deathDetailSerachCondition?.sex;
    } else {
      return querySex ? parseInt(querySex) : 0;
    }
  }, [deathDetailSerachCondition, querySex, fromComparison])

  const insuranceFee = useMemo(() => {
    if (fromComparison) {
      return deathDetailSerachCondition?.totalInsuranceFee;
    } else {
      return queryInsuranceFee ? parseInt(queryInsuranceFee) : 0;
    }
  }, [deathDetailSerachCondition, queryInsuranceFee, fromComparison])
  
  const setSearchConditionStorage = () => {
    searchConditionStorage.set({
      ...searchConditionStorage.get(),
      typeOfInsurance: 4
    });
    window.location.href = "/comparison/";
  };
  
  const navigate = useNavigate();
  const partnerData = partnerDataStorage.get();

  return (
    <>
      {isPopupOpen || isCartOpen ? <BlackFilter /> : <></>}
      <Header setIsCartOpen={setIsCartOpen} />
      <div className="main">
        <div className="twoColumns">
          <div className="left">
            <Card className="leftCard">
              <Card.Body className="cardBody">
                <div className="top">
                  <img src={response.thumbnail_url} alt="logo" width={210} />
                  <div className="names">
                    <h2>
                      {response.company_name}
                    </h2>
                    <p>{deathDetailSerachCondition?.product_name}</p>
                  </div>
                  <div className="applyButtonCon">
                    {response.is_online_entry && response.application_url
                    ? (
                      <>
                        {searchCondition?.isOpenNotes === 1 ? (
                          <Button
                            className="netBtn buttonIcon"
                            variant="orange"
                            onClick={() =>
                              response.application_url && window.open(response.application_url, "_blank")
                            }
                          >
                            {response.is_online_entry
                              ? "ネット申し込み"
                              : "資料請求リンク"}
                          </Button>
                        ): (
                          <Button
                            onClick={() => {
                              setIsSalesPolicyOpen(true);
                              setSearchConditionNoticeCheckStorage();
                            }}
                            className="netBtn buttonIcon"
                            variant="orange"
                          >
                            {response.is_online_entry
                              ? "ネット申し込み"
                              : "資料請求リンク"}
                          </Button>
                        )}
                      </>
                    ) : ''}
                    {response.file_url && response.file_url.trim() !== '' && (
                      <Button 
                        className="netBtnee alineleft" 
                        variant="orangenew"
                        onClick={() =>
                          response.file_url &&
                          window.open(response.file_url, "_blank")
                        }
                      >
                        パンフレットを見る
                      </Button>
                    )}
                  </div>
                  <div className="priceAndCondition">
                    <div className="price alignBaseline">
                      <span className="title">
                        月払保険料
                      </span>
                      <span className="number">
                        ¥
                        { jpNumFormatter(insuranceFee) }
                      </span>
                    </div>
                  </div>
                  <p className="priceAndConditionunder ">&#60;算出条件&#62;</p>
                  <div className="priceAndConditionunder fontcloro5e">
                    <ul className="priceAndConditionunderList">
                      <li>契約年齢：{userAge}歳</li>
                      <li>性別：{userSex === 2 ? "女" : "男"}性</li>
                      <li>払込回数：月払</li>
                      <li>払込経路：口座振替扱</li>
                      {deathDetailSerachCondition?.memo1 ? <li>{deathDetailSerachCondition?.memo1}</li> : ''}
                      {deathDetailSerachCondition?.memo2 ? <li>{deathDetailSerachCondition?.memo2}</li> : ''}
                      {deathDetailSerachCondition?.memo3 ? <li>{deathDetailSerachCondition?.memo3}</li> : ''}
                      {deathDetailSerachCondition.fee_memos?.map((memo, index) => (
                        <li>{memo}</li>
                      ))}
                      {queryMemo0 ? <li>{queryMemo0}</li> : ''}
                      {queryMemo1 ? <li>{queryMemo1}</li> : ''}
                      {queryMemo2 ? <li>{queryMemo2}</li> : ''}
                      {queryMemo3 ? <li>{queryMemo3}</li> : ''}
                      {queryMemo4 ? <li>{queryMemo4}</li> : ''}
                      {queryMemo5 ? <li>{queryMemo5}</li> : ''}
                    </ul>
                  </div>
                </div>
                <div className="points sectionMargin">
                  <div className="sectionTitle cordColumn">
                    <div>
                      <img
                        src="/img/lens_mark.svg"
                        alt=""
                        width={70}
                        height={70}
                      />
                    </div>
                    <div className="cordColumnTitle">
                      <span>商品のポイント</span>
                    </div>
                  </div>
                  <div className="texts">
                    <h3>
                      {formatTextWithLineBreaks(response.point_title_1)}
                    </h3>
                    <p>{formatTextWithLineBreaks(response.point_detail_1)}</p>
                  </div>
                  <div className="payAndEntry">
                    <p>
                      支払方法：　
                      <span>
                        {formatTextWithLineBreaks(response.payment_method)}
                      </span>
                    </p>
                    <p>
                      支払回数：　
                      <span>
                        {formatTextWithLineBreaks(response.number_of_payment)}
                      </span>
                    </p>
                    <p>
                      申込方法：　
                      <span>
                        {formatTextWithLineBreaks(response.entry_method)}
                      </span>
                    </p>
                  </div>
                  <div className="buttonBox">
                    {insuranceIdListInCart.includes(insurance_code ?? "") ||
                    fromComparison !== "true" ? (
                      <></>
                    ) : (
                      <Button
                        className="netBtn buttonRadius"
                        variant="comparisonHeader"
                        onClick={() => {
                          addInsuranceToCart(addInsuranceToCartProps);
                        }}
                      >
                        Myプランに反映
                      </Button>
                    )}
                  </div>
                </div>
                <div className="baseInfo sectionMargin">
                  <div className="sectionTitle cordColumn">
                    <div>
                      <img
                        src="/img/book_mark.svg"
                        alt=""
                        width={70}
                        height={70}
                      />
                    </div>
                    <div className="cordColumnTitle">
                      <span>基本情報</span>
                    </div>
                  </div>
                  <dl className="table">
                    <dt>引き受け保険会社</dt>
                    <dd>{response.basic_info?.official_company_name}</dd>
                    <dt>商品正式名称</dt>
                    <dd>{formatTextWithLineBreaks(response.basic_info?.formal_insurance_name)}</dd>
                    <dt>契約年齢</dt>
                    <dd>{formatTextWithLineBreaks(response.basic_info?.joining_age)}</dd>
                    <dt>保険期間</dt>
                    <dd>{formatTextWithLineBreaks(response.basic_info?.insurance_period)}</dd>
                    <dt>保険料払い込み期間</dt>
                    <dd>{formatTextWithLineBreaks(response.basic_info?.payment_period)}</dd>
                  </dl>
                </div>
                
                <div className="guaranteeContents sectionMargin">
                  <div className="sectionTitle cordColumn">
                    <div>
                      <img
                        src="/img/gurd_mark.svg"
                        alt=""
                        width={70}
                        height={70}
                      />
                    </div>
                    <div className="cordColumnTitle">
                      <span>保障内容</span>
                    </div>
                  </div>
                  <div className="listMargin">
                    <dl className="table">
                      <dt>保険金額（普通死亡・高度障害）</dt>
                      <dd>
                        {formatTextWithLineBreaks(
                          response.product_info?.death_benefit
                        )}
                      </dd>
                      <dt>保険金額（災害死亡・高度障害）</dt>
                      <dd>
                        {formatTextWithLineBreaks(
                          response.product_info?.accident_death_benefit
                        )}
                      </dd>
                    </dl>
                  </div>
                  <div className="listMargin">
                    <dl className="table">
                      <dt>解約返戻金</dt>
                      <dd>
                        {formatTextWithLineBreaks(
                          response.product_info?.cancellation_refund
                        )}
                      </dd>
                      <dt>リビングニーズ</dt>
                      <dd>
                        {formatTextWithLineBreaks(
                          response.product_info?.living_needs
                        )}
                      </dd>
                      <dt>保険料払込免除</dt>
                      <dd>
                        {formatTextWithLineBreaks(
                          response.product_info?.payment_exemption
                        )}
                      </dd>
                    </dl>
                  </div>
                  <div className="listMargin">
                    <dl className="table">
                      <dt>健康体割引</dt>
                      <dd>
                        {formatTextWithLineBreaks(
                          response.product_info?.health_discount
                        )}
                      </dd>
                      <dt>非喫煙割引</dt>
                      <dd>
                        {formatTextWithLineBreaks(
                          response.product_info?.non_smoking_discount
                        )}
                      </dd>
                      <dt>健康優良</dt>
                      <dd>
                        {formatTextWithLineBreaks(
                          response.product_info?.good_health_discount
                        )}
                      </dd>
                    </dl>
                  </div>
                  <div className="listMargin">
                    <dl className="table">
                      <dt>契約者サービス</dt>
                      <dd>
                        {formatTextWithLineBreaks(
                          response.product_info?.contractor_service
                        )}
                      </dd>
                    </dl>
                  </div>
                  <div className="listMargin">
                    <dl className="table">
                      <dt>その他</dt>
                      <dd>
                        {formatTextWithLineBreaks(
                          response.product_info?.other_topic
                        )}
                      </dd>
                    </dl>
                  </div>

                  <ul>
                    {response.guard_text1 && (<li>{formatTextWithLineBreaks(response.guard_text1)}</li>)}
                    {response.guard_text2 && (<li>{formatTextWithLineBreaks(response.guard_text2)}</li>)}
                    {response.guard_text3 && (<li>{formatTextWithLineBreaks(response.guard_text3)}</li>)}
                  </ul>
                  <div className="approveNumber">
                    {response.approval_number}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          {fromComparison === "true" && (
            <Card className="rightCard">
              <Card.Body className="cardBody">
                <h2>{response.company_name}</h2>
                <p>{response.product_name}</p>
                <img src={response.thumbnail_url} alt="logo" width={210} />
                <div className="insuranceFee alignBaseline">
                  <span className="title">
                    月払保険料
                  </span>
                  <span className="number">
                    ¥
                    {deathDetailSerachCondition?.totalInsuranceFee &&
                      jpNumFormatter(
                        deathDetailSerachCondition?.totalInsuranceFee
                      )}
                  </span>
                </div>
                <div className="priceAndConditionunder">
                  <p>&#60;算出条件&#62;</p>
                  <ul>
                    <li>契約年齢：{userAge}歳</li>
                    <li>性別：{userSex === 2 ? "女" : "男"}性</li>
                    <li>払込回数：月払</li>
                    <li>払込経路：口座振替扱</li>
                    {deathDetailSerachCondition?.memo1 ? <li>{deathDetailSerachCondition?.memo1}</li> : ''}
                    {deathDetailSerachCondition?.memo2 ? <li>{deathDetailSerachCondition?.memo2}</li> : ''}
                    {deathDetailSerachCondition?.memo3 ? <li>{deathDetailSerachCondition?.memo3}</li> : ''}
                    {deathDetailSerachCondition.fee_memos?.map((memo, index) => (
                      <li>{memo}</li>
                    ))}
                    {queryMemo0 ? <li>{queryMemo0}</li> : ''}
                    {queryMemo1 ? <li>{queryMemo1}</li> : ''}
                    {queryMemo2 ? <li>{queryMemo2}</li> : ''}
                    {queryMemo3 ? <li>{queryMemo3}</li> : ''}
                    {queryMemo4 ? <li>{queryMemo4}</li> : ''}
                    {queryMemo5 ? <li>{queryMemo5}</li> : ''}
                  </ul>
                </div>
                <div className="buttonBox">
                  {response.is_online_entry && response.application_url ? (
                    <>
                      {searchCondition?.isOpenNotes === 1 ? (
                        <Button
                          href={response.application_url}
                          target="_blank"
                          className="netBtn buttonIcon buttonRadius"
                          variant="orange"
                        >
                          {response.is_online_entry
                            ? "ネット申し込み"
                            : "資料請求リンク"}
                        </Button>
                      ): (
                        <Button
                          onClick={() => {
                            setIsSalesPolicyOpen(true);
                            setSearchConditionNoticeCheckStorage();
                          }}
                          className="netBtn buttonIcon buttonRadius"
                          variant="orange"
                        >
                          {response.is_online_entry
                            ? "ネット申し込み"
                            : "資料請求リンク"}
                        </Button>
                      )}
                    </>
                  ) : ''}
                  {insuranceIdListInCart.includes(insurance_code ?? "") ? (
                    <></>
                  ) : (
                    <Button
                      className="netBtn buttonRadius"
                      variant="comparisonHeader"
                      onClick={() => {
                        addInsuranceToCart(addInsuranceToCartProps);
                      }}
                    >
                      Myプランに反映
                    </Button>
                  )}
                </div>
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
      {isSalesPolicyOpen && (
        <div className="popUpOpen">
          <div className="wrap">
            <div className="mainCont mainContPlusButton">
              <SalesPolicy
                hasLinkButton={true}
                linkTitle={response.is_online_entry ? "ネット申し込み" : "資料請求リンク"}
                linkUrl={response.application_url ?? ''}
                setIsSalesPolicyOpen={setIsSalesPolicyOpen}
              />
            </div>
            <Button
              className="closePopup"
              onClick={() => {
                setIsSalesPolicyOpen(false);
              }}
            >
              閉じる
            </Button>
          </div>
        </div>
      )}
      {fromComparison === "true" ? (
        <div className="backButtonContadd">
          <Button 
            className="buttonmargin"
            variant="light"
            onClick={() => {
              navigate('/comparison/');
            }}
          >一覧へもどる</Button>
        </div>
      ):(
        <div className="backButtonContadd">
          <Button
            variant="light"
            className="buttonmargin"
            onClick={() => {
              navigate('/result-simple/');
            }}
          >診断結果ページに戻る</Button>
          {(partnerData ? partnerData.comparisonButtonDisp : true) ? (
            <Button 
              onClick={setSearchConditionStorage}
              className="buttonmargin"
              variant="light"
            >他の商品を見る</Button>
          ) : (
            <></>
          )}
        </div>
      )}
      {isPopupOpen ? (
        <AddInsuranceToCartPopup
          insurance={focusedInsurance ?? undefined}
          setIsPopupOpen={setIsPopupOpen}
        />
      ) : (
        <></>
      )}
      {isCartOpen ? (
        <EstimateResult
          setIsCartOpen={setIsCartOpen}
          insuranceListInCart={insuranceListInCart}
          setInsuranceListInCart={setInsuranceListInCart}
          insuranceIdListInCart={insuranceIdListInCart}
          setInsuranceIdListInCart={setInsuranceIdListInCart}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default TermDeathInsuranceDetail;
