import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, useLocation } from "react-router-dom";
import "./styles/main.scss";

// Pages
import Simple from "./pages/simple";

//components
import Detail from "./pages/detail";
import DownloadDocument from "./pages/download/downloadDocument";
import DownloadFp from "./pages/download/downloadFp";
import ResultDetail from "./pages/resultDetail";
import ResultSimple from "./pages/resultSimple";
import Top from "./pages/top";
//import DownloadLine from './downloadLine';
import CancerInsuranceDetail from "./pages/comparison/cancerInsuranceDetail";
import Comparison from "./pages/comparison/comparison";
import IncomeProtectionInsuranceDetail from "./pages/comparison/incomeProtectionInsuranceDetail";
import MedicalInsuranceDetail from "./pages/comparison/medicalInsuranceDetail";
import TermDeathInsuranceDetail from "./pages/comparison/termDeathInsuranceDetail";
import WholeDeathInsuranceDetail from "./pages/comparison/wholeDeathInsuranceDetail";
import ContactComplete from "./pages/contactComplete";
import ContactafterComplete from "./pages/contactafterComplete";
import SaveafterComplete from "./pages/saveafterComplete";
import DownloadLine from "./pages/download/downloadLine";
import DownloadMail from "./pages/download/downloadMail";
import DownloadSns from "./pages/download/downloadSms";
import Diagnose from "./pages/diagnose";

import { useEffect } from "react";

const DefaultRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  return (
    <Routes>
      <Route path={`/`} element={<Top />} />
      <Route path={`/simple/`} element={<Simple />} />
      <Route path={`/result-simple/`} element={<ResultSimple />} />
      <Route path={`/detail/`} element={<Detail />} />
      <Route path={`/result-detail/`} element={<ResultDetail />} />
      <Route path={`/download-document/`} element={<DownloadDocument />} />
      <Route path={`/download-fp/`} element={<DownloadFp />} />
      <Route path={`/download-sms/`} element={<DownloadSns />} />
      <Route path={`/download-mail/`} element={<DownloadMail />} />
      <Route path={`/download-line/`} element={<DownloadLine />} />
      <Route path={`/comparison/`} element={<Comparison />} />
      <Route path={`/contact-complete`} element={<ContactComplete />} />
      <Route path={`/contactafter-complete`} element={<ContactafterComplete />} />
      <Route path={`/saveafter-complete`} element={<SaveafterComplete />} />
      <Route
        path="/medical_insurances/:insurance_code"
        element={<MedicalInsuranceDetail />}
      />
      <Route
        path="/cancer_insurances/:insurance_code"
        element={<CancerInsuranceDetail />}
      />
      <Route
        path="/whole_death_insurances/:insurance_code"
        element={<WholeDeathInsuranceDetail />}
      />
      <Route
        path="/term_death_insurances/:insurance_code"
        element={<TermDeathInsuranceDetail />}
      />
      <Route
        path="/income_protection_insurances/:insurance_code"
        element={<IncomeProtectionInsuranceDetail />}
      />
      <Route path="/diagnose/:diagnose_code" element={<Diagnose />} />
    </Routes>
  );
};

export default DefaultRoutes;
