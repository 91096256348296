/**  @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  ApiIncomeProtectionInsurancesRankResponse,
  ApiIncomeProtectionInsurancesRankResponseInsurancesInner,
} from "../../../../openApi/api";
import { ContentsColumnIncomeProtection } from "./ContentsColumnIncomeProtection";
import { HeadColumnCommon } from "./HeadColumnCommon";
import { HeadColumnIncomeProtection } from "./HeadColumnIncomeProtection";

interface Props {
  typeOfInsurance: number;
  response: ApiIncomeProtectionInsurancesRankResponse | undefined;
  age: number;
  sex: number;
  paymentExemption: boolean;
  setPaymentExemption: (paymentExemption: boolean) => void;
  setIsPopupOpen: (isPopupOpen: boolean) => void;
  setFocusedIndex: (focusedIndex: number) => void;
  insuranceListInCart: ApiIncomeProtectionInsurancesRankResponseInsurancesInner[];
  setInsuranceListInCart: (
    prevCart: ApiIncomeProtectionInsurancesRankResponseInsurancesInner[]
  ) => void;
  insuranceIdListInCart: string[];
  setInsuranceIdListInCart: (insuranceIdListInCart: string[]) => void;
  // 収入補償保険特有のprops
  monthlyBenefit: number;
  setMonthlyBenefit: (monthlyBenefit: number) => void;
  cancelRepayment: boolean;
  setCancelRepayment: (cancelRepayment: boolean) => void;
  livingNeeds: boolean;
  setLivingNeeds: (livingNeeds: boolean) => void;
  paymentMinGuaranteeTerm: number;
  setPaymentMinGuaranteeTerm: (paymentMinGuaranteeTerm: number) => void;
  triggerIncomeProtectionInsuranceRankAPI: boolean;
  setTriggerIncomeProtectionInsuranceRankAPI: (_: boolean) => void;
  
  checkedRankList: string[];
  setCheckedRankList: (checkedRankList: string[]) => void;
  
  isContentsColumnRendered: boolean;
  setIsContentsColumnRendered: (isContentsColumnRendered: boolean) => void;
}

export const TableIncomeProtection = ({
  typeOfInsurance,
  response,
  age,
  sex,
  paymentExemption,
  setPaymentExemption,
  setIsPopupOpen,
  setFocusedIndex,
  insuranceListInCart,
  setInsuranceListInCart,
  insuranceIdListInCart,
  setInsuranceIdListInCart,
  monthlyBenefit,
  setMonthlyBenefit,
  cancelRepayment,
  setCancelRepayment,
  livingNeeds,
  setLivingNeeds,
  paymentMinGuaranteeTerm,
  setPaymentMinGuaranteeTerm,
  triggerIncomeProtectionInsuranceRankAPI,
  setTriggerIncomeProtectionInsuranceRankAPI,
  
  checkedRankList,
  setCheckedRankList,
  
  isContentsColumnRendered,
  setIsContentsColumnRendered,
}: Props) => {
  
  const [isDisplayOnlyChecked, setIsDisplayOnlyChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsContentsColumnRendered(true);
  }, [
    isDisplayOnlyChecked,
    setIsDisplayOnlyChecked,
    setIsContentsColumnRendered
  ]);
  
  return (
    <section className="comparisonTableSection">
      <Container>
        <Row>
          <Col className="speechBoxContainer" md={1}>
            <div className="speechBox">
              保障内容でも
              <br />
              絞り込み可能
            </div>
          </Col>
          <Col md={1} className="insuranceTableTitle">
            {response?.table_header_text}
          </Col>
        </Row>
      </Container>
      <div className="insuranceTable">
        <div className="headRow">
          <HeadColumnCommon
            isDisplayOnlyChecked={isDisplayOnlyChecked}
            setIsDisplayOnlyChecked={setIsDisplayOnlyChecked}
            isContentsColumnRendered={isContentsColumnRendered}
            setIsContentsColumnRendered={setIsContentsColumnRendered}
          />
          <div className="headRowSpecific">
            <HeadColumnIncomeProtection
              paymentExemption={paymentExemption}
              setPaymentExemption={setPaymentExemption}
              monthlyBenefit={monthlyBenefit}
              setMonthlyBenefit={setMonthlyBenefit}
              cancelRepayment={cancelRepayment}
              setCancelRepayment={setCancelRepayment}
              livingNeeds={livingNeeds}
              setLivingNeeds={setLivingNeeds}
              paymentMinGuaranteeTerm={paymentMinGuaranteeTerm}
              setPaymentMinGuaranteeTerm={setPaymentMinGuaranteeTerm}
              triggerIncomeProtectionInsuranceRankAPI={
                triggerIncomeProtectionInsuranceRankAPI
              }
              setTriggerIncomeProtectionInsuranceRankAPI={
                setTriggerIncomeProtectionInsuranceRankAPI
              }
              isContentsColumnRendered={isContentsColumnRendered}
              setIsContentsColumnRendered={setIsContentsColumnRendered}
            />
          </div>
        </div>
        <div className="contentRow">
          {response?.insurances &&
            response?.insurances.map(
              (
                insurance: ApiIncomeProtectionInsurancesRankResponseInsurancesInner,
                index: number
              ) =>
                !isDisplayOnlyChecked ||
                (isDisplayOnlyChecked &&
                  checkedRankList.includes(insurance.insurance_id ?? '')) ? (
                  <ContentsColumnIncomeProtection
                    key={`${typeOfInsurance}_${index + 1}`}
                    typeOfInsurance={typeOfInsurance}
                    insurance={insurance}
                    age={age}
                    sex={sex}
                    monthlyBenefit={monthlyBenefit}
                    lowPriceRank={index + 1}
                    setIsPopupOpen={setIsPopupOpen}
                    setFocusedIndex={setFocusedIndex}
                    insuranceListInCart={insuranceListInCart}
                    setInsuranceListInCart={setInsuranceListInCart}
                    insuranceIdListInCart={insuranceIdListInCart}
                    setInsuranceIdListInCart={setInsuranceIdListInCart}
                    paymentMinGuaranteeTerm={paymentMinGuaranteeTerm}
                    cancelRepayment={cancelRepayment}
                    livingNeeds={livingNeeds}
                    paymentExemption={paymentExemption}
                    checkedRankList={checkedRankList}
                    setCheckedRankList={setCheckedRankList}
                    setIsContentsColumnRendered={setIsContentsColumnRendered}
                    numberOfInsurances={response?.insurances?.length}
                  />
                ) : (
                  <div key={index + 1}></div>
                )
            )}
        </div>
      </div>
    </section>
  );
};
