import { useMemo } from "react";
import { PropsWithChildren } from "react";
import BootstrapThemeProvider from "react-bootstrap/ThemeProvider";
import { GetApiPartnersPartnerCode200Response } from "../openApi/api";
import { useGetPartnerInfo } from "../query/useGetPartnerInfo";
import { partnerDataStorage } from "../utils/storage";

const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const callback = (data: GetApiPartnersPartnerCode200Response) => {
    const htmlBody = document.documentElement.style;

    data.main_color && htmlBody.setProperty("--main_color", data.main_color);
    data.sub_color_1 && htmlBody.setProperty("--sub_color_1", data.sub_color_1);
    data.sub_color_2 && htmlBody.setProperty("--sub_color_2", data.sub_color_2);
    data.sub_color_3 && htmlBody.setProperty("--sub_color_3", data.sub_color_3);
    data.font_color && htmlBody.setProperty("--font_color", data.font_color);
    data.accent_color &&
      htmlBody.setProperty("--accent_color", data.accent_color);
    data.background_color &&
      htmlBody.setProperty("--background_color", data.background_color);
    data.main_button_color &&
      htmlBody.setProperty("--main_button_color", data.main_button_color);
    data.sub_button_color_1 &&
      htmlBody.setProperty("--sub_button_color_1", data.sub_button_color_1);
    data.sub_button_color_2 &&
      htmlBody.setProperty("--sub_button_color_2", data.sub_button_color_2);
    data.sub_button_color_3 &&
      htmlBody.setProperty("--sub_button_color_3", data.sub_button_color_3);
  };

  const partnerCode = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("partner") ?? undefined;
  }, []);

  useGetPartnerInfo({
    partnerCode,
    callback,
  });

  const partnerData = partnerDataStorage.get();
  // クエリパラメータ提携先コードなし、セッションストレージ上に提携先情報が存在する場合は、
  // セッションストレージ上のデータを利用してカラーテーマを設定する。
  // トップは除外
  if(!partnerCode && partnerData && window.location.pathname !== "/"){
    const htmlBody = document.documentElement.style;
    partnerData.mainColor && htmlBody.setProperty("--main_color", partnerData.mainColor);
    partnerData.subColor1 && htmlBody.setProperty("--sub_color_1", partnerData.subColor1);
    partnerData.subColor2 && htmlBody.setProperty("--sub_color_2", partnerData.subColor2);
    partnerData.subColor3 && htmlBody.setProperty("--sub_color_3", partnerData.subColor3);
    partnerData.fontColor && htmlBody.setProperty("--font_color", partnerData.fontColor);
    partnerData.accentColor &&
      htmlBody.setProperty("--accent_color", partnerData.accentColor);
    partnerData.backgroundColor &&
      htmlBody.setProperty("--background_color", partnerData.backgroundColor);
    partnerData.mainButtonColor &&
      htmlBody.setProperty("--main_button_color", partnerData.mainButtonColor);
    partnerData.subButtonColor1 &&
      htmlBody.setProperty("--sub_button_color_1", partnerData.subButtonColor1);
    partnerData.subButtonColor2 &&
      htmlBody.setProperty("--sub_button_color_2", partnerData.subButtonColor2);
    partnerData.subButtonColor3 &&
      htmlBody.setProperty("--sub_button_color_3", partnerData.subButtonColor3);
  }

  return (
    <BootstrapThemeProvider
      breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      minBreakpoint="xxs"
    >
      {children}
    </BootstrapThemeProvider>
  );
};

export default ThemeProvider;
