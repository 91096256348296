/** @jsxImportSource @emotion/react */
import { useParams, useSearchParams } from "react-router-dom";
import "../../styles/pages/resultSimple.scss";
import { RequiredAmount } from "./components/RequiredAmount";
import { AnnualPremium } from "./components/AnnualPremium";
import { EstimatePremium } from "./components/EstimatePremium";
import { useGetDiagnoseByCode } from "../../query/useGetDiagnoseByCode";
import { PostApiSimpleDiagnose200Response } from "../../openApi/api";
import { partnerDataStorage, PartnerData } from "../../utils/storage";
import { useMemo, useState } from "react";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const Diagnose = () => {
  useDocumentTitle("保デジWEB-診断結果");
  const { diagnose_code } = useParams();
  const [searchParams] = useSearchParams();
  const version = useMemo(() => {
    const data = searchParams.get("version");
    return data ? parseInt(data) : undefined;
  }, [searchParams]);

  const [diagnose, setDiagnose] = useState<PostApiSimpleDiagnose200Response>();

  const callback = (data: PostApiSimpleDiagnose200Response) => {
    setDiagnose(data);
    
    if (data.partner_code !== null){
      const partnerData: PartnerData = {
        partnerCode: data.partner_code,
        inflowCode: '',
        mainColor: data.partner_main_color,
        subColor1: data.partner_sub_color_1,
        subColor2: data.partner_sub_color_2,
        subColor3: data.partner_sub_color_3,
        fontColor: data.partner_font_color,
        accentColor: data.partner_accent_color,
        backgroundColor: data.partner_background_color,
        mainButtonColor: data.partner_main_button_color,
        subButtonColor1: data.partner_sub_button_color_1,
        subButtonColor2: data.partner_sub_button_color_2,
        subButtonColor3: data.partner_sub_button_color_3,
        saveButtonDisp: data.partner_save_button_disp,
        fpButtonDisp: data.partner_fp_button_disp,
        comparisonButtonDisp: data.partner_comparison_button_disp
      };
      partnerDataStorage.set(partnerData);

      const htmlBody = document.documentElement.style;
      data.partner_main_color && htmlBody.setProperty("--main_color", data.partner_main_color);
      data.partner_sub_color_1 && htmlBody.setProperty("--sub_color_1", data.partner_sub_color_1);
      data.partner_sub_color_2 && htmlBody.setProperty("--sub_color_2", data.partner_sub_color_2);
      data.partner_sub_color_3 && htmlBody.setProperty("--sub_color_3", data.partner_sub_color_3);
      data.partner_font_color && htmlBody.setProperty("--font_color", data.partner_font_color);
      data.partner_accent_color && htmlBody.setProperty("--accent_color", data.partner_accent_color);
      data.partner_background_color && htmlBody.setProperty("--background_color", data.partner_background_color);
      data.partner_main_button_color && htmlBody.setProperty("--main_button_color", data.partner_main_button_color);
      data.partner_sub_button_color_1 && htmlBody.setProperty("--sub_button_color_1", data.partner_sub_button_color_1);
      data.partner_sub_button_color_2 && htmlBody.setProperty("--sub_button_color_2", data.partner_sub_button_color_2);
      data.partner_sub_button_color_3 && htmlBody.setProperty("--sub_button_color_3", data.partner_sub_button_color_3);

    } else {
      partnerDataStorage.remove();
    }
  };

  useGetDiagnoseByCode({
    diagnoseCode: diagnose_code,
    version,
    callback,
  });

  return (
    <div id="result-simple">
      <main className="simpleAssessmentResult_layout">
        <section className="simpleAssessmentResult_title">
          <h1 className="assessmentTitle_header typographyTextTitle typographyPrimaryColor textsizeadd">
          かんたん診断結果ページ
          </h1>
        </section>
        {diagnose && (
          <>
            <RequiredAmount simpleDiagnose={diagnose} />
            <AnnualPremium simpleDiagnose={diagnose} />
            <EstimatePremium simpleDiagnose={diagnose} />
          </>
        )}
      </main>
    </div>
  );
};
export default Diagnose;
