import { ScrollBackBtn } from "../../../components/atoms/Button";

interface Props {
  current: number;
  sex: number;
  setSex: (value: number) => void;
  onPrev: (value: number) => void;
}

const Question2 = ({ current, sex, setSex, onPrev }: Props) => {
  return (
    <section
      className={`cardContainer fade-in-slide-up ${
        current === 3 ? "currentCard" : ""
      }`}
      id="question-2"
    >
      <ScrollBackBtn onClick={() => onPrev(2)} />
      <div className="cardWrapper question">
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            2&nbsp;/&nbsp;10
          </span>
          <h2 className="typographyPrimaryColor typographyTextSubTitle">
            性別はどちらですか
          </h2>
          <span>診断したい方について教えてください</span>
        </div>
        <fieldset>
          <label>
            <input
              type="radio"
              value="M"
              aria-checked={sex === 1}
              onClick={() => setSex(1)}
            />
            <div>
              <img src="/img/male.svg" alt="" />
              <span>男性</span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              value="F"
              aria-checked={sex === 2}
              onClick={() => setSex(2)}
            />
            <div>
              <img src="/img/female.svg" alt="" />
              <span>女性</span>
            </div>
          </label>
        </fieldset>
      </div>
    </section>
  );
};

export default Question2;
