import { useQuery } from "@tanstack/react-query";
import { Api } from "../openApi";
import { GetApiInsuranceCompanies200ResponseInner } from "../openApi/api";

interface Props {
  callback: (data: Array<GetApiInsuranceCompanies200ResponseInner>) => void;
}

export const useInsuranceCompanies = ({ callback }: Props) => {
  return useQuery({
    queryKey: ["getApiInsuranceCompanies"],
    queryFn: async () => {
      return Api.getApiInsuranceCompanies();
    },
    onSuccess: async (res: any) => {
      const data = await res;
      callback(data.data);
    },
    onError: (e: any) => {
      console.log(e);
    },
    enabled: false,
  });
};
