import { useMemo } from "react";
import { Button } from "react-bootstrap";
import { PostApiSimpleDiagnose200Response, EstimateInsurance } from "../../../openApi/api";
import { jpNumFormatter } from "../../../utils/formatter";
import {
  cancerPDStorage,
  deathPDStorage,
  incomeProtectionPDStorage,
  medicalPDStorage,
  simpleDiagnoseStorage,
  cartStorage,
} from "../../../utils/storage";
// import { MAIN_BENEFIT_TEXT_SET } from "../../../utils/textSet";
interface Props {
  simpleDiagnose: PostApiSimpleDiagnose200Response;
}

export const EstimatePremium = ({ simpleDiagnose }: Props) => {
  const cart = useMemo(() => cartStorage.get(), []);
  
  const age = simpleDiagnose.age; 
  const sex = simpleDiagnose.sex;

	simpleDiagnoseStorage.set({
	  request: {
	  	  sex: simpleDiagnose.sex,
	  	  age: simpleDiagnose.age,
	  },
	  result: simpleDiagnose,
	});

  if (cart === null && simpleDiagnose !== null){
    const insuranceIdListInCart = simpleDiagnose.recommended_insurance_items?.map((item, index) => {
      return item.insurance_code;
    });
    
    cartStorage.set({
      insuranceIdList: insuranceIdListInCart,
      insuranceList: simpleDiagnose.recommended_insurance_items
    });
  }

  const onViewProduct = (item: EstimateInsurance) => {
    if (!item.insurance_detail_link) return;
    if (item.insurance_detail_link.includes("medical_insurances/")) {
      medicalPDStorage.set({
        totalInsuranceFee: item.insurance_fee,
        product_name: item.insurance_name,
        memo1: item.memo1,
        memo2: item.memo2,
        memo3: item.memo3,
        fee_memos: item.fee_memos
      });
    } else if (item.insurance_detail_link.includes("cancer_insurances/")) {
      cancerPDStorage.set({
        totalInsuranceFee: item.insurance_fee,
        product_name: item.insurance_name,
        memo1: item.memo1,
        memo2: item.memo2,
        memo3: item.memo3,
        fee_memos: item.fee_memos
      });
    } else if (item.insurance_detail_link.includes("whole_death_insurances/")) {
      deathPDStorage.set({
        totalInsuranceFee: item.insurance_fee,
        product_name: item.insurance_name,
        memo1: item.memo1,
        memo2: item.memo2,
        memo3: item.memo3,
        fee_memos: item.fee_memos
      });
    } else if (item.insurance_detail_link.includes("term_death_insurances/")) {
      deathPDStorage.set({
        totalInsuranceFee: item.insurance_fee,
        product_name: item.insurance_name,
        memo1: item.memo1,
        memo2: item.memo2,
        memo3: item.memo3,
        fee_memos: item.fee_memos
      });
    } else if (item.insurance_detail_link.includes("income_protection_insurances/")) {
      incomeProtectionPDStorage.set({
        totalInsuranceFee: item.insurance_fee,
        product_name: item.insurance_name,
        memo1: item.memo1,
        memo2: item.memo2,
        memo3: item.memo3,
        fee_memos: item.fee_memos
      });
    }
    window.open(item.insurance_detail_link, "_blank");
  };
  
  const totalInsuranceFee = useMemo(() => {
    let totalInsurance = 0;
    simpleDiagnose.recommended_insurance_items?.forEach((item) => {
      totalInsurance += item.insurance_fee ?? 0;
    });
    return totalInsurance;
  }, [simpleDiagnose]);

  return (
    <section>
      <div className="cardWrapper">
        <div className="cardContainer">
          <h2 className="recommendedInsuranceItems_title">Myプラン(見積もり)</h2>
          <p className="recommendedInsuranceItems_subTitle">
            見直した場合の、月々の保険料見込み
          </p>
          <p className="recommendedInsuranceItems_subsubTitle">
          &#60;算出条件&#62;
          </p>

          <p className="recommendedInsuranceItems_subsubTitle">
          契約年齢：{age}歳　性別：{sex === 1 ? "男性" : sex === 2 ? "女性" : ""}　払込回数：月払　払込経路：口座振替扱
          </p>
          <ul
            className="recommendedInsuranceItems_table"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr",
              rowGap: "15px",
              margin: "33px 0 24px",
            }}
          >
            {simpleDiagnose.recommended_insurance_items?.map((item, index) => (
              <li className="grid-repeat" key={`insuranceItem_${index}`}>
                <div className="recommendedInsuranceItems_child">
                  <div className="thumbnail">
                    {item.thumbnail_url && (
                      <img
                        style={{
                          width: "100px",
                          height: "auto",
                          boxShadow: "0 0 4px #ccc",
                          borderRadius: "5px",
                        }}
                        src={item.thumbnail_url}
                        alt=""
                      />
                    )}
                    <p
                      className="typographyFontsizeXXs typographySecondaryColor typographyFontFeaturePalt"
                      style={{
                        marginTop: 4.75,
                        textAlign: "center",
                      }}
                    >
                      {item.company_name}
                    </p>
                  </div>
                  <div className={item.is_disp ? 'insuranceTypeMemo isOpen': 'insuranceTypeMemo isClose'}>
                    <div
                      className="typographyPrimaryColor"
                    >
                      <div className="insuranceType">
                        {item.insurance_category}
                      </div>
                      {item.is_disp && (
                        <div className="memoListBox typographyFontsizeXs font5e">
                          {item.memo1 ? 
                            <span>
                              {item.memo1}
                            </span>
                          : ''}
                          {item.memo2 ? 
                            <span>
                              {item.memo2}
                            </span>
                          : ''}
                          {item.memo3 ? 
                            <span>
                              {item.memo3}
                            </span>
                          : ''}
                          {item.fee_memos?.map((memo, index) => (
                            <span>
                              {memo}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="recommendedInsuranceItems_child content-float typographyPrimaryColor">
                  <div className="text-center">
                    <span className="recommendedInsuranceItems_number ">
                      {jpNumFormatter(item.insurance_fee!)}
                    </span>
                    円
                  </div>
                  <div className="text-center">
                    {item.is_disp ? (
                      <Button
                        variant="outline-primary"
                        className="rounded-pill m-auto ps-4 pe-4 gap-1 align-items-center"
                        style={{ fontSize: "11px" }}
                        onClick={() => onViewProduct(item)}
                      >
                        商品を見る
                      </Button>
                    ): ''}
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="recommendedInsuranceItems_border typographyPrimaryColor total-insurance-label">
            <div>
              <div className="recommendedInsuranceItems_totalTitle typographyTextSubTitle">
                月々合計
              </div>
            </div>
            <div>
              <div>
                <span className="recommendedInsuranceItems_number recommendedInsuranceItems_totalNumber">
                  {jpNumFormatter(totalInsuranceFee, true)}
                </span>
                円
              </div>
            </div>
          </div>
          <p className="typographyTextSupport typographySecondaryColor recommendedInsuranceItems_guardText">
            ここで表示されているMyプランの内容は、ご入力いただいた各情報を元に算出された必要保障額に対し、弊社が定めた基準でおすすめしている商品内容となっております。 なお、必ずしも表示されている保険商品で必要保障額の全てが満たされるとは限りません。また、ここで表示されている情報は、保障内容の全てが記載されているわけではなくあくまで情報の一部となる為、これ以外の保険商品と単純に比較したり優劣をつけたりすることはできません。 実際にご検討いただく際には各保険会社のパンフレット及び、ご契約のしおり、重要事項書類をご確認ください。 加えて、その他の条件等によってご提案できる商品や保険料が異なる場合がございますので、詳しいことにつきましてはお問い合わせください。 また、各保険会社基準の審査の結果お引き受け出来ない可能性もございますので何卒ご了承ください。
本資料でご案内している保険商品に関する内容は、2024年3月1日現在で適用されているものです。
          </p>
        </div>
      </div>
    </section>
  );
};
