import { useMemo } from "react";
import {
  CancerInsuranceRankProps,
  useCancerInsuranceRank,
} from "../query/useCancerInsuranceRank";
import {
  IncomeProtectionInsuranceRankProps,
  useIncomeProtectionInsuranceRank,
} from "../query/useIncomeProtectionInsuranceRank";
import {
  MedicalInsuranceRankProps,
  useMedicalInsuranceRank,
} from "../query/useMedicalInsuranceRank";
import {
  TermDeathInsuranceRankProps,
  useTermDeathInsuranceRank,
} from "../query/useTermDeathInsuranceRank";
import {
  WholeDeathInsuranceRankProps,
  useWholeDeathInsuranceRank,
} from "../query/useWholeDeathInsuranceRank";

export const useGetMedicalInsuranceRank = ({
  order,
  age,
  sex,
  hospitalizationGuarantee,
  hospitalizationGuaranteeDays,
  paymentExemption,
  underwritingEasing,
  intentionRecommendInsurance,
  maxBudget,
  hospitalizationOnce,
  surgeryGuarantee,
  commutingDailyAmount,
  commutingDailyAmountDays,
  commutingOnce,
  advancedMedical,
  cancerSpecialContractAmount,
  specifiedDisease,
  deathSpecialContractAmount,
  congratulatoryMoney,
  baseCode,
  onSuccess,
}: MedicalInsuranceRankProps) => {
  const { data: medicalInsuranceResponse, refetch: getMedicalInsuranceRank } =
    useMedicalInsuranceRank(
      useMemo(
        () => ({
          order,
          age,
          sex,
          hospitalizationGuarantee,
          hospitalizationGuaranteeDays,
          paymentExemption,
          underwritingEasing,
          intentionRecommendInsurance,
          maxBudget: maxBudget === 1 ? undefined : maxBudget,
          hospitalizationOnce:
            hospitalizationOnce === 0 ? undefined : hospitalizationOnce,
          surgeryGuarantee,
          commutingDailyAmount:
            commutingDailyAmount === 0 ? undefined : commutingDailyAmount,
          commutingDailyAmountDays:
            commutingDailyAmountDays === 0
              ? undefined
              : commutingDailyAmountDays,
          commutingOnce: commutingOnce === 0 ? undefined : commutingOnce,
          advancedMedical,
          cancerSpecialContractAmount:
            cancerSpecialContractAmount === 0
              ? undefined
              : cancerSpecialContractAmount,
          specifiedDisease,
          deathSpecialContractAmount:
            deathSpecialContractAmount === 0
              ? undefined
              : deathSpecialContractAmount,
          congratulatoryMoney,
          baseCode,
          onSuccess,
        }),
        [
          order,
          age,
          sex,
          hospitalizationGuarantee,
          hospitalizationGuaranteeDays,
          paymentExemption,
          underwritingEasing,
          intentionRecommendInsurance,
          maxBudget,
          hospitalizationOnce,
          surgeryGuarantee,
          commutingDailyAmount,
          commutingDailyAmountDays,
          commutingOnce,
          advancedMedical,
          cancerSpecialContractAmount,
          specifiedDisease,
          deathSpecialContractAmount,
          congratulatoryMoney,
          baseCode,
          onSuccess,
        ]
      )
    );

  return { medicalInsuranceResponse, getMedicalInsuranceRank };
};

export const useGetCancerInsuranceRank = ({
  order,
  age,
  sex,
  paymentExemption,
  underwritingEasing,
  intentionRecommendInsurance,
  maxBudget,
  malignantDiagnosticOnce,
  intraepithelialDiagnosticOnce,
  malignantDiagnosticTreatment,
  intraepithelialDiagnosticTreatment,
  hospitalizationDailyAmount,
  hospitalizationDailyAmountDays,
  surgery,
  leavingHospitalOnce,
  cancerHospitalDailyFee,
  cancerHospitalDailyFeeDays,
  commutingOnce,
  cancerAdvancedMedical,
  radiotherapy,
  anticancerDrugTreatment,
  femaleDiseases,
  baseCode,
  onSuccess,
}: CancerInsuranceRankProps) => {
  const { data: cancerInsuranceResponse, refetch: getCancerInsuranceRank } =
    useCancerInsuranceRank(
      useMemo(
        () => ({
          order,
          age,
          sex,
          paymentExemption,
          underwritingEasing,
          intentionRecommendInsurance,
          maxBudget: maxBudget === 1 ? undefined : maxBudget,
          malignantDiagnosticOnce:
            malignantDiagnosticOnce === 0 ? undefined : malignantDiagnosticOnce,
          intraepithelialDiagnosticOnce:
            intraepithelialDiagnosticOnce === 0
              ? undefined
              : intraepithelialDiagnosticOnce,
          malignantDiagnosticTreatment:
            malignantDiagnosticTreatment === 0
              ? undefined
              : malignantDiagnosticTreatment,
          intraepithelialDiagnosticTreatment:
            intraepithelialDiagnosticTreatment === 0
              ? undefined
              : intraepithelialDiagnosticTreatment,
          hospitalizationDailyAmount:
            hospitalizationDailyAmount === 0
              ? undefined
              : hospitalizationDailyAmount,
          hospitalizationDailyAmountDays:
            hospitalizationDailyAmountDays === 0
              ? undefined
              : hospitalizationDailyAmountDays,
          surgery: surgery === 0 ? undefined : surgery,
          leavingHospitalOnce:
            leavingHospitalOnce === 0 ? undefined : leavingHospitalOnce,
          cancerHospitalDailyFee:
            cancerHospitalDailyFee === 0 ? undefined : cancerHospitalDailyFee,
          cancerHospitalDailyFeeDays:
            cancerHospitalDailyFeeDays === 0
              ? undefined
              : cancerHospitalDailyFeeDays,
          commutingOnce: commutingOnce === 0 ? undefined : commutingOnce,
          cancerAdvancedMedical: cancerAdvancedMedical ? cancerAdvancedMedical : undefined,
          radiotherapy: radiotherapy === 0 ? undefined : radiotherapy,
          anticancerDrugTreatment: anticancerDrugTreatment
            ? anticancerDrugTreatment
            : undefined,
          femaleDiseases: femaleDiseases ? femaleDiseases : undefined,
          baseCode,
          onSuccess,
        }),
        [
          order,
          age,
          sex,
          paymentExemption,
          underwritingEasing,
          intentionRecommendInsurance,
          maxBudget,
          malignantDiagnosticOnce,
          intraepithelialDiagnosticOnce,
          malignantDiagnosticTreatment,
          intraepithelialDiagnosticTreatment,
          hospitalizationDailyAmount,
          hospitalizationDailyAmountDays,
          surgery,
          leavingHospitalOnce,
          cancerHospitalDailyFee,
          cancerHospitalDailyFeeDays,
          commutingOnce,
          cancerAdvancedMedical,
          radiotherapy,
          anticancerDrugTreatment,
          femaleDiseases,
          baseCode,
          onSuccess,
        ]
      )
    );

  return { cancerInsuranceResponse, getCancerInsuranceRank };
};

export const useGetWholeDeathInsuranceRank = ({
  order,
  age,
  sex,
  deathBenefit,
  paymentExemption,
  underwritingEasing,
  intentionRecommendInsurance,
  maxBudget,
  cancelRepayment,
  livingNeeds,
  baseCode,
  onSuccess,
}: WholeDeathInsuranceRankProps) => {
  const {
    data: wholeDeathInsuranceResponse,
    refetch: getWholeDeathInsuranceRank,
  } = useWholeDeathInsuranceRank(
    useMemo(
      () => ({
        maxBudget: maxBudget === 1 ? undefined : maxBudget,
        order,
        age,
        sex,
        deathBenefit,
        cancelRepayment: cancelRepayment ? cancelRepayment : undefined,
        paymentExemption,
        underwritingEasing,
        intentionRecommendInsurance,
        livingNeeds: livingNeeds ? livingNeeds : undefined,
        baseCode,
        onSuccess,
      }),
      [
        order,
        age,
        sex,
        deathBenefit,
        paymentExemption,
        underwritingEasing,
        intentionRecommendInsurance,
        maxBudget,
        cancelRepayment,
        livingNeeds,
        baseCode,
        onSuccess,
      ]
    )
  );

  return { wholeDeathInsuranceResponse, getWholeDeathInsuranceRank };
};

export const useGetTermDeathInsuranceRank = ({
  order,
  age,
  sex,
  deathBenefit,
  paymentExemption,
  underwritingEasing,
  intentionRecommendInsurance,
  maxBudget,
  cancelRepayment,
  livingNeeds,
  baseCode,
  onSuccess,
}: TermDeathInsuranceRankProps) => {
  const {
    data: termDeathInsuranceResponse,
    refetch: getTermDeathInsuranceRank,
  } = useTermDeathInsuranceRank(
    useMemo(
      () => ({
        maxBudget: maxBudget === 1 ? undefined : maxBudget,
        order,
        age,
        sex,
        deathBenefit: deathBenefit,
        cancelRepayment: cancelRepayment ? cancelRepayment : undefined,
        paymentExemption,
        underwritingEasing,
        intentionRecommendInsurance,
        livingNeeds: livingNeeds ? livingNeeds : undefined,
        baseCode,
        onSuccess,
      }),
      [
        order,
        age,
        sex,
        deathBenefit,
        paymentExemption,
        underwritingEasing,
        intentionRecommendInsurance,
        maxBudget,
        cancelRepayment,
        livingNeeds,
        baseCode,
        onSuccess,
      ]
    )
  );

  return { termDeathInsuranceResponse, getTermDeathInsuranceRank };
};

export const useGetIncomeProtectionInsuranceRank = ({
  order,
  age,
  sex,
  monthlyBenefit,
  paymentExemption,
  underwritingEasing,
  intentionRecommendInsurance,
  maxBudget,
  cancelRepayment,
  livingNeeds,
  paymentMinGuaranteeTerm,
  baseCode,
  onSuccess,
}: IncomeProtectionInsuranceRankProps) => {
  const {
    data: incomeProtectionInsuranceResponse,
    refetch: getIncomeProtectionInsuranceRank,
  } = useIncomeProtectionInsuranceRank(
    useMemo(
      () => ({
        maxBudget: maxBudget === 1 ? undefined : maxBudget,
        order,
        age,
        sex,
        monthlyBenefit,
        cancelRepayment: cancelRepayment ? cancelRepayment : undefined,
        paymentExemption,
        underwritingEasing,
        intentionRecommendInsurance,
        livingNeeds: livingNeeds ? livingNeeds : undefined,
        paymentMinGuaranteeTerm:
          paymentMinGuaranteeTerm === 0 ? undefined : paymentMinGuaranteeTerm,
        baseCode,
        onSuccess,
      }),
      [
        order,
        age,
        sex,
        monthlyBenefit,
        paymentExemption,
        underwritingEasing,
        intentionRecommendInsurance,
        maxBudget,
        cancelRepayment,
        livingNeeds,
        paymentMinGuaranteeTerm,
        baseCode,
        onSuccess,
      ]
    )
  );

  return {
    incomeProtectionInsuranceResponse,
    getIncomeProtectionInsuranceRank,
  };
};
