import { useMemo } from "react";
import { Button, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { jpNumFormatter } from "../../../utils/formatter";
import {
  cartStorage,
  detailDiagnoseStorage,
  searchConditionStorage,
  simpleDiagnoseStorage,
} from "../../../utils/storage";
import { CartStorage } from "../../comparison/components/EstimateResult";
// import { MAIN_BENEFIT_TEXT_SET } from "../../../utils/textSet";
import { EstimateInsurance } from "../../../openApi/api";

interface Props {
  isDispComparisonButton: boolean | undefined;
}

export const EstimatePremium = ({isDispComparisonButton = true}: Props) => {
  const navigate = useNavigate();
  const { result: detailDiagnose } = useMemo(
    () => detailDiagnoseStorage.get(),
    []
  );

  const simpleDiagnose = useMemo(() => simpleDiagnoseStorage.get(), []);

  const onCompare = () => {
    const cartData: CartStorage = {
      insuranceIdList: [],
      insuranceList: [],
    };

    detailDiagnose.recommended_insurance_items?.forEach((item) => {
      if (!item.insurance_code) return;

      cartData.insuranceIdList.push(item.insurance_code);
      cartData.insuranceList.push({
        ...item,
        insurance_id: item.insurance_code,
        insurance_period: item.payment_term,
        total_insurance_fee: item.insurance_fee,
      });

      setInsuranceItemSearchCondition(item);
    });
    cartStorage.set(cartData);

    searchConditionStorage.set({
      ...searchConditionStorage.get(),
      typeOfInsurance: 1,
      age: simpleDiagnose.request.age,
      sex: simpleDiagnose.request.sex,
    });
    navigate("/comparison");
  };

  const totalInsuranceFee = useMemo(() => {
    let totalInsurance = 0;
    detailDiagnose.recommended_insurance_items?.forEach((item) => {
      totalInsurance += item.insurance_fee ?? 0;
    });
    return totalInsurance;
  }, [detailDiagnose]);

  const setInsuranceItemSearchCondition = (item: EstimateInsurance) =>{
    switch(item.insurance_type){
      case 1:
        if(item.guarantee_amount_plane){
          searchConditionStorage.set({
            ...searchConditionStorage.get(),
            hospitalizationGuarantee: item.guarantee_amount_plane,
          });
        }
        break;
      case 2 :
        if(item.guarantee_amount_plane){
          searchConditionStorage.set({
            ...searchConditionStorage.get(),
            malignantDiagnosticOnce: item.guarantee_amount_plane,
          });
        }
        break;
      case 3 :
        if(item.guarantee_amount_plane){
          searchConditionStorage.set({
            ...searchConditionStorage.get(),
            wholeDeathBenefit: item.guarantee_amount_plane,
          });
        }
        break;
      case 4 :
        if(item.guarantee_amount_plane){
          searchConditionStorage.set({
            ...searchConditionStorage.get(),
            termDeathBenefit: item.guarantee_amount_plane,
          });
        }
        break;
      case 5 :
        if(item.guarantee_amount_plane){
          searchConditionStorage.set({
            ...searchConditionStorage.get(),
            monthlyBenefit: item.guarantee_amount_plane,
          });
        }
        break;
    }
  }

  return (
    <section>
      <div className="cardWrapper">
        <div className="cardContainer">
          <h2 className="recommendedInsuranceItems_title">Myプラン(見積もり)</h2>
          <p className="recommendedInsuranceItems_subTitle">
            見直した場合の、月々の保険料見込み
          </p>
          <div className="mysubtitle">
        ＜算出条件＞
        </div>
        <div className="mysubtitle ">
        契約年齢：{simpleDiagnose?.request?.age} 歳&nbsp;&nbsp;
                        {simpleDiagnose?.request?.sex === 2 ? "女" : "男"}
                        性&nbsp;&nbsp;
        払込回数：月払　払込経路：口座振替扱
        </div>
          <ul
            className="recommendedInsuranceItems_table"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr",
              rowGap: "15px",
              margin: "33px 0 24px",
            }}
          >
            {detailDiagnose.recommended_insurance_items?.map((item, index) => (
              <li className="grid-repeat" key={`insuranceItem_${index}`}>
                <div className="recommendedInsuranceItems_child">
                  <div>
                    <img
                      style={{
                        width: "100px",
                        height: "auto",
                        boxShadow: "0 0 4px #ccc",
                        borderRadius: "5px",
                      }}
                      src={item.thumbnail_url}
                      alt=""
                    />
                    <p
                      className="typographyFontsizeXXs typographySecondaryColor typographyFontFeaturePalt"
                      style={{
                        marginTop: 4.75,
                        textAlign: "center",
                      }}
                    >
                      {item.company_name}
                    </p>
                  </div>
                  <div>
                    <div
                      className="typographyPrimaryColor"
                      style={{ lineHeight: 1.3 }}
                    >
                      <div>
                        <div style={{ fontSize: "13px" }}>
                          {item.insurance_category}
                        </div>
                      </div>
                      <div className="memoListBox typographyFontsizeXs font5e">
                        {item.memo1 ? 
                          <span>
                            {item.memo1}
                          </span>
                        : ''}
                        {item.memo2 ? 
                          <span>
                            {item.memo2}
                          </span>
                        : ''}
                        {item.memo3 ? 
                          <span>
                            {item.memo3}
                          </span>
                        : ''}
                        {item.fee_memos?.map((memo, index) => (
                          <span>
                            {memo}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recommendedInsuranceItems_child content-float typographyPrimaryColor">
                  <div className="text-center">
                    <span className="recommendedInsuranceItems_number ">
                      {jpNumFormatter(item.insurance_fee!)}
                    </span>
                    円
                  </div>
                  <div className="text-center">
                    <Button
                      variant="outline-primary"
                      className="rounded-pill m-auto ps-4 pe-4 gap-1 align-items-center"
                      style={{ fontSize: "11px" }}
                      onClick={() =>
                        item.insurance_detail_link &&
                        window.open(item.insurance_detail_link, "_blank")
                      }
                    >
                      商品を見る
                    </Button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="recommendedInsuranceItems_border typographyPrimaryColor total-insurance-label">
            <div>
              <div className="recommendedInsuranceItems_totalTitle typographyTextSubTitle">
                月々合計
              </div>
            </div>
            <div>
              <div>
                <span className="recommendedInsuranceItems_number recommendedInsuranceItems_totalNumber">
                  {jpNumFormatter(totalInsuranceFee, true)}
                </span>
                円
              </div>
            </div>
          </div>
          <Stack style={{ marginTop: "40px" }}>
            {isDispComparisonButton ?
              <Button
                variant="blue"
                className="p-3"
                style={{
                  borderRadius: 12,
                }}
                onClick={onCompare}
              >
                保険商品を比較してみる
              </Button>
              : ''
            }
          </Stack>
          <p className="typographyTextSupport typographySecondaryColor recommendedInsuranceItems_guardText">
             ここで表示されているMyプランの内容は、ご入力いただいた各情報を元に算出された必要保障額に対し、弊社が定めた基準でおすすめしている商品内容となっております。 なお、必ずしも表示されている保険商品で必要保障額の全てが満たされるとは限りません。また、ここで表示されている情報は、保障内容の全てが記載されているわけではなくあくまで情報の一部となる為、これ以外の保険商品と単純に比較したり優劣をつけたりすることはできません。 実際にご検討いただく際には各保険会社のパンフレット及び、ご契約のしおり、重要事項書類をご確認ください。 加えて、その他の条件等によってご提案できる商品や保険料が異なる場合がございますので、詳しいことにつきましてはお問い合わせください。 また、各保険会社基準の審査の結果お引き受け出来ない可能性もございますので何卒ご了承ください。
本資料でご案内している保険商品に関する内容は、2024年3月1日現在で適用されているものです。
          </p>
        </div>
      </div>
    </section>
  );
};
