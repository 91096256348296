import { useQuery } from "@tanstack/react-query";
import { Api } from "../openApi";
import { PostApiSimpleDiagnose200Response } from "../openApi/api";

interface Props {
  diagnoseCode?: string;
  version?: number;
  callback: (data: PostApiSimpleDiagnose200Response) => void;
}

export const useGetDiagnoseByCode = ({
  diagnoseCode,
  version,
  callback,
}: Props) => {
  return useQuery({
    queryKey: ["getApiDiagnose"],
    queryFn: async () => {
      if (diagnoseCode !== undefined && version !== undefined) {
        return Api.getApiDiagnoseDiagnoseId(diagnoseCode, version);
      }
    },
    onSuccess: async (res: any) => {
      const data = await res;
      callback(data.data);
    },
    onError: (e: any) => {
      console.log(e);
    },
    enabled: !!diagnoseCode && !!version,
  });
};
