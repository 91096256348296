import { ChangeEvent } from "react";

interface Props {
  label: string;
  value?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const BorderCheckBox = ({ label, value, onChange }: Props) => {
  return (
    <label className="questionCheckbox">
      <input
        type="checkbox"
        value="true"
        aria-checked={value}
        onChange={onChange}
      />
      <div>
        <img
          src={`/img/${value === true ? "checked" : "unchecked"}.svg`}
          alt=""
        />
        <span>{label}</span>
      </div>
    </label>
  );
};
