import { BorderCheckBox } from "../../../components/atoms/BorderCheckBox";
import { ScrollBackBtn } from "../../../components/atoms/Button";
interface Props {
  current: number;
  currentInsuranceCategory: string[];
  setCurrentInsuranceCategory: (value: string[]) => void;
  onPrev: (value: number) => void;
  onComplete: () => void;
}

export const Question8 = ({
  current,
  currentInsuranceCategory,
  setCurrentInsuranceCategory,
  onPrev,
}: Props) => {
  const supportOptions = [
    "入院保険",
    "死亡保険",
    "がん保険",
    "資産系保険",
    "就業不能保険",
    "その他",
  ];

  const onChangeCheck = (label: string, checked: boolean) => {
    const tmpCategory = [...currentInsuranceCategory];
    if (checked === true) {
      tmpCategory.push(label);
      setCurrentInsuranceCategory(tmpCategory);
    } else {
      const index = tmpCategory.indexOf(label);
      if (index !== -1) {
        tmpCategory.splice(index, 1);
        setCurrentInsuranceCategory(tmpCategory);
      }
    }
  };
  return (
    <section className={`cardContainer fade-in-slide-up `} id="question-8">
      <ScrollBackBtn onClick={() => onPrev(7)} />
      <div className="cardWrapper">
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            8&nbsp;/&nbsp;8
          </span>
          <h2 className="typographyAccentOrange typographyNumTextProgress">
            保障内容は
          </h2>
          <span>加入している保険の種類をすべて選んでください</span>
        </div>
        <fieldset>
          {supportOptions.map((value, index) => (
            <BorderCheckBox
              key={`BorderCheck_${index}`}
              label={value}
              value={currentInsuranceCategory.indexOf(value) !== -1}
              onChange={(e) => onChangeCheck(value, e.target.checked)}
            />
          ))}
        </fieldset>
      </div>
    </section>
  );
};
