/**  @jsxImportSource @emotion/react */
import { Button } from "react-bootstrap";

interface Props {
  setIsCartOpen: (isCartOpen: boolean) => void;
}

export const MobileEstimateResultButton = ({ setIsCartOpen }: Props) => {
  return (
    <div className="MobileEstimateResultButtonCon">
      <Button
        className="half"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setIsCartOpen(true);
        }}
        variant="comparisonHeader"
      >
        <img src="/img/filechecked.svg" alt="plan" /><span>Myプランを見る</span>
      </Button>
    </div>
  );
};
