/**  @jsxImportSource @emotion/react */
import * as txt from "../../../../components/atoms/Typografy";

interface Props {
  isDisplayOnlyChecked: boolean;
  setIsDisplayOnlyChecked: (isDisplayOnlyChecked: boolean) => void;
  isContentsColumnRendered: boolean;
  setIsContentsColumnRendered: (isContentsColumnRendered: boolean) => void;
}

export const HeadColumnCommon = ({
  isDisplayOnlyChecked,
  setIsDisplayOnlyChecked,
  setIsContentsColumnRendered,
}: Props) => {
  return (
    <div className="headRowCommon">
      <div className="checkCompareButtonContainer radioButton">
        <button
          className={`checkCompareButton ${
            isDisplayOnlyChecked ? "checkCompareButtonActive" : ""
          }`}
          onClick={() => {
            setIsContentsColumnRendered(false);
            setIsDisplayOnlyChecked(!isDisplayOnlyChecked);
          }}
        >
          チェックした
          <br />
          商品で比較
        </button>
      </div>
      <div className="listHead h50" css={[txt.typographyAlignCenter]}>
        <span css={txt.typographyBlock}>ランキング</span>
      </div>
      <div className="listHead h102" css={[txt.typographyAlignCenter]}>
        <span css={txt.typographyBlock}>保険会社・商品</span>
      </div>
      <div className="listHead h50" css={[txt.typographyAlignCenter]}>
        <span css={txt.typographyBlock}>月払い保険料</span>
      </div>
      <div className="listHead h75" css={[txt.typographyAlignCenter]}>
        <span css={txt.typographyBlock}>お申し込み</span>
      </div>
      <div className="listHead h60" css={[txt.typographyAlignCenter]}>
        <span css={txt.typographyBlock}>
          Myプラン
          <br />
          に保存
        </span>
      </div>
    </div>
  );
};
