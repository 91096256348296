/**  @jsxImportSource @emotion/react */
import { Button } from "react-bootstrap";

interface Props {
  setIsCartOpen: (isCartOpen: boolean) => void;
}

export const Header = ({ setIsCartOpen }: Props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const fromComparison =
    queryParameters.get("fromComparison") ??
    window.location.href.includes("/comparison")
      ? "true"
      : "false";

  const phoneNumber = process.env.REACT_APP_TEL;

  return (
    <header>
      <div className="headerLogoContainer">
        <img src="/img/logo.svg" alt="logo" width={174} height={68} />
        <span className="headertitle">保険商品かんたん比較</span>
      </div>
      
      {fromComparison === "true" && (
        <div className="headerButtonContainer">
          <span className="headertitle">{phoneNumber}<p className="headertitlesub">平日9:30~19:00</p></span>
          <div className="headerButtonWrap">
            <Button
              className="half"
              onClick={() => {
                setIsCartOpen(true);
              }}
              variant="comparisonHeader"
            >
              <img src="/img/filechecked.svg" alt="plan" /><span>Myプランを見る</span>
            </Button>
          </div>
        </div>
      )}
      <a href="tel:0120-997-887" className="pcoff">
      <div className="headertitlesp"> <img src="/img/headertel.svg" alt="logo" width={70} height={30} />
      <p className="headertitlesubsp fontwight">お問い合わせ</p>
      <p className="headertitlesubsp">平日9:30~19:00</p>
      </div>
      </a>
    </header>
  );
};
