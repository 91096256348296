/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const typographyBlock = css`
  display: block;
`;
export const typographyAlignCenter = css`
  text-align: center;
`;
export const typographyAlignRight = css`
  text-align: right;
`;
export const typographyPrimaryColor = css`
  color: var(--font_color);
`;
export const typographySecondaryColor = css`
  color: #5e5e5e;
`;
export const typographyAccentOrange = css`
  color: var(--accent_color);
`;
export const typographyFontsizeXs = css`
  font-size: 10px;
`;
export const typographyFontFeaturePalt = css`
  font-feature-settings: "palt";
`;

export const typographyNumTextProgress = css`
  font-style: italic;
  font-size: 16px;
`;
export const typographyNumTextLarge = css`
  font-family: Number Font, Hiragino Kaku Gothic Pro, Helvetica Neue, Arial,
    Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif;
  font-weight: 500;
  line-height: 100%;
  color: #000;
  font-style: normal;
  font-size: 44px;
  letter-spacing: 2px;
`;
export const typographyNumTextLabelLarge = css`
  font-family: Number Font, Hiragino Kaku Gothic Pro, Helvetica Neue, Arial,
    Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #000;
  font-size: 20px;
  line-height: 150%;
`;

export const typographyToptitle = css`
  font-family: Hiragino Kaku Gothic Pro, Helvetica Neue, Arial,
    Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
`;
export const typographyTopSubtitle = css`
  font-family: Hiragino Kaku Gothic Pro, Helvetica Neue, Arial,
    Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
`;

export const typographyTextTitle = css`
  font-family: Hiragino Kaku Gothic Pro, Helvetica Neue, Arial,
    Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  color: #000;
`;
export const typographyTextSupport = css`
  font-family: Hiragino Kaku Gothic Pro, Helvetica Neue, Arial,
    Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 150%;
  color: #000;
`;
export const typographyTextSubTitle = css`
  font-family: Hiragino Kaku Gothic Pro, Helvetica Neue, Arial,
    Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 1.5;
`;
export const typographyTextBodyBold = css`
  font-family: Hiragino Kaku Gothic Pro, Helvetica Neue, Arial,
    Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  color: #000;
`;

export const typographyAnswerBtnLabel = css`
  font-family: Hiragino Kaku Gothic Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
`;
export const typographyAnswerBtnDescription = css`
  color: #5e5e5e;
  font-weight: 300;
  font-size: 13px;
`;

export const hasSmokedLink = css`
  margin-left: 16px;
  color: var(--main_color);
  cursor: pointer;
`;
export const typographyComparisonCategoryTitle = css`
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
`;
export const typographyComparisonAccent = css`
  font-size: 16px;
  font-weight: 600;
`;
