import { useCallback, useRef } from "react";

export const usePreventMultipleCalls = <
  Params extends unknown[],
  Ret extends unknown
>(
  callback: (...params: Params) => Promise<Ret>,
  timeout: number = 500
): ((...params: Params) => Promise<Ret | null>) => {
  const onProcessingRef = useRef(false);
  return useCallback(
    async (...params) => {
      if (onProcessingRef.current) return null;
      onProcessingRef.current = true;
      const result = callback(...params);
      return result
        .then((r) => {
          return r;
        })
        .catch((e) => {
          throw e;
        });
    },
    [callback]
  );
};
