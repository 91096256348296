export const numFormatter = (value: number) => {
  return value.toLocaleString();
};

export const jpNumFormatter = (value: number, noConvert?: boolean) => {
  if (value >= 10000 * 10 && !noConvert) {
    return `${Math.floor(value / 10000).toLocaleString()}万`;
  } else {
    return `${Math.floor(value).toLocaleString()}`;
  }
};

export const numDecryptor = (value: string) => {
  if (value.includes("万")) {
    value = `${value.replaceAll("万", "")}0000`;
  }
  return value.replaceAll(",", "");
};

export const hankaku2Zenkaku = (str: string) => {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
};

export const emailFormatter = (str: string) => {
  let str_hankaku = str.replace(/[！-～]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
  
  let regexp_email = /[^a-zA-Z0-9!-/:-@¥[-`{-~]+$/;
  return str_hankaku.replace(regexp_email, '');
}

export const telFormatter = (str: string) => {
  let str_hankaku = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
  
  let regexp_tel = /[^0-9]+$/;
  str_hankaku = str_hankaku.replace(regexp_tel, '');
  
  if (str_hankaku.length > 11){
    str_hankaku = str_hankaku.substr(0, 11);
  }
  
  return str_hankaku;
};
