/**  @jsxImportSource @emotion/react */
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import {
  DIAGNOSTIC_ONCE_OPTIONS,
  HOSPITALIZATION_GUARANTEE_OPTIONS,
  MONTHLY_BENEFIT_OPTIONS,
  NumberOptions,
  TERM_DEATH_BENEFIT_OPTIONS,
  WHOLE_DEATH_BENEFIT_OPTIONS,
} from "./comparisonTable/DropdownItem";

export interface SearchConditionProps {
  typeOfInsurance: number;
  maxBudget: number;
  underwritingEasing: boolean;
  order: number;
  intentionRecommendInsurance: boolean;
  age: number;
  sex: number;
  hospitalizationGuarantee: number;
  malignantDiagnosticOnce: number;
  wholeDeathBenefit: number;
  termDeathBenefit: number;
  monthlyBenefit: number;
  isOpenNotes: number;
}

interface Props {
  typeOfInsurance: number;
  setTypeOfInsurance: (typeOfInsurance: number) => void;
  maxBudget: number;
  setMaxBudget: (maxBudget: number) => void;
  underwritingEasing: boolean;
  setUnderwritingEasing: (hospitalizationGuarantee: boolean) => void;
  order: number;
  setOrder: (order: number) => void;
  intentionRecommendInsurance: boolean;
  setIntentionRecommendInsurance: (intentionRecommendInsurance: boolean) => void;
  age: number;
  setAge: (age: number) => void;
  sex: number;
  setSex: (sex: number) => void;
  hospitalizationGuarantee: number;
  setHospitalizationGuarantee: (hospitalizationGuarantee: number) => void;
  malignantDiagnosticOnce: number;
  setMalignantDiagnosticOnce: (malignantDiagnosticOnce: number) => void;
  wholeDeathBenefit: number;
  setWholeDeathBenefit: (wholeDeathBenefit: number) => void;
  termDeathBenefit: number;
  setTermDeathBenefit: (termDeathBenefit: number) => void;
  monthlyBenefit: number;
  setMonthlyBenefit: (monthlyBenefit: number) => void;
  executeSearch: () => void;
}

export const SearchCondition = ({
  typeOfInsurance,
  setTypeOfInsurance,
  maxBudget,
  setMaxBudget,
  underwritingEasing,
  setUnderwritingEasing,
  order,
  setOrder,
  intentionRecommendInsurance,
  setIntentionRecommendInsurance,
  age,
  setAge,
  sex,
  setSex,
  hospitalizationGuarantee,
  setHospitalizationGuarantee,
  malignantDiagnosticOnce,
  setMalignantDiagnosticOnce,
  wholeDeathBenefit,
  setWholeDeathBenefit,
  termDeathBenefit,
  setTermDeathBenefit,
  monthlyBenefit,
  setMonthlyBenefit,
  executeSearch,
}: Props) => {
  const switchInsuredAmount = (typeOfInsurance: number): number => {
    switch (typeOfInsurance) {
      case 1:
        return hospitalizationGuarantee;
      case 2:
        return malignantDiagnosticOnce;
      case 3:
        return wholeDeathBenefit;
      case 4:
        return termDeathBenefit;
      case 5:
        return monthlyBenefit;
      default:
        return hospitalizationGuarantee;
    }
  };
  const switchSetInsuredAmount = (
    typeOfInsurance: number
  ): ((insuredAmount: number) => void) => {
    switch (typeOfInsurance) {
      case 1:
        return setHospitalizationGuarantee;
      case 2:
        return setMalignantDiagnosticOnce;
      case 3:
        return setWholeDeathBenefit;
      case 4:
        return setTermDeathBenefit;
      case 5:
        return setMonthlyBenefit;
      default:
        return () => {};
    }
  };
  const switchOptionInsuredAmount = (
    typeOfInsurance: number
  ): NumberOptions => {
    switch (typeOfInsurance) {
      case 1:
        return HOSPITALIZATION_GUARANTEE_OPTIONS;
      case 2:
        return DIAGNOSTIC_ONCE_OPTIONS;
      case 3:
        return WHOLE_DEATH_BENEFIT_OPTIONS;
      case 4:
        return TERM_DEATH_BENEFIT_OPTIONS;
      case 5:
        return MONTHLY_BENEFIT_OPTIONS;
      default:
        return HOSPITALIZATION_GUARANTEE_OPTIONS;
    }
  };

  const ageOption = [];
  for (let i = 0; i < 85; i++) {
    ageOption.push(
      <Dropdown.Item key={i} onClick={() => setAge(i)}>
        {i}歳
      </Dropdown.Item>
    );
  }

  return (
    <section className="searchConditionSection ">
      <div className="insuranceTypeWrap">
      <div className="insuranceType">
        <h2 className="categoryTitleMargin">
          {/* 保険の種類 */}
        </h2>
        <ButtonGroup className="buttongroupWrap buttongroupWrapMargin">
          <Button
            variant={typeOfInsurance === 1 ? "naivy" : "naivyButtonGroup"}
            onClick={() => {
              setTypeOfInsurance(1); // 例: 新しい typeOfInsurance を設定
            }}
          >
            医療保険
          </Button>
          <Button
            variant={typeOfInsurance === 2 ? "naivy" : "naivyButtonGroup"}
            onClick={() => {
              setTypeOfInsurance(2); // 例: 新しい typeOfInsurance を設定
            }}
          >
            がん保険
          </Button>
          <Button
            variant={typeOfInsurance === 3 ? "naivy" : "naivyButtonGroup"}
            onClick={() => {
              setTypeOfInsurance(3); // 例: 新しい typeOfInsurance を設定
            }}
          >
            死亡保険（終身）
          </Button>
          <Button
            variant={typeOfInsurance === 4 ? "naivy" : "naivyButtonGroup"}
            onClick={() => {
              setTypeOfInsurance(4); // 例: 新しい typeOfInsurance を設定
            }}
          >
            死亡保険（定期）
          </Button>
          <Button
            variant={typeOfInsurance === 5 ? "naivy" : "naivyButtonGroup"}
            onClick={() => {
              setTypeOfInsurance(5); // 例: 新しい typeOfInsurance を設定
            }}
          >
            収入保障保険
          </Button>
        </ButtonGroup>
      </div>
      </div>
      <div className="searchConditionWrap">
      <div className="monthlyBudget dispplaynne">
        <h2 className="categoryTitleMargin">
          保険料の予算（月払い）
        </h2>
        <ButtonGroup className="buttongroupWrap buttongroupWrapMargin">
          <Button
            variant={maxBudget === 1 ? "naivy" : "naivyButtonGroup"}
            onClick={() => setMaxBudget(1)}
          >
            指定しない
          </Button>
          <Button
            variant={maxBudget === 3000 ? "naivy" : "naivyButtonGroup"}
            onClick={() => setMaxBudget(3000)}
          >
            〜3,000円
          </Button>
          <Button
            variant={maxBudget === 5000 ? "naivy" : "naivyButtonGroup"}
            onClick={() => setMaxBudget(5000)}
          >
            〜5,000円
          </Button>
          <Button
            variant={maxBudget === 8000 ? "naivy" : "naivyButtonGroup"}
            onClick={() => setMaxBudget(8000)}
          >
            〜8,000円
          </Button>
          <Button
            variant={maxBudget === 10000 ? "naivy" : "naivyButtonGroup"}
            onClick={() => setMaxBudget(10000)}
          >
            〜10,000円
          </Button>
          <Button
            variant={maxBudget === 15000 ? "naivy" : "naivyButtonGroup"}
            onClick={() => setMaxBudget(15000)}
          >
            〜15,000円
          </Button>
          <Button
            variant={maxBudget === 20000 ? "naivy" : "naivyButtonGroup"}
            onClick={() => setMaxBudget(20000)}
          >
            〜20,000円
          </Button>
        </ButtonGroup>
      </div>
      <div className="easingAndSort">
        <div className="easing dispplaynne">
          <h2 className="categoryTitleMargin">
            引受基準緩和型
          </h2>
          <ButtonGroup className="buttongroupWrap buttongroupWrapMargin">
            <Button
              variant={underwritingEasing ? "naivyButtonGroup" : "naivy"}
              onClick={() => setUnderwritingEasing(false)}
            >
              なし
            </Button>
            <Button
              variant={underwritingEasing ? "naivy" : "naivyButtonGroup"}
              onClick={() => setUnderwritingEasing(true)}
            >
              あり
            </Button>
          </ButtonGroup>
        </div>
        <div className="sort dispplaynne">
          <h2 className="categoryTitleMargin">
            並び替え
          </h2>
          <ButtonGroup className="buttongroupWrap buttongroupWrapMargin">
            <Button
              variant={order === 1 ? "naivy" : "naivyButtonGroup"}
              onClick={() => setOrder(1)}
            >
              保険料が安い順
            </Button>
            <Button
              variant={order === 2 ? "naivy" : "naivyButtonGroup"}
              onClick={() => setOrder(2)}
            >
              保険料が高い順
            </Button>
          </ButtonGroup>
        </div>
        <div className="intentionRecommendInsurance ">
          <h2 className="categoryTitleMargin dispplaynne">
            表示
          </h2>
          <ButtonGroup className="buttongroupWrap buttongroupWrapMargin ">
            <Button
              variant={intentionRecommendInsurance ? "naivy" : "naivyButtonGroup"}
              onClick={() => setIntentionRecommendInsurance(true)}
            >
              推奨保険会社のみ
            </Button>
            <Button
              variant={intentionRecommendInsurance ? "naivyButtonGroup" : "naivy"}
              onClick={() => setIntentionRecommendInsurance(false)}
            >
              全て
            </Button>
          </ButtonGroup>
          
        </div>

        <div className="searchButtonContainer">
        <Button
          className="searchButton"
          variant="orange"
          onClick={() => executeSearch()}
        >
          検索する
        </Button>
      </div>
      </div>
      <div className="others dispplaynne">
        <div>
          <h3 className="dropDownTitle">
            年齢
          </h3>
          <Dropdown className="dropdown">
            <Dropdown.Toggle className="toggle" variant="dropdown">
              {age}歳
            </Dropdown.Toggle>
            <Dropdown.Menu className="menu">{ageOption}</Dropdown.Menu>
          </Dropdown>
        </div>
        <div>
          <h3 className="dropDownTitle">
            性別
          </h3>
          <Dropdown className="dropdow">
            <Dropdown.Toggle className="toggle" variant="dropdown">
              {sex === 1 ? "男性" : "女性"}
            </Dropdown.Toggle>
            <Dropdown.Menu className="menu">
              <Dropdown.Item
                onClick={() => {
                  setSex(1);
                }}
              >
                男性
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setSex(2);
                }}
              >
                女性
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="dispplaynne">
          <h3 className="dropDownTitle dispplaynne">
            保障額
          </h3>
          <Dropdown className="dropdown dispplaynne">
            <Dropdown.Toggle
              className="toggle toggle-guarantee dispplaynne"
              variant="dropdown"
            >
              {
                switchOptionInsuredAmount(typeOfInsurance)[
                  switchInsuredAmount(typeOfInsurance)
                ]
              }
            </Dropdown.Toggle>
            <Dropdown.Menu className="menu dispplaynne">
              {Object.keys(switchOptionInsuredAmount(typeOfInsurance)).map(
                (key, _) => (
                  <Dropdown.Item
                    key={key}
                    onClick={() =>
                      switchSetInsuredAmount(typeOfInsurance)(Number(key))
                    }
                  >
                    {switchOptionInsuredAmount(typeOfInsurance)[Number(key)]}
                  </Dropdown.Item>
                )
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      </div>
    </section>
  );
};
