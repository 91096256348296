interface Props {
  title: string;
}

const Loading = ({ title }: Props) => {
  return (
    <div className="loading-frame">
      <div className="content">
        <img
          src="/img/loading/loading_background.svg"
          alt="bg"
          className="back"
        />
        <span className="content-notify">
          <img
            src="/img/loading/operator.svg"
            className="content-icon"
            alt=""
          />
          <img
            src="/img/loading/loading.svg"
            className="content-waiting"
            alt=""
          />
          <label>{title}</label>
        </span>
      </div>
    </div>
  );
};

export default Loading;
