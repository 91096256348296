/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ArrowUpSvg from "../../assets/img/arrowUp";
interface ScrollBackBtnProps {
  onClick?: () => void;
}

export const ScrollBackBtn = ({ onClick }: ScrollBackBtnProps) => {
  return (
    <div className="scrollBackBtn" onClick={onClick}>
      <ArrowUpSvg />
    </div>
  );
};

interface ArrowButtonProps {
  onClick?: () => void;
}

export const ArrowButton = ({ onClick }: ArrowButtonProps) => {
  return (
    <button className="arrowButton" onClick={onClick}>
      <img src="/img/arrowDown.svg" alt="" />
    </button>
  );
};

export const centerBtn = css`
  text-align: center;
`;
export const blueBtn = css`
  width: 132px;
  height: 56px;
  background: var(--main_button_color);
  color: #fff;
  border-radius: 200px;
  text-align: center;
  border: none;
`;

export const activeBtn = css`
  padding: 16px;
  border-radius: 8px;
  border: 4px solid var(--main_color);
`;
export const nontiveBtn = css`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #afb7cc;
`;
export const ariaTrueElem = css`
  input[type="radio"] {
    display: none;
  }
  input[aria-checked="true"] + div {
    ${activeBtn};
    color: #5e5e5e;
    img {
      opacity: 1;
    }
  }
`;
export const ariaFalseElem = css`
  input[type="radio"] {
    display: none;
  }
  input[aria-checked="false"] + div {
    ${nontiveBtn}
    color: #d0d0d0;
    img {
      opacity: 0.5;
    }
  }
`;
export const checkboxBtn = css`
  padding: 20px 10px;
  border: 2px solid #5e5e5e;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  gap: 0.5em;
`;

export const policyClosedBtn = css`
  width: 60px;
  height: 60px;
  position: absolute;
  top: -30px;
  right: -30px;
  background: var(--main_button_color);
  border-radius: 50%;
  z-index: 20;
  cursor: pointer;
  span {
    width: 25px;
    height: 4px;
    background: #fff;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center;
    line-height: 1;
    &:first-of-type {
      transform: translate(-50%) rotate(45deg);
    }
    &:last-of-type {
      transform: translate(-50%) rotate(-45deg);
    }
  }
`;
export const downloadDocumentSubmit = css`
  margin-top: 10px;
  padding: 20px 0;
  border: none;
  color: #fff;
  background: var(--main_button_color);
  font-size: 18px;
  border-radius: 8px;
`;
export const underlineButton = css`
  border: none;
  outline: none;
  background: none;
  padding: 0;
  line-height: 1.5;
  border-bottom: 1px solid #5e5e5e;
`;
