import { useMemo } from "react";

interface Props {
  label: string;
  required?: boolean;
  options: string[];
  value?: string[];
  onChange?: (value: string[]) => void;
  selectKey?: string;
}

const MultiSelect = ({
  label,
  required,
  options,
  value,
  onChange,
  selectKey,
}: Props) => {
  const validate = useMemo(() => {
    if (required && value?.length === 0) return `${label}を入力してください`;
    return "";
  }, [value, label, required]);

  const onChangeCheck = (checked: boolean, option: string) => {
    if (!onChange) return;
    const tmpValue = value ? [...value] : [];
    if (checked) {
      onChange([...tmpValue, option]);
    } else {
      onChange(tmpValue.filter((x) => x !== option));
    }
  };
  return (
<div className={`flexColumn multi-select ${validate !== "" && "error"}`}>
      <span className="typographyAnswerBtnDescription">
        {label}
      </span>
      {options.map((option, index) => (
        <label
          htmlFor={`${selectKey}_${index}`}
          key={`${selectKey}_${index}`}
          className="checkboxBtn flex"
        >
          <input
            type="checkbox"
            id={`${selectKey}_${index}`}
            name=""
            value={option}
            checked={
              value === undefined ? undefined : value.indexOf(option) !== -1
            }
            onClick={(e) => onChangeCheck(e.currentTarget.checked, option)}
          />
          <p>{option}</p>
        </label>
      ))}
      {validate !== "" && <span className="validation">{validate}</span>}
    </div>

  
  );
};

export default MultiSelect;


