/**  @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import * as txt from "../../../../components/atoms/Typografy";
import {
  BOOL_OPTIONS,
  DIAGNOSTIC_ONCE_OPTIONS,
  DIAGNOSTIC_TREATMENT_OPTIONS,
  LEAVING_HOSPITAL_ONCE_OPTIONS,
  RADIOTHERAPY_OPTIONS,
  SURGERY_OPTIONS,
} from "./DropdownItem";
import { CancerTableRows, setHeightToTargetElement } from "./utils";

interface Props {
  paymentExemption: boolean;
  setPaymentExemption: (paymentExemption: boolean) => void;
  malignantDiagnosticOnce: number;
  setMalignantDiagnosticOnce: (malignantDiagnosticOnce: number) => void;
  intraepithelialDiagnosticOnce: number;
  setIntraepithelialDiagnosticOnce: (
    intraepithelialDiagnosticOnce: number
  ) => void;
  malignantDiagnosticTreatment: number;
  setMalignantDiagnosticTreatment: (
    malignantDiagnosticTreatment: number
  ) => void;
  intraepithelialDiagnosticTreatment: number;
  setIntraepithelialDiagnosticTreatment: (
    intraepithelialDiagnosticTreatment: number
  ) => void;
  hospitalizationDailyAmount: number;
  setHospitalizationDailyAmount: (hospitalizationDailyAmount: number) => void;
  hospitalizationDailyAmountDays: number;
  setHospitalizationDailyAmountDays: (hospitalizationDailyAmountDays: number) => void;
  surgery: number;
  setSurgery: (surgery: number) => void;
  leavingHospitalOnce: number;
  setLeavingHospitalOnce: (leavingHospitalOnce: number) => void;
  cancerHospitalDailyFee: number;
  setCancerHospitalDailyFee: (cancerHospitalDailyFee: number) => void;
  cancerHospitalDailyFeeDays: number;
  setCancerHospitalDailyFeeDays: (cancerHospitalDailyFeeDays: number) => void;
  commutingOnce: number;
  setCommutingOnce: (commutingOnce: number) => void;
  cancerAdvancedMedical: boolean;
  setCancerAdvancedMedical: (cancerAdvancedMedical: boolean) => void;
  radiotherapy: number;
  setRadiotherapy: (radiotherapy: number) => void;
  anticancerDrugTreatment: boolean;
  setAnticancerDrugTreatment: (anticancerDrugTreatment: boolean) => void;
  femaleDiseases: boolean;
  setFemaleDiseases: (femaleDiseases: boolean) => void;
  triggerCancerInsuranceRankAPI: boolean;
  setTriggerCancerInsuranceRankAPI: (_: boolean) => void;
  isContentsColumnRendered: boolean;
  setIsContentsColumnRendered: (isContentsColumnRendered: boolean) => void;
}

export const HeadColumnCancer = ({
  paymentExemption,
  setPaymentExemption,
  malignantDiagnosticOnce,
  setMalignantDiagnosticOnce,
  intraepithelialDiagnosticOnce,
  setIntraepithelialDiagnosticOnce,
  malignantDiagnosticTreatment,
  setMalignantDiagnosticTreatment,
  intraepithelialDiagnosticTreatment,
  setIntraepithelialDiagnosticTreatment,
  hospitalizationDailyAmount,
  setHospitalizationDailyAmount,
  hospitalizationDailyAmountDays,
  setHospitalizationDailyAmountDays,
  surgery,
  setSurgery,
  leavingHospitalOnce,
  setLeavingHospitalOnce,
  cancerHospitalDailyFee,
  setCancerHospitalDailyFee,
  cancerHospitalDailyFeeDays,
  setCancerHospitalDailyFeeDays,
  commutingOnce,
  setCommutingOnce,
  cancerAdvancedMedical,
  setCancerAdvancedMedical,
  radiotherapy,
  setRadiotherapy,
  anticancerDrugTreatment,
  setAnticancerDrugTreatment,
  femaleDiseases,
  setFemaleDiseases,
  triggerCancerInsuranceRankAPI,
  setTriggerCancerInsuranceRankAPI,
  isContentsColumnRendered,
  setIsContentsColumnRendered,
}: Props) => {
  
  useEffect(() => {
    if (isContentsColumnRendered) {
      CancerTableRows.map((selectorName) => {
        setHeightToTargetElement(selectorName);
        return null;
      });
    }
    
    setIsContentsColumnRendered(false);
  }, [
    isContentsColumnRendered,
    setIsContentsColumnRendered,
  ]);
  /*
  const removeLastNDigits = (number: number, n: number) => {
    return number.toString().slice(0, -n);
  };
  */
  
  return (
    <>
      <div
        id="malignantDiagnosticOnce"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>
          診断一時金
          <br />
          （悪性新生物）
        </span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {DIAGNOSTIC_ONCE_OPTIONS[malignantDiagnosticOnce]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(DIAGNOSTIC_ONCE_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() => {
                  setMalignantDiagnosticOnce(Number(Number(key)));
                  setTriggerCancerInsuranceRankAPI(
                    !triggerCancerInsuranceRankAPI
                  );
                }}
              >
                {DIAGNOSTIC_ONCE_OPTIONS[Number(Number(key))]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="intraepithelialDiagnosticOnce"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>
          診断一時金
          <br />
          （上皮内新生物）
        </span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {DIAGNOSTIC_ONCE_OPTIONS[intraepithelialDiagnosticOnce]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(DIAGNOSTIC_ONCE_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() =>
                  setIntraepithelialDiagnosticOnce(Number(Number(key)))
                }
              >
                {DIAGNOSTIC_ONCE_OPTIONS[Number(Number(key))]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="malignantDiagnosticTreatment"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>
          治療給付金
          <br />
          （悪性新生物）
        </span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {DIAGNOSTIC_TREATMENT_OPTIONS[malignantDiagnosticTreatment]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(DIAGNOSTIC_TREATMENT_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() => setMalignantDiagnosticTreatment(Number(key))}
              >
                {DIAGNOSTIC_TREATMENT_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="intraepithelialDiagnosticTreatment"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>
          治療給付金
          <br />
          （上皮内新生物）
        </span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {DIAGNOSTIC_TREATMENT_OPTIONS[intraepithelialDiagnosticTreatment]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(DIAGNOSTIC_TREATMENT_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() =>
                  setIntraepithelialDiagnosticTreatment(Number(key))
                }
              >
                {DIAGNOSTIC_TREATMENT_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="hospitalizationDailyAmount"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>がん入院日額</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {hospitalizationDailyAmount === 0 ? '指定なし' : 
              hospitalizationDailyAmount === 5000 ? '日額5,000円' : 
              hospitalizationDailyAmount === 10000 ? '日額10,000円' : 
              hospitalizationDailyAmount === 15000 ? '日額15,000円' : 
              hospitalizationDailyAmount === 20000 ? '日額20,000万円' : ''}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => {
              setHospitalizationDailyAmount(0);
            }}>
              指定なし
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setHospitalizationDailyAmount(5000);
              }}
            >
              日額5,000円
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setHospitalizationDailyAmount(10000);
              }}
            >
              日額10,000円
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setHospitalizationDailyAmount(15000);
              }}
            >
              日額15,000円
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setHospitalizationDailyAmount(20000);
              }}
            >
              日額20,000円
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="hospitalizationDailyAmountDays"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>がん入院日数</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {hospitalizationDailyAmountDays === 0
              ? "指定なし"
              : hospitalizationDailyAmountDays === 1
              ? "無制限"
              : `${hospitalizationDailyAmountDays}日`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => {
              setHospitalizationDailyAmountDays(0);
            }}>
              指定なし
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setHospitalizationDailyAmountDays(60);
              }}
            >
              60日
            </Dropdown.Item>
            <Dropdown.Item onClick={() => {
                setHospitalizationDailyAmountDays(1);
              }}>
              無制限
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="surgery"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>がん手術保障</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {SURGERY_OPTIONS[surgery]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(SURGERY_OPTIONS).map((key, _) => (
              <Dropdown.Item key={key} onClick={() => setSurgery(Number(key))}>
                {SURGERY_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="leavingHospitalOnce"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>がん退院一時金</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {LEAVING_HOSPITAL_ONCE_OPTIONS[leavingHospitalOnce]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(LEAVING_HOSPITAL_ONCE_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() => setLeavingHospitalOnce(Number(key))}
              >
                {LEAVING_HOSPITAL_ONCE_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="cancerHospitalDailyFee"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>がん通院日額</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {cancerHospitalDailyFee === 0 ? '指定なし' : 
              cancerHospitalDailyFee === 3000 ? '3,000円' : 
              cancerHospitalDailyFee === 5000 ? '5,000円' : 
              cancerHospitalDailyFee === 10000 ? '10,000円' : ''}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setCancerHospitalDailyFee(0)}>
              指定なし
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setCancerHospitalDailyFee(3000)}>
              3,000円
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setCancerHospitalDailyFee(5000)}>
              5,000円
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setCancerHospitalDailyFee(10000)}>
              10,000円
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="cancerAdvancedMedical"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>がん先進医療</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {cancerAdvancedMedical ? "あり" : "指定なし"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(BOOL_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() => setCancerAdvancedMedical(Number(key) ? true : false)}
              >
                {BOOL_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="radiotherapy"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>放射線治療</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {RADIOTHERAPY_OPTIONS[radiotherapy]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(RADIOTHERAPY_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() => setRadiotherapy(Number(key))}
              >
                {RADIOTHERAPY_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="anticancerDrugTreatment"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>抗がん剤治療</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {anticancerDrugTreatment ? "あり" : "指定なし"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(BOOL_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() =>
                  setAnticancerDrugTreatment(Number(key) ? true : false)
                }
              >
                {BOOL_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="femaleDiseases"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>女性特有がん</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {femaleDiseases ? "あり" : "指定なし"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(BOOL_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() => setFemaleDiseases(Number(key) ? true : false)}
              >
                {BOOL_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="paymentExemption"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>保険料払込免除</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {paymentExemption ? "あり" : "指定なし"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setPaymentExemption(false);
              }}
            >
              指定なし
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setPaymentExemption(true);
              }}
            >
              あり
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="attention"
        className="listHead mh50"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>備考</span>
      </div>
      <div
        id="condition"
        className="listHead mh50"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>その他（算出条件）</span>
      </div>
      <div
        id="approvalNumber"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>承認番号</span>
      </div>
    </>
  );
};
