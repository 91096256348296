import { useMemo } from "react";

import { MultiSelectDay } from "../../openApi/api";

interface Props {
  label: string;
  required?: boolean;
  options: MultiSelectDay[];
  value?: string[];
  onChange?: (value: string[]) => void;
  selectKey?: string;
}

const MultiSelect = ({
  label,
  required,
  options,
  value,
  onChange,
  selectKey,
}: Props) => {
  const validate = useMemo(() => {
    if (required && value?.length === 0) return `${label}を選択してください`;
    return "";
  }, [value, label, required]);

  const onChangeCheck = (checked: boolean, option_vl: string) => {
    if (!onChange) return;
    const tmpValue = value ? [...value] : [];
    if (checked) {
      onChange([...tmpValue, option_vl]);
    } else {
      onChange(tmpValue.filter((x) => x !== option_vl));
    }
  };
  return (
<div className={`flexColumn ${validate !== "" && "error"}`}>
    <span className="typographyAnswerBtnDescription">
        {label}
        {required ? (
          <span className="typographyAccentOrange">（必須）</span>
        ) : (
          "（任意）"
        )}
      </span>
    <div className={`multi-select-container multi-select ${validate !== "" && "error"}`}>
      
      {options.map((option, index) => (
        <>
          {option?.is_open && (
            <label
              htmlFor={`${selectKey}_${index}`}
              key={`${selectKey}_${index}`}
              className="checkboxBtn inlinflex"
            >
              <input
                type="checkbox"
                id={`${selectKey}_${index}`}
                name=""
                value={option?.label}
                checked={
                  value !== undefined && option.label !== undefined && value.indexOf(option.label) !== -1
                }
                onClick={(e) => onChangeCheck(e.currentTarget.checked, option.label ?? '')}
              />
              <span>{option?.label}</span>
            </label>
          )}
        </>
      ))}
      {validate !== "" && <span className="validation">{validate}</span>}
    </div>
  </div>
  );
};

export default MultiSelect;
