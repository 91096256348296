const DocumentDescription = () => {
  return (
    <div
      className="typographyFontFeaturePalt"
      style={{
        marginBottom: "40px",
      }}
    >
      <p
        style={{
          marginBottom: "1em",
        }}
      >
        お客さまの体調などによりご案内可能な商品が異なりますので、一度電話でヒアリングした後に、最適な資料をご案内いたします。
      </p>
      <p>
        〈注釈〉
        <br />
        ※電話にかかる時間は10分程度です。
        <br />
        ※現在、多くのお客様からのお問い合わせ・ご案内予約をいただいているため、ご指定いただいた時間にご連絡ができない場合があります。あらかじめご了承ください。
      </p>
    </div>
  );
};

export default DocumentDescription;
