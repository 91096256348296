/** @jsxImportSource @emotion/react */
//import { Link } from 'react-router-dom';
import { useMemo, useState } from "react";
import { Button } from "react-bootstrap";

//components
import PageHeader from "../../components/atoms/PageHeader";
import "../../styles/pages/download.scss";
import LabelInput from "../../components/molecules/LabelInput";
import {
  cartStorage,
  //detailDiagnoseStorage,
  simpleDiagnoseStorage,
} from "../../utils/storage";
import { EstimateInsurance1 } from "../../openApi/api";
import { useContactSms } from "../../query/useContactSms";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { telFormatter } from "../../utils/formatter";

const Download_document = () => {
  useDocumentTitle("保デジWEB-問い合わせ");
  
  const baseCode = process.env.REACT_APP_CODE;

  const queryParameters = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const fromComparison = queryParameters.get("fromComparison");

  const simpleDiagnose = useMemo(() => simpleDiagnoseStorage.get(), []);
  //const detailDiagnose = useMemo(() => detailDiagnoseStorage.get(), []);

  const diagnosCode = useMemo(
    () =>
      simpleDiagnose?.result?.diagnos_code ??
      queryParameters.get("diagnosCode") ??
      undefined,
    [simpleDiagnose, queryParameters]
  );

  const cartList = useMemo(() => cartStorage.get()?.insuranceList ?? [], []);

  const estimateInsurances = useMemo(() => {
    return cartList.map((cart) => ({
      insurance_code: cart.insurance_id,
      insurance_fee_codes: cart.insurance_fee_codes,
      insurance_sub_fee_codes: cart.insurance_sub_fee_codes,
    })) as EstimateInsurance1[];
  }, [cartList]);

  const [name, setName] = useState<string>();
  const [nameKana, setNameKana] = useState<string>();
  const [tel, setTel] = useState<string>();
  const [email, setEmail] = useState<string>();
  
  const [telErrors, setTelErrors] = useState<string[]>([]);
  const mobileNumberPattern = /^(090|080|070|050)\d{8}$/;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = telFormatter(e.target.value);
    
    setTel(inputValue);
    if (mobileNumberPattern.test(inputValue)){
      setTelErrors([]);
    } else {
      setTelErrors(["携帯電話番号を入力してください。"]);
    }
  };
  
  const [nameKanaErrors, setNameKanaErrors] = useState<string[]>([]);
  
  const kanaPattern = /^[^0-9０-９]+$/;
  const handleKanaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setNameKana(inputValue);
    if (kanaPattern.test(inputValue)){
      setNameKanaErrors([]);
    } else {
      setNameKanaErrors(["カタカナで入力してください。"]);
    }
  };

  const onError = (e: any) => {
    if (e.tel) {
      setTelErrors(e.tel);
    }

    if (e.name_kana) {
      setNameKanaErrors(e.name_kana);
    }
  };

  const { mutate: postContactSms } = useContactSms({ onError });

  const clearUndefine = () => {
    if (name === undefined) setName("");
    if (nameKana === undefined) setNameKana("");
    if (tel === undefined) setTel("");
    if (email === undefined) setEmail("");
  };

  const onSubmit = () => {
    if (
      nameKana === undefined ||
      nameKana === "" ||
      tel === undefined ||
      tel === "" ||
      !mobileNumberPattern.test(tel) ||
      !kanaPattern.test(nameKana)
    ) {
      clearUndefine();
      return;
    }

    postContactSms({
      diagnos_code: diagnosCode,
      name,
      name_kana: nameKana,
      tel: telFormatter(tel),
      email,
      from_comparison: fromComparison === "true",
      estimate_insurances: estimateInsurances,
      base_code: baseCode ?? '',
    });
  };

  return (
    <>
      <section className="download-fp-section">
        <PageHeader>SMSで保存</PageHeader>
        <div className="downloadDocumentForm">
          <LabelInput
            label="カナ苗字"
            placeholder="ミョウジ"
            value={nameKana}
            errors={nameKanaErrors}
            onChange={handleKanaChange}
            onBlur={(e) => setNameKana(e.target.value)}
            required
          />
          
          <LabelInput
            label="電話番号（ハイフンなし）"
            placeholder=""
            value={tel}
            onChange={handleChange}
            onBlur={(e) => setTel(telFormatter(e.target.value))}
            errors={telErrors}
            required
            type="tel"
          />
          <Button
            color="primary"
            className="downloadDocumentSubmit"
            onClick={onSubmit}
          >
            SMSで診断結果を受け取る
          </Button>
        </div>
      </section>
    </>
  );
};
export default Download_document;
