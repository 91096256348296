/** @jsxImportSource @emotion/react */
import * as txt from "../atoms/Typografy";
const PolicyDivFa = () => {
  return (
    <div className="policy-modal">
      <h3
        className="policy-title"
        css={[
          txt.typographyTextTitle,
          txt.typographyPrimaryColor,
          txt.typographyAlignCenter,
        ]}
      >
        利用規約
      </h3>
      <span className="policy-content">
        <p>第1条（目的）</p>

        <ul>
          <li>
            1.
            株式会社フィナンシャル・エージェンシー（以下「当社」）は、運営する必要保障額算出（以下「本シミュレーション」）サイトならびに保険比較サイト（以下総称して「本サイト」）において提供するサービス（以下「本サービス」）の利用につき、以下のとおり利用規約を定めます（以下「本規約」）。本サービスのご利用者（以下「ご利用者」）は、あらかじめ本規約に同意の上、本サービスを利用するものとします。本利用規約の内容にご同意いただけない場合は、本サービスのご利用をお控えください。
          </li>
          <li>
            2.
            本規約の他、随時当社より公表される規約、ガイドライン、通知、本サイト上に記載の諸注意等（以下「個別規約等」）は、本規約と一体をなすものであり、個別規約等の内容と本規約の内容とが矛盾する場合には、本規約に別段の定めがない限り、個別規約等の内容が本規約の内容に優先して適用されます。
          </li>
          <li>
            3.
            各種保険商品の勧誘・募集等は、当社の保険募集人が行いますが、ご利用者の希望される申込方法によっては、当社が提携する保険代理店の保険募集人が勧誘・募集等を実施する場合があります。
          </li>
        </ul>

        <p>第2条（適用範囲）</p>
        <ul>
          <li>
            本規約は、ご利用者が本サービスを利用する場合に適用されるものとします。
          </li>
        </ul>
        <p>第3条（本サービスの内容） </p>
        <ul>
          <li>1. 本サービスの内容は、以下のとおりです。</li>
          <li>
            （1）Web上でご利用者が入力した内容に基づいた必要保障額の算出および結果表示
          </li>
          <li>（2）保険等に関する情報の掲載・閲覧サービス</li>
          <li>
            （3）当社または当社のグループ会社と契約する生命保険会社、損害保険会社、少額短期保険業者、保険募集代理店（以下「保険会社等」）が取り扱う保険商品について、見積作成、保険の資料等の送付、相談対応等を提供するサービス
          </li>
          <li>（4）その他前三号のサービスに付随又は関連するサービス </li>
          <li>
            2.
            本サービスはご利用者からの依頼に基づいて行われるものとし、ご利用者がこれらのサービスの利用に必要な操作および情報送信を行った場合には、ご利用者本人が当該依頼を行ったものとみなされます。
          </li>
          <li>3. 本サービスの利用に係る対価は、無償とします。 </li>
          <li>
            4.
            本サービスの利用に関連して、ご利用者が利用する端末に何らかの影響が生じ、これによりご利用者が損害を被った場合にも、当社の責めに帰すべき事由がない限り、当社は一切の責任を負わないものとします。
          </li>
          <li>
            5.
            本サービスは、予告なく内容の変更、提供の中断又は終了をする場合があります。
          </li>
        </ul>
        <p>第4条（提供する情報の内容） </p>
        <ul>
          <li>
            1.
            当社は、本サービスにおいて掲載・閲覧に供される保険商品その他保険に関する情報を十分な注意を払って提供しておりますが、当該情報の内容の正確性、妥当性、適法性、目的適合性その他一切の事項について保証するものではありません。本サービスにおいて掲載・閲覧に供される保険商品に関する情報の最終的な内容の確認は、ご利用者が保険会社等に対して直接行うこととします。
            2.
          </li>
          <li>
            本サービスにおいて提供する情報の一部または全部は、ご利用者に対して予告なく、変更、中断または停止される場合があります。
          </li>
        </ul>
        <p>第5条（留意事項） </p>
        <ul>
          <li>
            1.
            当社は、ご利用者と保険会社等との間で締結される保険契約または共済契約等につき当事者となるものではありません。
          </li>
          <li>
            2.
            本サイトに掲載する保険商品その他本サービスに関連して当社が紹介する保険商品の中には、ご利用者が当社に対して行った情報送信が、保険会社等に対する保険契約の申込とはならない場合があります。その場合は、保険契約の申込には、ご利用者から保険会社等に対して直接手続を行っていただく必要がありますので、申込手続の内容等については、保険会社等にお問い合わせください。
          </li>
          <li>
            3.
            当社は、ご利用者に対し、保険会社等との保険契約の締結または更新、更改等の可否、これらの時期または内容等について保証いたしません。
          </li>
          <li>
            4.
            当社はご利用者と保険会社等との保険契約または共済契約の成否、内容または履行等に関し、何ら責任を負わないものとし、保険会社等とご利用者との間で解決するものとします。
          </li>
          <li>
            5.
            本シミュレーション掲載のデータ等は概算であり、実際の数値とは異なります。
          </li>
          <li>
            6.
            本シミュレーションはあくまでも目安であり、将来の結果を予測し、保証するものではありません。
          </li>
          <li>
            7.
            本シミュレーションは、2022年度の税制・社会保険制度が今後も継続することを前提に作成しています。前提としている制度については、今後の制度改正等によって変更される可能性があります。税制や法律に関わる個別具体的なご対応は税理士・公認会計士・弁護士等の専門家へご相談・ご確認ください。
          </li>
          <li>
            8 .
            本シミュレーションおよび掲載された情報を利用することで生じるいかなる損害(直接的、間接的を問わず)についても、当社が責任を負うものではありません。実際の資産運用ならびに保険契約または共済契約の締結や解約にあたっては、ご利用者の責任において最終的にご判断ください。
          </li>
        </ul>
        <p>第6条（個人情報の取扱い） </p>
        <ul>
          <li>
            1.
            本サービスにおける個人情報の取扱いについては、当社が定める「個人情報保護方針」(https://www.financial-agency.com/compliance/pesonalinfo/)の定めに従うものとします。
          </li>
          <li>
            2.
            当社は、ご利用者ご本人を識別または特定することができない状態の統計的なデータを作成し、当該データを利用する場合があります。
          </li>
          <li>
            3.
            本規約に同意し個人情報を当社に送信した場合は、その後本サービスを継続して利用しているか否かに関わらず、本規約の効力は継続するものとします。このため、本規約に同意し個人情報を当社に送信した後に、当社に送信した個人情報について、利用停止、消去、又は第三者への提供の拒否等をご希望される場合は、当社が定める「個人情報保護方針」に基づく手続きをお取りいただくことが必要となります。
          </li>
          <li>
            4.
            個人情報の入力はご利用者の任意ですが、すべての項目にご入力いただけなかった場合、各種サービスにおいて、適切な対応ができなくなる場合がありますのでご了承下さい。
          </li>
        </ul>
        <p>第7条（遵守事項及び禁止行為） </p>
        <ul>
          <li>
            1.
            ご利用者は、自らの責任において本サービスを利用するものとし、本サービスの利用における自らの行為、および本規約への違反等から生じる一切の責任を負担するものとします。
          </li>
          <li>
            2.
            本サービスの利用において当社または第三者との間に問題が生じた場合、ご利用者は自らの責任と費用負担によりこれを解決し、当社に迷惑や損害を一切与えないものとします。
          </li>
          <li>
            3.
            本サービスの利用にあたり、ご利用者が法令または本規約に違反したことに起因して、当社が損害(弁護士費用の負担を含みます。)を被った場合、当社の請求にしたがって直ちにこれを補償しなければなりません。
          </li>
          <li>
            4.
            ご利用者は、本サービスを利用するために必要なOS、ソフトウェア、インターネット接続回線その他一切の当社所定の仕様を満たす利用環境を、自己の責任と費用をもって準備するものとします。また、利用者は上記の他、通信費用、インターネット接続費用、電気代その他本サービスを利用するために必要な費用がかかることを了承し、これを自ら負担するものとします。
          </li>
          <li>
            5.
            ご利用者は、本サービスにおいて次に該当する行為を行ってはなりません。
          </li>
          <li>
            （1 ）
            法令、裁判所の判決、決定もしくは命令または法令上拘束力のある行政措置に違反する行為。
          </li>
          <li>
            （2）
            公の秩序または善良の風俗を害するおそれのある行為(過度に暴力的な表現、露骨な性的表現その他反社会的な内容を含み他人に不快感を与える表現を投稿、掲載、公開、送信する行為などを含みます。)。
          </li>
          <li>
            （3）
            当社の許可なく勧誘、営業、広告、宣伝等の活動を行い、または営利目的の活動を行う行為。
          </li>
          <li>
            （4）
            当社所定の仕様を満たした通信端末機器または通信回線設備以外からのインターネット接続により本サービスを利用する行為。
          </li>
          <li>
            （5）
            当社所定の仕様を満たさない利用環境から本サービスを利用しようとする行為。
          </li>
          <li>
            {" "}
            （6） コンピュータプログラム上の不具合を意図的に利用する行為。
          </li>
          <li>（7） 当社の定める利用条件、操作手順等に従わない行為。</li>
          <li>
            （8）
            本サービスが予定している利用態様を超えて、本サービスを利用（複製、送信、転載、改変などの行為を含みます。）する行為。
          </li>
          <li>
            （9） 本サービスを不正に操作する外部ツールの利用・作成・頒布。
          </li>
          <li>
            （10） 販売等を行う行為、その他営利目的で本サービスを利用する行為。{" "}
          </li>
          <li>
            （11）
            本サービスに掲載されている情報の改竄、消去等、不正なアクセスその他の行為またはかかる行為を行おうと試みる行為。
          </li>
          <li>（12） ご利用者以外の者に成りすます行為。</li>
          <li>（13） 当社による本サービスの運営・提供を妨害する行為。 </li>
          <li>
            （14）
            当社または第三者の権利（著作権、商標権、特許権等の知的財産権、名誉権、プライバシー権その他法令上または契約上の権利を広く含みます。）を侵害する行為および当社または第三者の名誉、信用を毀損する行為。
          </li>
          <li>（15） 反社会的勢力に対する利益供与その他の協力行為。 </li>
          <li>（16） 宗教活動または宗教団体への勧誘行為。 </li>
          <li>
            （17） 上記のいずれかに該当する行為を援助または助長する行為。{" "}
          </li>
          <li>
            （18）
            前各号に定めるほか、法令に反する行為、本規約に反する行為その他客観的な根拠に基づいて本サービスの利用を継続させることが不適切と当社が合理的に判断する行為。
          </li>
          <li>
            6.
            ご利用者が前項各号のいずれかの規定に該当した場合は、当社は、当該ご利用者に対して、本サービスの利用の停止その他の必要な措置をとることができます。この場合において、当社の責めに帰すべき事由によらずにご利用者に生じた損害については、当社は一切の責任を負いません。
          </li>
        </ul>
        <p>第8条（本サービスの中断等） </p>
        <ul>
          <li>
            1.
            当社は、定期的または臨時のメンテナンス作業等を行う場合または緊急やむを得ないと当社が合理的に判断する場合において、本サービスの提供を一時中断することができます。
          </li>
          <li>
            2.
            ご利用者または当社以外の第三者が使用する機器・通信回線等に原因がある場合、本サービスの機能が正常に動作しており原因が特定できない場合等、当社の責めに帰すべき事由によらず、本サービスに関してご利用者に生じた不利益および損害について、当社はご利用者に対して補償等を行う責任を負いません。
          </li>
        </ul>
        <p>第9条（免責事項） </p>
        <ul>
          <li>
            1.
            本サービスの提供または本サービスの変更、遅延、中止、廃止その他本サービスに関して、当社の責めに帰すべき事由によらずにご利用者または第三者が損害を被った場合は、当社は一切の責任を負わないものとします。
          </li>
          <li>
            2.
            当社は、システム障害、誤作動または通信回線の障害等の当社の責めに帰すべき事由によらずに生じた結果につき、責任を負わないものとします。
          </li>
          <li>
            3. 当社は、本サービスでは、SSL（Secure Sockets
            Layer）と呼ばれる暗号化通信プロトコルを使用してご利用者の情報の安全に努めていますが
            、ご利用者が当社に送信する情報が当社の責めに帰すべき事由によらずにインターネット上で傍受され、または漏洩した場合であっても、当社は責任を負わないものとします。
          </li>
          <li>
            4.
            本サービスの利用により伝達される情報は、当社、保険会社等の休業またはシステム障害その他の原因により遅延する場合があります。
          </li>
          <li>
            5.
            本サービスにおいてご利用者に対して行われる情報伝達は、当社が受信したご利用者の電話番号、電子メールアドレスまたは住所のいずれかに宛て発信することにより足りるものとします。
          </li>
          <li>
            6.
            当社は、本規約の他の条項に定める場合のほか、保険会社等第三者の行った行為につき、当社の責めに帰すべき事由がない限り、ご利用者に対し責任を負いません。
          </li>
          <li>
            7.
            本サービス中に、他のWebサイトやリソースへのリンクを設置する場合があります。リンク先のWebサイトやリソースはそれぞれの運営者が独立して管理しており、当社ではその内容や安全性などについては関知しておらず、一切の責任を負わないものとします。
          </li>
          <li>
            8．本サービスを経由して保険契約を締結された場合、引受保険会社が発行または交付する保険証券および保険約款の内容が本サービスで提供する情報に優先されます。
          </li>
          <li>
            9 .
            本規約に基づく契約が消費者契約となる場合、本規約中、当社の責任の全部を免責する規定は適用されないものとします。ただし、この場合でも、当社に故意又は重過失がある場合を除き、当社の賠償責任はご利用者について発生した直接かつ現実に生じた通常の損害に限るものとします。
          </li>
        </ul>
        <p>第 10条（知的財産権）</p>
        <ul>
          <li>
            本サービスに含まれているコンテンツ及び個々の情報、商標、画像、動画、広告、デザイン等（以下「コンテンツ等」）に関する著作権、商標権その他の財産権は、当社もしくは当該コンテンツ等を創作した著作者又は著作権者に帰属しています。
          </li>
        </ul>
        <p>第11条（本サービスの利用制限） </p>
        <ul>
          <li>
            1.
            当社は、本サービスのご利用を、一定の年齢の範囲の方に限定したり、当社が定める本人確認等の手続を経て一定の要件を満たしたご利用者のみに限定したりする等、利用に際して条件を付すことができるものとします。
          </li>
          <li>
            2.
            当社は反社会的勢力の構成員（過去に構成員であった者を含みます）およびその関係者または、本サービスを悪用したりあるいは第三者の利益を害するような利用方法をする者に対しては本サービスの利用をお断りしています。かかる事実が判明した場合、当社は、当社の判断により、本サービスの利用停止等の必要な手段をとることができるものとします。
          </li>
        </ul>
        <p>第12条（本規約の変更）</p>
        <ul>
          <li>
            当社は、本規約を変更する場合があります。この場合には、本規約を変更する旨、変更後の規約の内容およびその効力発生日を、当該効力発生日の相当な期間前に本
            サイトに掲示するものとします。効力発生日後の本サービスの利用については、変更後の規約が適用されるものとし、ご利用者はこれをあらかじめ承諾するものとします。
          </li>
        </ul>
        <p>第13条（準拠法・管轄） </p>
        <ul>
          <li>
            1.
            本規約の成立、解釈および履行その他本サービスの提供および利用に関しては、日本国法が適用されるものとします。
          </li>
          <li>
            2.
            本規約または本サービスに関して生じた紛争については、東京地方裁判所
            を第一審の専属的合意管轄裁判所とします。
          </li>
        </ul>
        <ul className="mt-5">
          <li>
            株式会社フィナンシャル・エージェンシー
          </li>
          <li>
            東京都渋谷区恵比寿1-19-19 恵比寿ビジネスタワー16F
          </li>
        </ul>
      </span>
    </div>
  );
};
export default PolicyDivFa;
