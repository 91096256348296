/** @jsxImportSource @emotion/react */
import { Figure, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../styles/components/diagnoseResultSavePopup.scss";

export const SaveDiagnoseResultPopup = () => {
  const navigate = useNavigate();
  return (
    <div className="saveDiagnoseResultPopup">
      <div className="textCon">
        <p className="saveText typographyTextBodyBold typographySecondaryColor">
          保存する
        </p>
        <p className="info typographySecondaryColor">
          お好きな方法で診断結果をお送りいたします
        </p>
      </div>
      <Stack
        className="iconCon justify-content-center"
        direction="horizontal"
        gap={4}
      >
        <Figure onClick={() => navigate("/download-sms?fromComparison=true")}>
          <Figure.Image
            width={87}
            height={87}
            alt="SMS"
            src="/img/smsIcon.svg"
          />
          <Figure.Caption className="text-center">SMS</Figure.Caption>
        </Figure>
        <Figure onClick={() => navigate("/download-mail?fromComparison=true")}>
          <Figure.Image
            width={87}
            height={87}
            alt="mail"
            src="/img/mailIcon.svg"
          />
          <Figure.Caption className="text-center">メール</Figure.Caption>
        </Figure>
      </Stack>
    </div>
  );
};
export default SaveDiagnoseResultPopup;
