import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Api } from "../openApi";
import { ApiSimpleDiagnoseRequest } from "../openApi/api";
import { PartnerData, partnerDataStorage, simpleDiagnoseStorage } from "../utils/storage";

export const useSimpleDiagnose = () => {
  const navigate = useNavigate();

  const partnerData : PartnerData = partnerDataStorage.get();

  return useMutation(
    ["postSimpleDiagnose"],
    async (params: ApiSimpleDiagnoseRequest) => {
      return Api.postApiSimpleDiagnose({
        ...params,
        partner_code: partnerData?.partnerCode ?? undefined,
        Inflow_source_code: partnerData?.inflowCode ?? undefined,
      });
    },
    {
      onSuccess: (res) => {
        simpleDiagnoseStorage.set({
          request: JSON.parse(res.config.data),
          result: res.data,
        });
        // partnerDataStorage.remove();
        navigate("/result-simple");
      },
      onError: (e) => {
        console.log(e);
      },
    }
  );
};
