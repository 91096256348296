/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
//import { Link } from 'react-router-dom';
import { useState } from "react";
import * as txt from "../../components/atoms/Typografy";
import {
  policyClosedBtn,
  downloadDocumentSubmit,
} from "../../components/atoms/Button";
import * as grid from "../../components/molecules/grid";
import {
  downloadDocumentForm,
  policyModal,
} from "../../components/molecules/layout";
import { Button } from "react-bootstrap";

//components
import PolicyDivFa from "../../components/molecules/PolicyDivFa";
import PolicyDivSsnb from "../../components/molecules/PolicyDivSsnb";
import PolicyDivTest from "../../components/molecules/PolicyDivTest";

const DownloadLine = () => {
  const [modal, setModal] = useState(false);
  const baseCode = process.env.REACT_APP_CODE;
  
  return (
    <>
      <section
        css={css`
          width: 100%;
          max-width: 592px;
          margin: 0 auto;
          @media (max-width: 576px) {
            width: 92%;
          }
        `}
      >
        <h2
          css={[
            txt.typographyPrimaryColor,
            txt.typographyTextSubTitle,
            txt.typographyAlignCenter,
            css`
              padding: 40px 0;
            `,
          ]}
        >
          LINEで保存
        </h2>
        <form css={downloadDocumentForm}>
          <label htmlFor="" css={grid.flexColumn}>
            <span css={txt.typographyAnswerBtnDescription}>
              名前<span css={txt.typographyAccentOrange}>（必須）</span>
            </span>
            <input type="text" placeholder="苗字　名前" />
          </label>
          <label htmlFor="" css={grid.flexColumn}>
            <span css={txt.typographyAnswerBtnDescription}>
              電話番号<span css={txt.typographyAccentOrange}>（必須）</span>
            </span>
            <input type="text" placeholder="0000-00-0000" />
          </label>
          <label htmlFor="" css={grid.flexColumn}>
            <span css={txt.typographyAnswerBtnDescription}>
              LINE&nbsp;ID<span css={txt.typographyAccentOrange}>（必須）</span>
            </span>
            <input type="text" />
          </label>

          <span
            css={[
              txt.typographyAnswerBtnDescription,
              txt.typographyAlignCenter,
            ]}
          >
            <span
              css={css`
                color: #0d6efd;
                cursor: pointer;
              `}
              onClick={() => setModal(true)}
            >
              利用規約
            </span>
            へ同意したものとみなされます
          </span>
          <Button css={downloadDocumentSubmit}>送信する</Button>
        </form>
      </section>
      {modal ? (
        <div css={policyModal}>
          <div
            css={css`
              position: relative;
            `}
          >
            <span onClick={() => setModal(false)} css={policyClosedBtn}>
              <span></span>
              <span></span>
            </span>
            {baseCode === 'financial-agency' && (<PolicyDivFa />)}
            {baseCode === 'ssnb' && (<PolicyDivSsnb />)}
            {baseCode === 'test' && (<PolicyDivTest />)}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default DownloadLine;
