/**  @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  ApiWholeDeathInsurancesRankResponse,
  ApiWholeDeathInsurancesRankResponseInsurancesInner,
} from "../../../../openApi/api";
import { ContentsColumnWholeDeath } from "./ContentsColumnWholeDeath";
import { HeadColumnCommon } from "./HeadColumnCommon";
import { HeadColumnWholeDeath } from "./HeadColumnWholeDeath";

interface Props {
  typeOfInsurance: number;
  response: ApiWholeDeathInsurancesRankResponse | undefined;
  age: number;
  sex: number;
  paymentExemption: boolean;
  setPaymentExemption: (paymentExemption: boolean) => void;
  setIsPopupOpen: (isPopupOpen: boolean) => void;
  setFocusedIndex: (focusedIndex: number) => void;
  insuranceListInCart: ApiWholeDeathInsurancesRankResponseInsurancesInner[];
  setInsuranceListInCart: (
    prevCart: ApiWholeDeathInsurancesRankResponseInsurancesInner[]
  ) => void;
  insuranceIdListInCart: string[];
  setInsuranceIdListInCart: (insuranceIdListInCart: string[]) => void;

  // 終身死亡保険特有のprops
  wholeDeathBenefit: number;
  setWholeDeathBenefit: (wholeDeathBenefit: number) => void;
  cancelRepayment: boolean;
  setCancelRepayment: (cancelRepayment: boolean) => void;
  livingNeeds: boolean;
  setLivingNeeds: (livingNeeds: boolean) => void;
  triggerWholeDeathInsuranceRankAPI: boolean;
  setTriggerWholeDeathInsuranceRankAPI: (_: boolean) => void;
  
  checkedRankList: string[];
  setCheckedRankList: (checkedRankList: string[]) => void;
  
  isContentsColumnRendered: boolean;
  setIsContentsColumnRendered: (isContentsColumnRendered: boolean) => void;
}

export const TableWholeDeath = ({
  typeOfInsurance,
  response,
  age,
  sex,
  paymentExemption,
  setPaymentExemption,
  setIsPopupOpen,
  setFocusedIndex,
  insuranceListInCart,
  setInsuranceListInCart,
  insuranceIdListInCart,
  setInsuranceIdListInCart,
  wholeDeathBenefit,
  setWholeDeathBenefit,
  cancelRepayment,
  setCancelRepayment,
  livingNeeds,
  setLivingNeeds,
  triggerWholeDeathInsuranceRankAPI,
  setTriggerWholeDeathInsuranceRankAPI,
  
  checkedRankList,
  setCheckedRankList,
  
  isContentsColumnRendered,
  setIsContentsColumnRendered,
}: Props) => {
  
  const [isDisplayOnlyChecked, setIsDisplayOnlyChecked] = useState<boolean>(false);
  
  useEffect(() => {
    setIsContentsColumnRendered(true);
  }, [
    isDisplayOnlyChecked,
    setIsDisplayOnlyChecked,
    setIsContentsColumnRendered
  ]);
  
  return (
    <section className="comparisonTableSection">
      <Container>
        <Row>
          <Col className="speechBoxContainer" md={1}>
            <div className="speechBox">
              保障内容でも
              <br />
              絞り込み可能
            </div>
          </Col>
          <Col md={1} className="insuranceTableTitle">
            {response?.table_header_text}
          </Col>
        </Row>
      </Container>
      <div className="insuranceTable">
        <div className="headRow">
          <HeadColumnCommon
            isDisplayOnlyChecked={isDisplayOnlyChecked}
            setIsDisplayOnlyChecked={setIsDisplayOnlyChecked}
            isContentsColumnRendered={isContentsColumnRendered}
            setIsContentsColumnRendered={setIsContentsColumnRendered}
          />
          <div className="headRowSpecific">
            <HeadColumnWholeDeath
              paymentExemption={paymentExemption}
              setPaymentExemption={setPaymentExemption}
              deathBenefit={wholeDeathBenefit}
              setDeathBenefit={setWholeDeathBenefit}
              cancelRepayment={cancelRepayment}
              setCancelRepayment={setCancelRepayment}
              livingNeeds={livingNeeds}
              setLivingNeeds={setLivingNeeds}
              triggerWholeDeathInsuranceRankAPI={
                triggerWholeDeathInsuranceRankAPI
              }
              setTriggerWholeDeathInsuranceRankAPI={
                setTriggerWholeDeathInsuranceRankAPI
              }
              isContentsColumnRendered={isContentsColumnRendered}
              setIsContentsColumnRendered={setIsContentsColumnRendered}
            />
          </div>
        </div>
        <div className="contentRow">
          {response?.insurances &&
            response?.insurances.map(
              (
                insurance: ApiWholeDeathInsurancesRankResponseInsurancesInner,
                index: number
              ) =>
                !isDisplayOnlyChecked ||
                (isDisplayOnlyChecked &&
                  checkedRankList.includes(insurance.insurance_id ?? '')) ? (
                  <ContentsColumnWholeDeath
                    key={`${typeOfInsurance}_${index + 1}`}
                    typeOfInsurance={typeOfInsurance}
                    insurance={insurance}
                    age={age}
                    sex={sex}
                    deathBenefit={wholeDeathBenefit}
                    lowPriceRank={index + 1}
                    setIsPopupOpen={setIsPopupOpen}
                    setFocusedIndex={setFocusedIndex}
                    insuranceListInCart={insuranceListInCart}
                    setInsuranceListInCart={setInsuranceListInCart}
                    insuranceIdListInCart={insuranceIdListInCart}
                    setInsuranceIdListInCart={setInsuranceIdListInCart}
                    cancelRepayment={cancelRepayment}
                    livingNeeds={livingNeeds}
                    paymentExemption={paymentExemption}
                    checkedRankList={checkedRankList}
                    setCheckedRankList={setCheckedRankList}
                    setIsContentsColumnRendered={setIsContentsColumnRendered}
                    numberOfInsurances={response?.insurances?.length}
                  />
                ) : (
                  <div key={index + 1}></div>
                )
            )}
        </div>
      </div>
    </section>
  );
};
