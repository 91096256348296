import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "bootstrap/dist/css/bootstrap.min.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/main.scss";

import ThemeProvider from "./providers/ThemeProvider";
import DefaultRoutes from "./routes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const Contents = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        {/* basename => sub directory */}
        <Router>
          <DefaultRoutes />
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

const baseClassName = process.env.REACT_APP_CODE;
if (baseClassName !== undefined){
  document.body.classList.add(baseClassName);
}

//Render

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<Contents />);
