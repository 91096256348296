import PolicyDivFa from "./PolicyDivFa";
import PolicyDivSsnb from "./PolicyDivSsnb";
import PolicyDivTest from "./PolicyDivTest";

interface Props {
  visible: boolean;
  onClose: () => void;
}

const PolicyModal = ({ visible, onClose }: Props) => {
  
  const baseCode = process.env.REACT_APP_CODE;
  
  return visible ? (
    <div className="policyModal">
      <div
        style={{
          position: "relative"
        }}
        className="policyModalWrap"
      >
        <span onClick={onClose} className="policyClosedBtn">
          <span></span>
          <span></span>
        </span>
        {baseCode === 'financial-agency' && (<PolicyDivFa />)}
        {baseCode === 'ssnb' && (<PolicyDivSsnb />)}
        {baseCode === 'test' && (<PolicyDivTest />)}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PolicyModal;
