/**  @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import * as txt from "../../../../components/atoms/Typografy";
import {
  BOOL_OPTIONS,
  COMMUTING_DAILY_OPTIONS,
  COMMUTING_ONCE_OPTIONS,
  HOSPITALIZATION_GUARANTEE_OPTIONS,
  HOSPITALIZATION_ONCE_OPTIONS,
} from "./DropdownItem";
import { MedicalTableRows, setHeightToTargetElement } from "./utils";

interface Props {
  hospitalizationGuarantee: number;
  setHospitalizationGuarantee: (hospitalizationGuarantee: number) => void;
  paymentExemption: boolean;
  setPaymentExemption: (paymentExemption: boolean) => void;
  hospitalizationGuaranteeDays: number;
  setHospitalizationGuaranteeDays: (
    hospitalizationGuaranteeDays: number
  ) => void;
  hospitalizationOnce: number;
  setHospitalizationOnce: (hospitalizationOnce: number) => void;
  surgeryGuarantee: boolean;
  setSurgeryGuarantee: (surgeryGuarantee: boolean) => void;
  commutingDailyAmount: number;
  setCommutingDailyAmount: (commutingDailyAmount: number) => void;
  commutingDailyAmountDays: number;
  setCommutingDailyAmountDays: (commutingDailyAmountDays: number) => void;
  commutingOnce: number;
  setCommutingOnce: (commutingOnce: number) => void;
  advancedMedical: boolean;
  setAdvancedMedical: (advancedMedical: boolean) => void;
  cancerSpecialContractAmount: number;
  setCancerSpecialContractAmount: (cancerSpecialContractAmount: number) => void;
  specifiedDisease: boolean;
  setSpecifiedDiseaseInsurance: (specifiedDisease: boolean) => void;
  deathSpecialContractAmount: number;
  setDeathSpecialContractAmount: (deathSpecialContractAmount: number) => void;
  congratulatoryMoney: boolean;
  setCongratulatoryMoney: (congratulatoryMoney: boolean) => void;
  triggerMedicalInsuranceRankAPI: boolean;
  setTriggerMedicalInsuranceRankAPI: (_: boolean) => void;
  isContentsColumnRendered: boolean;
  setIsContentsColumnRendered: (isContentsColumnRendered: boolean) => void;
}

export const HeadColumnMedical = ({
  hospitalizationGuarantee,
  setHospitalizationGuarantee,
  paymentExemption,
  setPaymentExemption,
  hospitalizationGuaranteeDays,
  setHospitalizationGuaranteeDays,
  hospitalizationOnce,
  setHospitalizationOnce,
  surgeryGuarantee,
  setSurgeryGuarantee,
  commutingDailyAmount,
  setCommutingDailyAmount,
  commutingDailyAmountDays,
  setCommutingDailyAmountDays,
  commutingOnce,
  setCommutingOnce,
  advancedMedical,
  setAdvancedMedical,
  cancerSpecialContractAmount,
  setCancerSpecialContractAmount,
  specifiedDisease,
  setSpecifiedDiseaseInsurance,
  deathSpecialContractAmount,
  setDeathSpecialContractAmount,
  congratulatoryMoney,
  setCongratulatoryMoney,
  triggerMedicalInsuranceRankAPI,
  setTriggerMedicalInsuranceRankAPI,
  isContentsColumnRendered,
  setIsContentsColumnRendered,
}: Props) => {
  
  useEffect(() => {
    if (isContentsColumnRendered) {
      MedicalTableRows.map((selectorName, index) => {
        setHeightToTargetElement(selectorName);
        return null;
      });
      
      setIsContentsColumnRendered(false);
    }
  }, [
    isContentsColumnRendered,
    setIsContentsColumnRendered,
  ]);
  
  return (
    <>
      <div className="twoColumns">
        <div className="leftColumn listHead">
          <span className="guaranteeText">入院保障</span>
        </div>
        <div
          id="hospitalizationGuarantee"
          className="listHead mh162"
        >
          <span css={txt.typographyBlock}>入院日額</span>
          <Dropdown>
            <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
              {HOSPITALIZATION_GUARANTEE_OPTIONS[hospitalizationGuarantee]}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.keys(HOSPITALIZATION_GUARANTEE_OPTIONS).map(
                (key, _) => (
                  <Dropdown.Item
                    key={key}
                    onClick={() => {
                      setHospitalizationGuarantee(Number(key));
                      setTriggerMedicalInsuranceRankAPI(
                        !triggerMedicalInsuranceRankAPI
                      );
                    }}
                  >
                    {HOSPITALIZATION_GUARANTEE_OPTIONS[Number(key)]}
                  </Dropdown.Item>
                )
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div
          id="hospitalizationGuaranteeDays"
          className="listHead mh100"
          css={[txt.typographyAlignCenter]}
        >
          <span css={txt.typographyBlock}>入院日数</span>
          <Dropdown>
            <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
              {hospitalizationGuaranteeDays === 0
                ? "指定なし"
                : hospitalizationGuaranteeDays === 1
                ? "無制限"
                : `${hospitalizationGuaranteeDays}日`}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setHospitalizationGuaranteeDays(0);
                }}
              >
                指定なし
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setHospitalizationGuaranteeDays(30);
                }}
              >
                30日
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setHospitalizationGuaranteeDays(60);
                }}
              >
                60日
              </Dropdown.Item>
              <Dropdown.Item
              	onClick={() => {
              		setHospitalizationGuaranteeDays(120);
            	}}
              >
                120日
              </Dropdown.Item>
              <Dropdown.Item
              	onClick={() => {
              		setHospitalizationGuaranteeDays(180);
            	}}
              >
                180日
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div
          id="hospitalizationOnce"
          className="listHead mh100"
          css={[txt.typographyAlignCenter]}
        >
          <span css={txt.typographyBlock}>入院一時金</span>
          <Dropdown>
            <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
              {HOSPITALIZATION_ONCE_OPTIONS[hospitalizationOnce]}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.keys(HOSPITALIZATION_ONCE_OPTIONS).map((key, _) => (
                <Dropdown.Item
                  key={key}
                  onClick={() => setHospitalizationOnce(Number(key))}
                >
                  {HOSPITALIZATION_ONCE_OPTIONS[Number(key)]}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div
        id="surgeryGuarantee"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>手術</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {BOOL_OPTIONS[surgeryGuarantee ? 1 : 0]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(BOOL_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() => setSurgeryGuarantee(Number(key) ? true : false)}
              >
                {BOOL_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="twoColumns">
        <div className="leftColumn listHead">
          <span className="guaranteeText">通院保障</span>
        </div>
        <div
          id="commutingDailyAmount"
          className="listHead mh162"
          css={[txt.typographyAlignCenter]}
        >
          <span css={txt.typographyBlock}>通院日額</span>
          <Dropdown>
            <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
              {COMMUTING_DAILY_OPTIONS[commutingDailyAmount]}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.keys(COMMUTING_DAILY_OPTIONS).map((key, _) => (
                <Dropdown.Item
                  key={key}
                  onClick={() => {
                    setCommutingDailyAmount(Number(key));
                  }}
                >
                  {COMMUTING_DAILY_OPTIONS[Number(key)]}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div
          id="commutingDailyAmountDays"
          className="listHead mh100"
          css={[txt.typographyAlignCenter]}
        >
          <span css={txt.typographyBlock}>通院日数</span>
          <Dropdown>
            <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
              {commutingDailyAmountDays === 0
                ? "指定なし"
                : commutingDailyAmountDays === 1
                ? "無制限"
                : `${commutingDailyAmountDays}日`}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => {
              	  setCommutingDailyAmountDays(0);
                }}>
                指定なし
              </Dropdown.Item>
              <Dropdown.Item onClick={() => {
              	  setCommutingDailyAmountDays(30);
              	}}>
                30日
              </Dropdown.Item>
              <Dropdown.Item onClick={() => {
              	  setCommutingDailyAmountDays(60);
              }}>
                60日
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div
          id="commutingOnce"
          className="listHead mh100"
          css={[txt.typographyAlignCenter]}
        >
          <span css={txt.typographyBlock}>通院一時金</span>
          <Dropdown>
            <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
              {COMMUTING_ONCE_OPTIONS[commutingOnce]}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.keys(COMMUTING_ONCE_OPTIONS).map((key, _) => (
                <Dropdown.Item
                  key={key}
                  onClick={() => setCommutingOnce(Number(key))}
                >
                  {COMMUTING_ONCE_OPTIONS[Number(key)]}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div
        id="advancedMedical"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>先進医療</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {BOOL_OPTIONS[advancedMedical ? 1 : 0]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(BOOL_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() => setAdvancedMedical(Number(key) ? true : false)}
              >
                {BOOL_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="cancerSpecialContractAmount"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>がん特約</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {BOOL_OPTIONS[cancerSpecialContractAmount]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(BOOL_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() => setCancerSpecialContractAmount(Number(key))}
              >
                {BOOL_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="specifiedDisease"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>特定疾病</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {BOOL_OPTIONS[specifiedDisease ? 1 : 0]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(BOOL_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() =>
                  setSpecifiedDiseaseInsurance(Number(key) ? true : false)
                }
              >
                {BOOL_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="deathSpecialContractAmount"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>死亡特約</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {BOOL_OPTIONS[deathSpecialContractAmount]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(BOOL_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() => setDeathSpecialContractAmount(Number(key))}
              >
                {BOOL_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="congratulatoryMoney"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>
          健康ボーナス
          <br />
          （お祝い金）
        </span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {BOOL_OPTIONS[congratulatoryMoney ? 1 : 0]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(BOOL_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() =>
                  setCongratulatoryMoney(Number(key) ? true : false)
                }
              >
                {BOOL_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="paymentExemption"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>保険料払込免除</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {paymentExemption ? "あり" : "指定なし"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setPaymentExemption(false);
              }}
            >
              指定なし
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setPaymentExemption(true);
              }}
            >
              あり
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="attention"
        className="listHead mh50"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>備考</span>
      </div>
      <div
        id="condition"
        className="listHead mh50"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>その他（算出条件）</span>
      </div>
      <div
        id="approvalNumber"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>承認番号</span>
      </div>
    </>
  );
};
