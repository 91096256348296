import React from "react";

interface Props {
  label: string;
  required?: boolean;
  options: string[];
  value?: string | string[]; // Adjust the type here
  onChange?: (value: string | string[]) => void; // Adjust the type here
  selectKey?: string;
}

const MultiSelect = ({
  label,
  required,
  options,
  value,
  onChange,
  selectKey,
}: Props) => {
  const validate = React.useMemo(() => {
    if (required && (!value || (Array.isArray(value) && value.length === 0))) {
      return `${label}を選択してください`;
    }
    return "";
  }, [value, label, required]);

  const onChangeRadio = (option: string) => {
    if (onChange) {
      if (Array.isArray(value)) {
        const updatedValue = value.includes(option)
          ? value.filter((v) => v !== option)
          : [...value, option];
        onChange(updatedValue);
      } else {
        onChange([option]);
      }
    }
  };

  return (
    <div className={`flexColumn multi-select ${validate !== "" && "error"}`}>
      <span className="typographyAnswerBtnDescription">
        {label}<br />
        （いずれか１つ）
      </span>
      {options.map((option, index) => (
        <label
          htmlFor={`${selectKey}_${index}`}
          key={`${selectKey}_${index}`}
          className="checkboxBtn flex"
        >
          <input
            type="radio"
            id={`${selectKey}_${index}`}
            name={selectKey}
            value={option}
            checked={
              Array.isArray(value)
                ? value.includes(option)
                : value === option
            }
            onChange={() => onChangeRadio(option)}
          />
          <p>{option}</p>
        </label>
      ))}
      {validate !== "" && <span className="validation">{validate}</span>}
    </div>
  );
};

export default MultiSelect;
