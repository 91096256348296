/**  @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  GetApiMedicalInsuranceRank200Response,
  Insurance,
} from "../../../../openApi/api";
import { ContentsColumnMedical } from "./ContentsColumnMedical";
import { HeadColumnCommon } from "./HeadColumnCommon";
import { HeadColumnMedical } from "./HeadColumnMedical";

interface Props {
  typeOfInsurance: number;
  response: GetApiMedicalInsuranceRank200Response | undefined;
  age: number;
  sex: number;
  hospitalizationGuarantee: number;
  setHospitalizationGuarantee: (hospitalizationGuarantee: number) => void;
  paymentExemption: boolean;
  setPaymentExemption: (paymentExemption: boolean) => void;
  setIsPopupOpen: (isPopupOpen: boolean) => void;
  setFocusedIndex: (focusedIndex: number) => void;
  insuranceListInCart: Insurance[];
  setInsuranceListInCart: (prevCart: Insurance[]) => void;
  insuranceIdListInCart: string[];
  setInsuranceIdListInCart: (insuranceIdListInCart: string[]) => void;

  //医療保険特有のprops
  hospitalizationGuaranteeDays: number;
  setHospitalizationGuaranteeDays: (
    hospitalizationGuaranteeDays: number
  ) => void;
  hospitalizationOnce: number;
  setHospitalizationOnce: (hospitalizationOnce: number) => void;
  surgeryGuarantee: boolean;
  setSurgeryGuarantee: (surgeryGuarantee: boolean) => void;
  commutingDailyAmount: number;
  setCommutingDailyAmount: (commutingDailyAmount: number) => void;
  commutingDailyAmountDays: number;
  setCommutingDailyAmountDays: (commutingDailyAmountDays: number) => void;
  commutingOnce: number;
  setCommutingOnce: (commutingOnce: number) => void;
  advancedMedical: boolean;
  setAdvancedMedical: (advancedMedical: boolean) => void;
  cancerSpecialContractAmount: number;
  setCancerSpecialContractAmount: (cancerSpecialContractAmount: number) => void;
  specifiedDisease: boolean;
  setSpecifiedDiseaseInsurance: (specifiedDisease: boolean) => void;
  deathSpecialContractAmount: number;
  setDeathSpecialContractAmount: (deathSpecialContractAmount: number) => void;
  congratulatoryMoney: boolean;
  setCongratulatoryMoney: (congratulatoryMoney: boolean) => void;
  triggerMedicalInsuranceRankAPI: boolean;
  setTriggerMedicalInsuranceRankAPI: (_: boolean) => void;
  
  checkedRankList: string[];
  setCheckedRankList: (checkedRankList: string[]) => void;
  
  isContentsColumnRendered: boolean;
  setIsContentsColumnRendered: (isContentsColumnRendered: boolean) => void;
}

export const TableMedical = ({
  typeOfInsurance,
  response,
  age,
  sex,
  hospitalizationGuarantee,
  setHospitalizationGuarantee,
  paymentExemption,
  setPaymentExemption,
  setIsPopupOpen,
  setFocusedIndex,
  insuranceListInCart,
  setInsuranceListInCart,
  insuranceIdListInCart,
  setInsuranceIdListInCart,
  hospitalizationGuaranteeDays,
  setHospitalizationGuaranteeDays,
  hospitalizationOnce,
  setHospitalizationOnce,
  surgeryGuarantee,
  setSurgeryGuarantee,
  commutingDailyAmount,
  setCommutingDailyAmount,
  commutingDailyAmountDays,
  setCommutingDailyAmountDays,
  commutingOnce,
  setCommutingOnce,
  advancedMedical,
  setAdvancedMedical,
  cancerSpecialContractAmount,
  setCancerSpecialContractAmount,
  specifiedDisease,
  setSpecifiedDiseaseInsurance,
  deathSpecialContractAmount,
  setDeathSpecialContractAmount,
  congratulatoryMoney,
  setCongratulatoryMoney,
  triggerMedicalInsuranceRankAPI,
  setTriggerMedicalInsuranceRankAPI,
  
  checkedRankList,
  setCheckedRankList,
  
  isContentsColumnRendered,
  setIsContentsColumnRendered,
}: Props) => {
  
  const [isDisplayOnlyChecked, setIsDisplayOnlyChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsContentsColumnRendered(true);
  }, [
    isDisplayOnlyChecked,
    setIsDisplayOnlyChecked,
    setIsContentsColumnRendered
  ]);
  
  return (
    <section className="comparisonTableSection">
      <Container>
        <Row>
          <Col className="speechBoxContainer" md={1}>
            <div className="speechBox">
              保障内容でも
              <br />
              絞り込み可能
            </div>
          </Col>
          <Col md={1} className="insuranceTableTitle">
            {response?.table_header_text}
          </Col>
        </Row>
      </Container>
      <div className="insuranceTable">
        <div className="headRow">
          <HeadColumnCommon
            isDisplayOnlyChecked={isDisplayOnlyChecked}
            setIsDisplayOnlyChecked={setIsDisplayOnlyChecked}
            isContentsColumnRendered={isContentsColumnRendered}
            setIsContentsColumnRendered={setIsContentsColumnRendered}
          />
          <div className="headRowSpecific">
            <HeadColumnMedical
              hospitalizationGuarantee={hospitalizationGuarantee}
              setHospitalizationGuarantee={setHospitalizationGuarantee}
              paymentExemption={paymentExemption}
              setPaymentExemption={setPaymentExemption}
              hospitalizationGuaranteeDays={hospitalizationGuaranteeDays ?? 0}
              setHospitalizationGuaranteeDays={setHospitalizationGuaranteeDays}
              hospitalizationOnce={hospitalizationOnce}
              setHospitalizationOnce={setHospitalizationOnce}
              surgeryGuarantee={surgeryGuarantee}
              setSurgeryGuarantee={setSurgeryGuarantee}
              commutingDailyAmount={commutingDailyAmount}
              setCommutingDailyAmount={setCommutingDailyAmount}
              commutingDailyAmountDays={commutingDailyAmountDays}
              setCommutingDailyAmountDays={setCommutingDailyAmountDays}
              commutingOnce={commutingOnce}
              setCommutingOnce={setCommutingOnce}
              advancedMedical={advancedMedical}
              setAdvancedMedical={setAdvancedMedical}
              cancerSpecialContractAmount={cancerSpecialContractAmount}
              setCancerSpecialContractAmount={setCancerSpecialContractAmount}
              specifiedDisease={specifiedDisease}
              setSpecifiedDiseaseInsurance={setSpecifiedDiseaseInsurance}
              deathSpecialContractAmount={deathSpecialContractAmount}
              setDeathSpecialContractAmount={setDeathSpecialContractAmount}
              congratulatoryMoney={congratulatoryMoney}
              setCongratulatoryMoney={setCongratulatoryMoney}
              triggerMedicalInsuranceRankAPI={triggerMedicalInsuranceRankAPI}
              setTriggerMedicalInsuranceRankAPI={
                setTriggerMedicalInsuranceRankAPI
              }
              isContentsColumnRendered={isContentsColumnRendered}
              setIsContentsColumnRendered={setIsContentsColumnRendered}
            />
          </div>
        </div>
        <div className="contentRow">
          {
            // TODO: 以下のmapの型定義はopenApiの不具合修正後に削除が必要
            response?.insurances &&
              response?.insurances.map((insurance: Insurance, index: number) =>
                !isDisplayOnlyChecked ||
                (isDisplayOnlyChecked &&
                  checkedRankList.includes(insurance.insurance_id ?? '')) ? (
                  <ContentsColumnMedical
                    key={`${typeOfInsurance}_${index + 1}`}
                    typeOfInsurance={typeOfInsurance}
                    insurance={insurance}
                    age={age}
                    sex={sex}
                    hospitalizationGuarantee={hospitalizationGuarantee}
                    lowPriceRank={index + 1}
                    setIsPopupOpen={setIsPopupOpen}
                    setFocusedIndex={setFocusedIndex}
                    insuranceListInCart={insuranceListInCart}
                    setInsuranceListInCart={setInsuranceListInCart}
                    insuranceIdListInCart={insuranceIdListInCart}
                    setInsuranceIdListInCart={setInsuranceIdListInCart}
                    hospitalizationGuaranteeDays={hospitalizationGuaranteeDays}
                    hospitalizationOnce={hospitalizationOnce}
                    surgeryGuarantee={surgeryGuarantee}
                    commutingDailyAmount={commutingDailyAmount}
                    commutingDailyAmountDays={commutingDailyAmountDays}
                    commutingOnce={commutingOnce}
                    advancedMedical={advancedMedical}
                    cancerSpecialContractAmount={cancerSpecialContractAmount}
                    specifiedDisease={specifiedDisease}
                    deathSpecialContractAmount={deathSpecialContractAmount}
                    congratulatoryMoney={congratulatoryMoney}
                    paymentExemption={paymentExemption}
                    checkedRankList={checkedRankList}
                    setCheckedRankList={setCheckedRankList}
                    setIsContentsColumnRendered={setIsContentsColumnRendered}
                    numberOfInsurances={response?.insurances?.length}
                  />
                ) : (
                  <div key={index + 1}></div>
                )
              )
          }
        </div>
      </div>
    </section>
  );
};
