import { PropsWithChildren } from "react";

const PageHeader: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <h2
      className="typographyPrimaryColor typographyTextSubTitle typographyAlignCenter"
      style={{
        padding: "40px 0",
      }}
    >
      {children}
    </h2>
  );
};

export default PageHeader;
