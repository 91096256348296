import { useQuery } from "@tanstack/react-query";
import { Api } from "../openApi";
import { GetApiInsurancesInsuranceCode200Response } from "../openApi/api";

export const useMedicalInsuranceDetail = (
  insuranceCode: string,
  setResponse: (response: GetApiInsurancesInsuranceCode200Response) => void
) => {
  return useQuery(
    ["getMedicalInsuranceDetail"],
    async () => {
      const { data } = await Api.getApiInsurancesInsuranceCode(insuranceCode);
      setResponse(data);
      return data;
    },
    {
      enabled: false,
    }
  );
};
