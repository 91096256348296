/** @jsxImportSource @emotion/react */
import { useCallback, useState, useMemo, useEffect } from "react";
import ProgressBar from "../../components/atoms/ProgressBar";
import Loading from "../../components/molecules/Loading";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { usePreventMultipleCalls } from "../../hooks/usePreventMultipleCalls";
import { useDetailDiagnose } from "../../query/useDetailDiagnose";
import "../../styles/pages/detail.scss";
import {
  detailDiagnoseStorage,
  simpleDiagnoseStorage,
} from "../../utils/storage";
import CalcContainer, { ButtonType } from "./components/CalcContainer";
import { Question1 } from "./components/Question1";
import { Question2 } from "./components/Question2";
import { Question3 } from "./components/Question3";
import { Question4 } from "./components/Question4";
import { Question5 } from "./components/Question5";
import { Question6 } from "./components/Question6";
import { Question7 } from "./components/Question7";
import { Question8 } from "./components/Question8";

const Detail = () => {
  useDocumentTitle("保デジWEB-詳しく診断");
  const totalStep = 9;
  const simpleDiagnose = simpleDiagnoseStorage.get();
  const [currentStep, setCurrentStep] = useState(0);

  const [hasSmoked, setHasSmoked] = useState<boolean>();
  const [isAskedHealthCheck, setIsAskedHealthCheck] = useState<boolean>();
  const [hasHouse, setHasHouse] = useState<boolean>();
  const [rent, setRent] = useState<number>(0);
  const [partnerYearlyIncome, setPartnerYearlyIncome] = useState<number | undefined>(0);
  const [currentInsuranceCompanyId, setCurrentInsuranceCompanyId] =
    useState<number>();
  const [currentInsuranceMonthTerm, setCurrentInsuranceMonthTerm] =
    useState<number | undefined>();
  const [currentInsuranceCategory, setCurrentInsuranceCategory] = useState<
    string[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { request: prevDiagnoseRequest } = useMemo(
    () => detailDiagnoseStorage.get(),
    []
  );

  useEffect(() => {
    if (prevDiagnoseRequest) {
      setHasSmoked(prevDiagnoseRequest.has_smoked);
      setHasHouse(prevDiagnoseRequest.has_house);
      setIsAskedHealthCheck(prevDiagnoseRequest.is_asked_health_check);
      setRent(prevDiagnoseRequest.rent);
      setPartnerYearlyIncome(prevDiagnoseRequest.partner_yearly_income);
      setCurrentInsuranceCompanyId(
        prevDiagnoseRequest.current_insurance_company_id
      );
      setCurrentInsuranceMonthTerm(
        prevDiagnoseRequest.current_insurance_month_term
      );
      setCurrentInsuranceCategory(
        prevDiagnoseRequest.current_insurance_category
      );
      setCurrentStep(totalStep - 1);

      setTimeout(() => {
        const element = document.getElementById("section-end");
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }, 1000);
    }
  }, [prevDiagnoseRequest]);

  const onSetHasSmoked = (value: boolean) => {
    setHasSmoked(value);
    if (currentStep === 1) {
      onNextStep();
    }
  };

  const onSetIsAskedHealthCheck = (value: boolean) => {
    setIsAskedHealthCheck(value);
    if (currentStep === 2) {
      onNextStep();
    }
  };

  const onSetHasHouse = (value: boolean) => {
    setHasHouse(value);
    if (currentStep === 3) {
      onNextStep();
    }
  };

  const onSetRent = (value: number) => {
    setRent(value);
    if (currentStep === 4) {
      onNextStep();
    }
  };

  const onSetPartnerYearlyIncome = (value: number | undefined) => {
    setPartnerYearlyIncome(value);
    if (currentStep === 5) {
      onNextStep();
    }
  };

  const onSetCurrentInsuranceCompanyId = (value: number) => {
    setCurrentInsuranceCompanyId(value);
  };

  const onSetCurrentInsuranceMonthTerm = (value: number | undefined) => {
    setCurrentInsuranceMonthTerm(value);
    if (currentStep === 7) {
      onNextStep();
    }
  };

  const { mutate: _postDetailDiagnose } = useDetailDiagnose();

  const postDetailDiagnose = usePreventMultipleCalls(
    async (...params: Parameters<typeof _postDetailDiagnose>) => {
      await _postDetailDiagnose(...params);
    }
  );

  const scrollInto = (elementName: string) => {
    const element = document.getElementById(elementName);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const onComplete = useCallback(() => {
    if (rent === undefined || rent > 100000) {
      scrollInto("question-4");
      return;
    }

    if (partnerYearlyIncome && partnerYearlyIncome > 100000) {
      scrollInto("question-5");
      return;
    }

    if (currentInsuranceMonthTerm && currentInsuranceMonthTerm > 1000) {
      scrollInto("question-7");
      return;
    }

    setCurrentStep(totalStep);
    setIsLoading(true);
    setTimeout(() => {
      postDetailDiagnose({
        diagnos_code: simpleDiagnose.result.diagnos_code!,
        has_smoked: hasSmoked!,
        is_asked_health_check: isAskedHealthCheck!,
        has_house: hasHouse!,
        rent: rent!,
        partner_yearly_income: partnerYearlyIncome,
        current_insurance_company_id: currentInsuranceCompanyId ?? 0,
        current_insurance_month_term: currentInsuranceMonthTerm!,
        current_insurance_category: currentInsuranceCategory,
      });
    }, 1000);
  }, [
    simpleDiagnose,
    hasSmoked,
    isAskedHealthCheck,
    hasHouse,
    rent,
    partnerYearlyIncome,
    currentInsuranceCompanyId,
    currentInsuranceMonthTerm,
    currentInsuranceCategory,
    postDetailDiagnose,
  ]);

  const onNextStep = useCallback(() => {
    if (currentStep === 6 && currentInsuranceCompanyId === 0) {
      return;
    }

    if (currentStep === 4 && simpleDiagnose.request.is_married === false) {
      setCurrentStep(currentStep + 2);
    } else {
      setCurrentStep(currentStep + 1);
    }

    setTimeout(() => {
      const element = document.getElementById("section-end");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }, 1000);
  }, [currentStep, currentInsuranceCompanyId, simpleDiagnose]);

  const onPrevStep = (currentQuestion: number) => {
    const element = document.getElementById(`question-${currentQuestion}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const onCompleteStatus = useMemo(
    () =>
      (currentStep === 6 && currentInsuranceCompanyId === 0) ||
      currentStep === 8,
    [currentStep, currentInsuranceCompanyId]
  );

  useEffect(() => {
    if (
      currentStep === 6 &&
      currentInsuranceCompanyId &&
      currentInsuranceCompanyId !== 0
    ) {
      onNextStep();
    }
  }, [currentStep, currentInsuranceCompanyId, onNextStep]);

  return (
    <>
      {isLoading && <Loading title={"詳しく診断の結果を算出しています..."} />}
      <ProgressBar percentage={(currentStep * 100) / totalStep} />
      <div className="simpleAssessmentForm">
        <CalcContainer
          btnType={currentStep === 0 ? ButtonType.ARROW : undefined}
          onClick={onNextStep}
        >
          <h1>さらに正確に診断します</h1>
          <h2>必要保障額シミュレーション</h2>
          <p>より詳しい質問へ回答して、より正確な結果をみてみましょう！</p>
        </CalcContainer>
        {currentStep >= 1 && (
          <Question1
            current={currentStep}
            hasSmoked={hasSmoked}
            setHasSmoked={onSetHasSmoked}
            onNext={onNextStep}
          />
        )}
        {currentStep >= 2 && (
          <Question2
            current={currentStep}
            isAskedHealthCheck={isAskedHealthCheck}
            setIsAskedHealthCheck={onSetIsAskedHealthCheck}
            onPrev={onPrevStep}
          />
        )}
        {currentStep >= 3 && (
          <Question3
            current={currentStep}
            hasHouse={hasHouse}
            setHasHouse={onSetHasHouse}
            onPrev={onPrevStep}
          />
        )}
        {currentStep >= 4 && (
          <Question4
            hasHouse={hasHouse!}
            current={currentStep}
            rent={rent}
            setRent={onSetRent}
            onPrev={onPrevStep}
          />
        )}
        {currentStep >= 5 && simpleDiagnose.request.is_married === true && (
          <Question5
            current={currentStep}
            partnerYearlyIncome={partnerYearlyIncome}
            setPartnerYearlyIncome={onSetPartnerYearlyIncome}
            onPrev={onPrevStep}
          />
        )}
        {currentStep >= 6 && (
          <Question6
            current={currentStep}
            currentInsuranceCompanyId={currentInsuranceCompanyId}
            setCurrentInsuranceCompanyId={onSetCurrentInsuranceCompanyId}
            onPrev={onPrevStep}
          />
        )}
        {(currentStep >= 7 &&
          currentInsuranceCompanyId &&
          currentInsuranceCompanyId !== 0) ? (
            <Question7
              current={currentStep}
              currentInsuranceMonthTerm={currentInsuranceMonthTerm}
              setCurrentInsuranceMonthTerm={onSetCurrentInsuranceMonthTerm}
              onPrev={onPrevStep}
            />
          ) : ''}
        {(currentStep >= 8 &&
          currentInsuranceCompanyId &&
          currentInsuranceCompanyId !== 0) ? (
            <Question8
              current={currentStep}
              setCurrentInsuranceCategory={setCurrentInsuranceCategory}
              currentInsuranceCategory={currentInsuranceCategory}
              onPrev={onPrevStep}
              onComplete={onComplete}
            />
          ) : ''}
        {onCompleteStatus && (
          <div className="textBtn complete-ctrl mt-2 mb-5">
            <button onClick={onComplete}>診断結果へ</button>
          </div>
        )}
        <div id="section-end" />
      </div>
    </>
  );
};
export default Detail;
