interface Props {
  current: number;
  whoAssured: number;
  setWhoAssured: (assured: number) => void;
}

const Question1 = ({ current, whoAssured, setWhoAssured }: Props) => {
  return (
    <section
      className={`cardContainer fade-in-slide-up ${
        current === 1 ? "currentCard" : ""
      }`}
      id="question-1"
    >
      <div className="cardWrapper question1">
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            1&nbsp;/&nbsp;10
          </span>
          <h2 className="typographyPrimaryColor typographyTextSubTitle">
            診断したい方はどなたですか
          </h2>
          <span>ご自身から見た診断したい方の立場を教えてください</span>
        </div>
        <fieldset>
          <label>
            <input
              type="radio"
              value="me"
              aria-checked={whoAssured === 1 ? "true" : "false"}
              onClick={() => setWhoAssured(1)}
            />
            <div>
              <span>わたし</span>
              <span>ご自身を診断したい</span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              value="husband"
              aria-checked={whoAssured === 2 ? "true" : "false"}
              onClick={() => setWhoAssured(2)}
            />
            <div>
              <img src="/img/male.svg" alt="" />
              <span>夫</span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              value="wife"
              aria-checked={whoAssured === 3 ? "true" : "false"}
              onClick={() => setWhoAssured(3)}
            />
            <div>
              <img src="/img/female.svg" alt="" />
              <span>妻</span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              value="other"
              aria-checked={whoAssured === 4 ? "true" : "false"}
              onClick={() => setWhoAssured(4)}
            />
            <div>
              <span>そのほか</span>
            </div>
          </label>
        </fieldset>
      </div>
    </section>
  );
};

export default Question1;
