/**  @jsxImportSource @emotion/react */

import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer>
      <div className="footerContents">
        <p>募集代理店</p>
        <p>株式会社フィナンシャル・エージェンシー</p>
        <p>〒150-0013 東京都渋谷区恵比寿1-19-19　恵比寿ビジネスタワー16F</p>
        <p>
          <Link
            to="https://www.financial-agency.com/company/about/"
            target="_blank"
          >
            会社概要
          </Link>
          {"   "}
          <Link
            to="https://www.financial-agency.com/compliance/webhodegi-service/"
            target="_blank"
          >
            利用規約
          </Link>
          {"   "}
          <Link
            to="https://www.financial-agency.com/compliance/pesonalinfo/"
            target="_blank"
          >
            プライバシーポリシー
          </Link>
          {"   "}
          <Link to="https://form.run/@webhodegi-contact" target="_blank">
            お問い合わせ
          </Link>
          {"   "}
          <Link to="/doc/SalesPolicy.pdf" rel="noopener noreferrer" target="_blank">販売方針</Link>
          {"   "}
          <Link
            to="https://www.financial-agency.com/compliance/solicitation/"
            target="_blank"
          >
            勧誘方針
          </Link>
          {"   "}
          <Link
            to="https://www.financial-agency.com/compliance/comparison/"
            target="_blank"
          >
            比較表示ポリシー
          </Link>
        </p>
      </div>
    </footer>
  );
};
