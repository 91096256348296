/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const downloadDocumentForm = css`
	display: flex;
	flex-flow: column;
	padding: 40px;
	font-size: 18px;
	border-radius: 8px;
	background: #fff;
	label{
		margin-bottom: 15px;
		input,select{
			border-color: #5e5e5e;
			font-size: 18px;
			padding: 20px 10px;
			border-radius: 8px;
			&::placeholder{
				text-align: center;
			}
		}
	}
`
export const policyModal = css`
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	padding: 100px;
	display: flex;
	justify-content: center;
	background: #00000080;
	position: fixed;
	top: 0;
`