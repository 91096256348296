interface Props {
  current: number;
  hasSmoked: boolean | undefined;
  setHasSmoked: (hasSmoked: boolean) => void;
  onNext: () => void;
}

export const Question1 = ({
  current,
  hasSmoked,
  setHasSmoked,
  onNext,
}: Props) => {
  return (
    <section
      className={`cardContainer fade-in-slide-up ${
        current === 1 ? "currentCard" : ""
      }`}
      id="question-1"
    >
      <div className="cardWrapper question1">
        <div className="titleImage">
          <img src="/img/tabacco.svg" alt="" />
        </div>
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            1&nbsp;/&nbsp;8
          </span>
          <h2 className="typographyAccentOrange typographyNumTextProgress">
            あなたは、1年以内に喫煙しましたか
          </h2>
          <span>診断したい方について教えてください</span>
        </div>
        <fieldset>
          <label>
            <input
              type="radio"
              value="true"
              aria-checked={hasSmoked === true}
              onClick={() => setHasSmoked(true)}
            />
            <div>
              <span>はい</span>
              <span>喫煙した</span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              value="false"
              aria-checked={hasSmoked === false}
              onClick={() => setHasSmoked(false)}
            />
            <div>
              <span>いいえ</span>
              <span>喫煙していない</span>
            </div>
          </label>
          <div className="typographyAlignCenter grid2ColumnFull mt-16">
            わからない場合は
            <span className="hasSmokedLink" onClick={onNext}>
              質問をスキップ
            </span>
          </div>
        </fieldset>
      </div>
    </section>
  );
};
