import {
  ApiDetailDiagnoseRequest,
  ApiSimpleDiagnoseRequest,
  PostApiSimpleDiagnose200Response,
} from "../openApi/api";
import { CancerInsuranceDetailProps } from "../pages/comparison/cancerInsuranceDetail";
import { InsuranceRankResponseInner } from "../pages/comparison/comparison";
import { CartStorage } from "../pages/comparison/components/EstimateResult";
import { SearchConditionProps } from "../pages/comparison/components/SearchCondition";
import { CommonPDProps } from "../pages/comparison/components/comparisonTable/ContentsColumnCommon";
import { IncomeProtectionInsuranceDetailProps } from "../pages/comparison/incomeProtectionInsuranceDetail";
import { MedicalInsuranceDetailProps } from "../pages/comparison/medicalInsuranceDetail";
import { DeathInsuranceDetailProps } from "../pages/comparison/wholeDeathInsuranceDetail";
import {
  DEFAULT_CANCER_PD_PARAM,
  DEFAULT_COMMON_PD_PARAM,
  DEFAULT_DEATH_PD_PARAM,
  DEFAULT_INCOME_PROTECTION_PD_PARAM,
  DEFAULT_MEDICAL_PD_PARAM,
  DEFAULT_SEARCH_CONDITION,
} from "./storageDefaultValue";

const LOCAL = "localStorage";
const SESSION = "sessionStorage";
const COOKIE = "cookieStorage";

type StorageType = typeof LOCAL | typeof SESSION | typeof COOKIE;

type GetItemParams = {
  key: string;
  storage?: StorageType;
  empty?: any;
};

type SetItemParams = {
  key: string;
  value: any;
  storage?: StorageType;
};

type RemoveItemParams = {
  key: string;
  storage?: StorageType;
};

export type SimpleDiagnose = {
  result: PostApiSimpleDiagnose200Response;
  request: ApiSimpleDiagnoseRequest;
};

export type DetailDiagnose = {
  result: PostApiSimpleDiagnose200Response;
  request: ApiDetailDiagnoseRequest;
};
export type SearchCondition = {
  result: SearchConditionProps;
};

export type MedicalInsuranceDetail = {
  result: MedicalInsuranceDetailProps;
};
export type CancerInsuranceDetail = {
  result: CancerInsuranceDetailProps;
};
export type DeathInsuranceDetail = {
  result: DeathInsuranceDetailProps;
};
export type IncomeProtectionInsuranceDetail = {
  result: IncomeProtectionInsuranceDetailProps;
};

export type PartnerData = {
  partnerCode: string | undefined;
  inflowCode: string | undefined;
  mainColor: string | undefined;
  subColor1: string | undefined;
  subColor2: string | undefined;
  subColor3: string | undefined;
  fontColor: string | undefined;
  accentColor: string | undefined;
  backgroundColor: string | undefined;
  mainButtonColor: string | undefined;
  subButtonColor1: string | undefined;
  subButtonColor2: string | undefined;
  subButtonColor3: string | undefined;

  saveButtonDisp: boolean | undefined;
  fpButtonDisp: boolean | undefined;
  comparisonButtonDisp: boolean | undefined;
}

export type SimpleDiagnoseKey = "simpleDiagnose";
export type DetailDiagnoseKey = "detailDiagnose";
export type SearchConditionKey = "searchCondition";
export type InsuranceDetailKey = "insuranceDetail";
export type CommonPDKey = "commonPD";
export type FocusedInsuranceKey = "focusedInsurance";
export type MedicalInsuranceDetailKey = "medicalInsuranceDetail";
export type CancerInsuranceDetailKey = "cancerInsuranceDetail";
export type DeathInsuranceDetailKey = "deathInsuranceDetail";
export type WholeDeathInsuranceDetailKey = "wholeDeathInsuranceDetail";
export type IncomeProtectionInsuranceDetailKey =
  "incomeProtectionInsuranceDetail";
export type CartKey = "cart";
export type PartnerDataKey = "partner";

export const getItem = ({
  key,
  storage = LOCAL,
  empty = null,
}: GetItemParams): typeof empty | unknown => {
  const item = window[storage as keyof typeof window].getItem(key);
  if (typeof item === "undefined" || item === null) return empty;
  return JSON.parse(item);
};

export const setItem = ({ key, value, storage = LOCAL }: SetItemParams) => {
  window[storage as keyof typeof window].setItem(key, JSON.stringify(value));
};

export const removeItem = ({ key, storage = LOCAL }: RemoveItemParams) => {
  window[storage as keyof typeof window].removeItem(key);
};

export const removeInsuranceFromCartById = (
  { key, storage = LOCAL }: RemoveItemParams,
  id: string
) => {
  const items = JSON.parse(window[storage as keyof typeof window].getItem(key));
  items.insuranceIdList = items.insuranceIdList.filter(
    (insuranceId: string) => insuranceId !== id
  );
  items.insuranceList = items.insuranceList.filter(
    (insurance: InsuranceRankResponseInner) => insurance.insurance_id !== id
  );
  window[storage as keyof typeof window].setItem(key, JSON.stringify(items));
};

const BASE_SIMPLE_DIAGNOSE_PARAM: {
  key: SimpleDiagnoseKey;
  storage: StorageType;
  empty: unknown;
} = {
  key: "simpleDiagnose",
  storage: LOCAL,
  empty: {},
};

export const simpleDiagnoseStorage = {
  get: () => getItem(BASE_SIMPLE_DIAGNOSE_PARAM) as SimpleDiagnose,
  set: (value: any) => setItem({ ...BASE_SIMPLE_DIAGNOSE_PARAM, value }),
  remove: () => removeItem({ ...BASE_SIMPLE_DIAGNOSE_PARAM }),
};

const BASE_DETAIL_DIAGNOSE_PARAM: {
  key: DetailDiagnoseKey;
  storage: StorageType;
  empty: unknown;
} = {
  key: "detailDiagnose",
  storage: LOCAL,
  empty: {},
};

export const detailDiagnoseStorage = {
  get: () => getItem(BASE_DETAIL_DIAGNOSE_PARAM) as DetailDiagnose,
  set: (value: any) => setItem({ ...BASE_DETAIL_DIAGNOSE_PARAM, value }),
  remove: () => removeItem({ ...BASE_DETAIL_DIAGNOSE_PARAM }),
};

const BASE_SEARCH_CONDITION_PARAM: {
  key: SearchConditionKey;
  storage: StorageType;
} = {
  key: "searchCondition",
  storage: LOCAL,
};

export const searchConditionStorage = {
  get: () => {
    const searchCondition = getItem(
      BASE_SEARCH_CONDITION_PARAM
    ) as SearchConditionProps;
    return searchCondition || DEFAULT_SEARCH_CONDITION;
  },
  set: (value: any) => setItem({ ...BASE_SEARCH_CONDITION_PARAM, value }),
  remove: () => removeItem({ ...BASE_SEARCH_CONDITION_PARAM }),
};

const COMMON_PD_PARAM: {
  key: CommonPDKey;
  storage: StorageType;
} = {
  key: "commonPD",
  storage: LOCAL,
};
export const commonPDStorage = {
  get: () => {
    const commonPD = getItem(COMMON_PD_PARAM) as CommonPDProps;
    return commonPD || DEFAULT_COMMON_PD_PARAM;
  },
  set: (value: any) => setItem({ ...COMMON_PD_PARAM, value }),
  remove: () => removeItem({ ...COMMON_PD_PARAM }),
};

const FOCUSED_INSURANCE: {
  key: FocusedInsuranceKey;
  storage: StorageType;
} = {
  key: "focusedInsurance",
  storage: LOCAL,
};
export const focusedInsuranceStorage = {
  get: () => {
    const focusedInsurance = getItem(
      FOCUSED_INSURANCE
    ) as InsuranceRankResponseInner;
    return focusedInsurance;
  },
  set: (value: any) => setItem({ ...FOCUSED_INSURANCE, value }),
  remove: () => removeItem({ ...FOCUSED_INSURANCE }),
};

const MEDICAL_PD_PARAM: {
  key: MedicalInsuranceDetailKey;
  storage: StorageType;
} = {
  key: "medicalInsuranceDetail",
  storage: LOCAL,
};
export const medicalPDStorage = {
  get: () => {
    const medicalPD = getItem(MEDICAL_PD_PARAM) as MedicalInsuranceDetailProps;
    return medicalPD || DEFAULT_MEDICAL_PD_PARAM;
  },
  set: (value: any) => setItem({ ...MEDICAL_PD_PARAM, value }),
  remove: () => removeItem({ ...MEDICAL_PD_PARAM }),
};

const CANCER_PD_PARAM: {
  key: CancerInsuranceDetailKey;
  storage: StorageType;
} = {
  key: "cancerInsuranceDetail",
  storage: LOCAL,
};
export const cancerPDStorage = {
  get: () => {
    const cancerPD = getItem(CANCER_PD_PARAM) as CancerInsuranceDetailProps;
    return cancerPD || DEFAULT_CANCER_PD_PARAM;
  },
  set: (value: any) => setItem({ ...CANCER_PD_PARAM, value }),
  remove: () => removeItem({ ...CANCER_PD_PARAM }),
};

const DEATH_PD_PARAM: {
  key: DeathInsuranceDetailKey;
  storage: StorageType;
} = {
  key: "deathInsuranceDetail",
  storage: LOCAL,
};
export const deathPDStorage = {
  get: () => {
    const deathPD = getItem(DEATH_PD_PARAM) as DeathInsuranceDetailProps;
    return deathPD || DEFAULT_DEATH_PD_PARAM;
  },
  set: (value: any) => setItem({ ...DEATH_PD_PARAM, value }),
  remove: () => removeItem({ ...DEATH_PD_PARAM }),
};

const WHOLE_DEATH_PD_PARAM: {
  key: WholeDeathInsuranceDetailKey;
  storage: StorageType;
} = {
  key: "wholeDeathInsuranceDetail",
  storage: LOCAL,
};
export const wholeDeathPDStorage = {
  get: () => {
    const deathPD = getItem(WHOLE_DEATH_PD_PARAM) as DeathInsuranceDetailProps;
    return deathPD || DEFAULT_DEATH_PD_PARAM;
  },
  set: (value: any) => setItem({ ...DEATH_PD_PARAM, value }),
  remove: () => removeItem({ ...DEATH_PD_PARAM }),
};

const INCOME_PROTECTION_PD_PARAM: {
  key: IncomeProtectionInsuranceDetailKey;
  storage: StorageType;
} = {
  key: "incomeProtectionInsuranceDetail",
  storage: LOCAL,
};
export const incomeProtectionPDStorage = {
  get: () => {
    const incomeProtectionPD = getItem(
      INCOME_PROTECTION_PD_PARAM
    ) as IncomeProtectionInsuranceDetailProps;
    return incomeProtectionPD || DEFAULT_INCOME_PROTECTION_PD_PARAM;
  },
  set: (value: any) => setItem({ ...INCOME_PROTECTION_PD_PARAM, value }),
  remove: () => removeItem({ ...INCOME_PROTECTION_PD_PARAM }),
};

const CART_PARAM: {
  key: CartKey;
  storage: StorageType;
} = {
  key: "cart",
  storage: LOCAL,
};

export const cartStorage = {
  get: () => getItem(CART_PARAM) as CartStorage,
  set: (value: any) => setItem({ ...CART_PARAM, value }),
  remove: (id: string) => removeInsuranceFromCartById({ ...CART_PARAM }, id),
  removeAll: () => removeItem({ ...CART_PARAM }),
};

const PARTNER_DATA_PARAM: {
  key: PartnerDataKey;
  storage: StorageType;
} = {
  key: "partner",
  storage: SESSION,
};
export const partnerDataStorage = {
  get: () => getItem(PARTNER_DATA_PARAM) as PartnerData,
  set: (value: PartnerData) => setItem({ ...PARTNER_DATA_PARAM, value }),
  remove: () => removeItem({ ...PARTNER_DATA_PARAM }),
};
