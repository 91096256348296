/** @jsxImportSource @emotion/react */
//import { Link } from 'react-router-dom';
import { useMemo, useState, useEffect } from "react";
import { Button } from "react-bootstrap";

//components
import PageHeader from "../../components/atoms/PageHeader";
import "../../styles/pages/download.scss";
import FpCardList from "./components/FpCardList";
import LabelInput from "../../components/molecules/LabelInput";

import MultiSelect from "../../components/molecules/MultiSelect";
import MultiSelectTime from "../../components/molecules/MultiSelecttime";
import {
  PHONE_DAY_OPEN_DEFAULT,
  PHONE_DAY_OPEN_TEST,
  PHONE_DAY_OPEN_FINANCIAL_AGENCY,
  PHONE_DAY_OPEN_SSNB,
  PHONE_TIME_DEFAULT,
  PHONE_TIME_TEST,
  PHONE_TIME_FINANCIAL_AGENCY,
  PHONE_TIME_SSNB
} from "../../components/molecules/PhoneSetting";

import { useContactFP } from "../../query/useContactFP";
import {
  cartStorage,
  //detailDiagnoseStorage,
  simpleDiagnoseStorage,
} from "../../utils/storage";
import { EstimateInsurance1, MultiSelectDay } from "../../openApi/api";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { telFormatter, emailFormatter } from "../../utils/formatter";

const Download_document = () => {
  useDocumentTitle("保デジWEB-問い合わせ");
  
  const baseCode = process.env.REACT_APP_CODE;
  
  const queryParameters = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );

  const fromComparison = queryParameters.get("fromComparison");

  const simpleDiagnose = useMemo(() => simpleDiagnoseStorage.get(), []);
  //const detailDiagnose = useMemo(() => detailDiagnoseStorage.get(), []);

  const diagnosCode = useMemo(
    () =>
      simpleDiagnose?.result?.diagnos_code ??
      queryParameters.get("diagnosCode") ??
      undefined,
    [simpleDiagnose, queryParameters]
  );

  const cartList = useMemo(() => cartStorage.get()?.insuranceList ?? [], []);

  const estimateInsurances = useMemo(() => {
    return cartList.map((cart) => ({
      insurance_code: cart.insurance_id,
      insurance_fee_codes: cart.insurance_fee_codes,
      insurance_sub_fee_codes: cart.insurance_sub_fee_codes,
    })) as EstimateInsurance1[];
  }, [cartList]);

  const [name, setName] = useState<string>();
  const [nameKana, setNameKana] = useState<string>();
  const [tel, setTel] = useState<string>();
  const [candidateDayOfWeek, setCandidateDayOfWeek] = useState<string[]>();
  const [candidateTimeZone, setCandidateTimeZone] = useState<string[]>();
  const [email, setEmail] = useState<string>("");
  const [company, setCompany] = useState<string>();
  const { mutate: postContactFP } = useContactFP();
  const [telErrors, setTelErrors] = useState<string[]>([]);
  const [emailErrors, setEmailErrors] = useState<string[]>([]);
  const mobileNumberPattern = /^(090|080|070|050)\d{8}$/;
  
  const [optionsDaySelect, setOptionsDaySelect] = useState<MultiSelectDay[]>([]);
  const [optionsTimeSelect, setOptionsTimeSelect] = useState<string[]>([]);
  
  useEffect(() => {
    if (baseCode === 'test'){
      setOptionsDaySelect(PHONE_DAY_OPEN_TEST);
      setOptionsTimeSelect(PHONE_TIME_TEST);
    } else if (baseCode === 'financial-agency'){
      setOptionsDaySelect(PHONE_DAY_OPEN_FINANCIAL_AGENCY);
      setOptionsTimeSelect(PHONE_TIME_FINANCIAL_AGENCY);
    } else if (baseCode === 'ssnb'){
      setOptionsDaySelect(PHONE_DAY_OPEN_SSNB);
      setOptionsTimeSelect(PHONE_TIME_SSNB);
    } else {
      setOptionsDaySelect(PHONE_DAY_OPEN_DEFAULT);
      setOptionsTimeSelect(PHONE_TIME_DEFAULT);
    }
  }, [baseCode, setOptionsDaySelect]);
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = telFormatter(e.target.value);
    setTel(inputValue);

    if (mobileNumberPattern.test(inputValue)) {
      // 携帯電話番号の場合、エラーをクリア
      setTelErrors([]);
    } else {
      // 携帯電話番号でない場合、エラーを設定
      setTelErrors(["携帯電話番号を入力してください。"]);
    }
  };
  
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = emailFormatter(e.target.value);
    setEmail(inputValue);
    
    if (inputValue.length === 0){
      setEmailErrors([]);
    } else if (emailPattern.test(inputValue)){
      setEmailErrors([]);
    } else {
      setEmailErrors(["有効なメールアドレスを入力してください。"]);
    }
  };

  const [nameErrors, setNameErrors] = useState<string[]>([]);
  
  const namePattern = /^[^0-9０-９]+$/;
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setName(inputValue);
    if (namePattern.test(inputValue)){
      setNameErrors([]);
    } else {
      setNameErrors(["漢字で入力してください。"]);
    }
  };

  const [nameKanaErrors, setNameKanaErrors] = useState<string[]>([]);
  
  const kanaPattern = /^[^0-9０-９\u4E00-\u9FFF\u3041-\u3093]+$/;
  const handleKanaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setNameKana(inputValue);
    if (kanaPattern.test(inputValue)){
      setNameKanaErrors([]);
    } else {
      setNameKanaErrors(["カタカナで入力してください。"]);
    }
  };
  
  const clearUndefine = () => {
    if (name === undefined) setName("");
    if (nameKana === undefined) setNameKana("");
    if (tel === undefined) setTel("");
    if (candidateDayOfWeek === undefined) setCandidateDayOfWeek([]);
    if (candidateTimeZone === undefined) setCandidateTimeZone([]);
    if (email === undefined) setEmail("");
    if (company === undefined) setCompany("");
  };
  const onSubmit = () => {
    const submittedEmail = email || "";
    if (
      name === undefined ||
      name === "" ||
      nameKana === undefined ||
      nameKana === "" ||
      tel === undefined ||
      tel === "" ||
      candidateDayOfWeek === undefined ||
      candidateDayOfWeek.length === 0 ||
      candidateTimeZone === undefined ||
      candidateTimeZone.length === 0 ||
      !mobileNumberPattern.test(tel) ||
      !namePattern.test(name) ||
      !kanaPattern.test(nameKana) ||
      (email.length > 0 && !emailPattern.test(email))
    ) {
      clearUndefine();
      return false;
    }
    
    if (baseCode === 'ssnb'){
      if (company === undefined || company === ""){
        clearUndefine();
        return false;
      }
    }
    
    postContactFP({
      diagnos_code: diagnosCode,
      name,
      name_kana: nameKana,
      tel: telFormatter(tel),
      candidate_day_of_week: candidateDayOfWeek,
      candidate_time_zone: candidateTimeZone,
      email: submittedEmail,
      from_comparison: fromComparison === "true",
      estimate_insurances: estimateInsurances,
      company: company,
      base_code: baseCode ?? '',
    });
  };

  return (
    <>
      <section className="download-fp-section">
        <PageHeader>無料相談・問い合わせ</PageHeader>
        <FpCardList />
        <div className="downloadDocumentForm">
          <LabelInput
            label="名前"
            placeholder="苗字　名前"
            value={name}
            onChange={handleNameChange}
            onBlur={(e) => setName(e.target.value)}
            errors={nameErrors}
            required
          />
          <LabelInput
            label="フリガナ"
            placeholder="フリ　ガナ"
            value={nameKana}
            onChange={handleKanaChange}
            onBlur={(e) => setNameKana(e.target.value)}
            errors={nameKanaErrors}
            required
          />
          <LabelInput
            label="電話番号（ハイフンなし）"
            placeholder=""
            value={tel}
            onChange={handleChange}
            onBlur={(e) => setTel(telFormatter(e.target.value))}
            errors={telErrors}
            required
          />
          <MultiSelect
            label="電話がつながりやすい曜日"
            options={optionsDaySelect}
            value={candidateDayOfWeek}
            onChange={(data) => setCandidateDayOfWeek(data)}
            selectKey="dayOfWeek"
            required
          />
          
          <MultiSelectTime
            label="電話がつながりやすい時間帯"
            options={optionsTimeSelect}
            value={candidateTimeZone}
            onChange={(data) => setCandidateTimeZone(data)}
            selectKey="timeZone"
            required
          />
          {baseCode === 'ssnb' && (
            <LabelInput
              label="勤務先"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              required
            />
          )}
          <LabelInput
            label="メールアドレス"
            type="email"
            value={email}
            onChange={handleEmailChange}
            onBlur={(e) => setEmail(emailFormatter(e.target.value))}
            errors={emailErrors}
            required={false}
          />
          <Button
            color="primary"
            className="downloadDocumentSubmit"
            onClick={onSubmit}
          >
            送信する
          </Button>
        </div>
      </section>
    </>
  );
};
export default Download_document;
