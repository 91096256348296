import { useMemo } from "react";
import { jpNumFormatter } from "../../../utils/formatter";
import { simpleDiagnoseStorage } from "../../../utils/storage";
import { ChartItem, SummaryChart } from "./SummaryChart";

export const AnnualPremium = () => {
  const { result: simpleDiagnose, request: diagnoseReq } = useMemo(
    () => simpleDiagnoseStorage.get(),
    []
  );

  const chartData = useMemo(() => {
    const hodegiResult = {
      label: "現在",
      value: simpleDiagnose.current_yearly_insurance_fee ?? 0,
    };
    const compensationAvg = {
      label: `${diagnoseReq.age - (diagnoseReq.age % 10)}代の平均`,
      value: simpleDiagnose.average_insurance_fee_generation ?? 0,
    };

    const userInputResult = {
      label: "見直し後",
      value: simpleDiagnose.review_yearly_insurance_fee ?? 0,
    };

    let result: ChartItem[] = [];
    switch (simpleDiagnose.disp_type) {
      case 1:
        result.push(hodegiResult, compensationAvg, userInputResult);
        break;
      case 2:
        result.push(hodegiResult, userInputResult);
        break;
      case 3:
        result.push(userInputResult, compensationAvg);
        break;
    }
    result = result.sort((a, b) => b.value - a.value);
    return result;
  }, [simpleDiagnose, diagnoseReq]);

  return (
    <section>
      <div className="cardWrapper">
        <div className="cardContainer">
          <h2 className="typographyTextTitle typographyPrimaryColor">
            {simpleDiagnose.disp_type === 1 || simpleDiagnose.disp_type === 2
              ? "節約見込み額"
              : "年間保険料"}
          </h2>
          <p className="typographyTextSupport typographySecondaryColor">
            {simpleDiagnose.disp_type === 1 || simpleDiagnose.disp_type === 2
              ? "もし保険を見直した場合、節約できそうな金額"
              : "もし保険に加入した場合、年間でお支払いいただく金額"}
          </p>
          <div className="canSave_estimate">
            <div>
              <h2 className="canSave_price">
                <span className="canSave_annual">年間&nbsp;</span>
                <span className="typographyNumTextLarge typographyPrimaryColor">
                  {jpNumFormatter(
                    simpleDiagnose.disp_type === 1 ||
                      simpleDiagnose.disp_type === 2
                      ? simpleDiagnose.saved_money!
                      : simpleDiagnose.review_yearly_insurance_fee!,
                    true
                  )}
                </span>
                <span className="canSave_yen">円</span>
              </h2>
              <h4 className="typographyTextBody typographyBlackColor fw-bold right">
                {simpleDiagnose.disp_type === 1 ||
                simpleDiagnose.disp_type === 2
                  ? "節約できそうです！"
                  : "で必要保障が準備可能です！"}
              </h4>
            </div>
          </div>
          {simpleDiagnose.disp_type !== 4 && (
            <div className="canSaveContainer"><SummaryChart chartData={chartData} /></div>
          )}
        </div>
      </div>
    </section>
  );
};
