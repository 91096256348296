import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Api } from "../openApi";
import { ApiContactEmailRequest } from "../openApi/api";

export const useContactEmail = () => {
  const navigate = useNavigate();
  return useMutation(
    ["postContactEmail"],
    async (params: ApiContactEmailRequest) => {
      return Api.postApiContactEmail(params);
    },
    {
      onSuccess: (res) => {
        navigate("/contact-complete?type=email");
      },
      onError: (e) => {
        console.log(e);
      },
    }
  );
};
