import { ScrollBackBtn } from "../../../components/atoms/Button";

interface Props {
  current: number;
  isAskedHealthCheck: boolean | undefined;
  setIsAskedHealthCheck: (hasSmoked: boolean) => void;
  onPrev: (value: number) => void;
}

export const Question2 = ({
  current,
  isAskedHealthCheck,
  setIsAskedHealthCheck,
  onPrev,
}: Props) => {
  return (
    <section
      className={`cardContainer fade-in-slide-up ${
        current === 2 ? "currentCard" : ""
      }`}
      id="question-2"
    >
      <ScrollBackBtn onClick={() => onPrev(1)} />
      <div className="cardWrapper question1">
        <div className="titleImage">
          <img src="/img/clinic.svg" alt="" />
        </div>
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            2&nbsp;/&nbsp;8
          </span>
          <h2 className="typographyAccentOrange typographyNumTextProgress">
            あなたは、1年以内に受けた健康診断で再検査や精密検査を受けましたか
          </h2>
        </div>
        <fieldset>
          <label>
            <input
              type="radio"
              value="true"
              aria-checked={isAskedHealthCheck === true}
              onClick={() => setIsAskedHealthCheck(true)}
            />
            <div>
              <span>はい</span>
              <span>検査した</span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              value="false"
              aria-checked={isAskedHealthCheck === false}
              onClick={() => setIsAskedHealthCheck(false)}
            />
            <div>
              <span>いいえ</span>
              <span>検査していない</span>
            </div>
          </label>
        </fieldset>
      </div>
    </section>
  );
};
