import { useQuery } from "@tanstack/react-query";
import { Api } from "../openApi";
import { GetApiWholeDeathInsurancesInsuranceCode200Response } from "../openApi/api";

export const useWholeDeathInsuranceDetail = (
  insuranceCode: string,
  setResponse: (
    response: GetApiWholeDeathInsurancesInsuranceCode200Response
  ) => void
) => {
  return useQuery(
    ["getWholeDeathInsuranceDetail"],
    async () => {
      const { data } = await Api.getApiWholeDeathInsurancesInsuranceCode(
        insuranceCode
      );
      setResponse(data);
      return data;
    },
    {
      enabled: false,
    }
  );
};
