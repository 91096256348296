/**  @jsxImportSource @emotion/react */
import {
  ApiIncomeProtectionInsurancesRankResponseInsurancesInner,
  ApiTermDeathInsurancesRankResponseInsurancesInner,
  ApiWholeDeathInsurancesRankResponseInsurancesInner,
  Insurance,
} from "../../openApi/api";
import "../../styles/components/comparisonPopup.scss";

interface Props {
  insurance?:
    | Insurance
    | ApiWholeDeathInsurancesRankResponseInsurancesInner
    | ApiTermDeathInsurancesRankResponseInsurancesInner
    | ApiIncomeProtectionInsurancesRankResponseInsurancesInner;
  setIsPopupOpen: (isPopupOpen: boolean) => void;
}

const AddInsuranceToCartPopup = ({ insurance, setIsPopupOpen }: Props) => {
  return (
    <div className="popupCon">
      <div className="popup">
        <span
          className="backButton"
          onClick={() => {
            setIsPopupOpen(false);
          }}
        >
          ×
        </span>
        <img
          src={insurance?.thumbnail_url}
          alt={insurance?.insurance_name}
          style={{ maxWidth: "100%", height: "100%" }}
        />
        <div className="text">この保険をMyプランに 反映させました！</div>
      </div>
    </div>
  );
};
export default AddInsuranceToCartPopup;
