import { useQuery } from "@tanstack/react-query";
import { Api } from "../openApi";
import { simpleDiagnoseStorage } from "../utils/storage";
import { useMemo } from "react";

export type MedicalInsuranceRankProps = {
  order: number;
  age: number;
  sex: number;
  hospitalizationGuarantee: number;
  hospitalizationGuaranteeDays: number;
  paymentExemption: boolean;
  underwritingEasing: boolean;
  maxBudget?: number;
  intentionRecommendInsurance: boolean;
  hospitalizationOnce?: number;
  surgeryGuarantee?: boolean;
  commutingDailyAmount?: number;
  commutingDailyAmountDays?: number;
  commutingOnce?: number;
  advancedMedical?: boolean;
  cancerSpecialContractAmount?: number;
  specifiedDisease?: boolean;
  deathSpecialContractAmount?: number;
  congratulatoryMoney?: boolean;
  baseCode?: string;
  onSuccess?: () => void;
};

export const useMedicalInsuranceRank = (params: MedicalInsuranceRankProps) => {

  const { result: simpleDiagnose } = useMemo(
    () => simpleDiagnoseStorage.get(),
    []
  );

  return useQuery(
    ["getMedicalInsuranceRank", params],
    async () => {
      const { data } = await Api.getApiMedicalInsuranceRank(
        params.order,
        params.age,
        params.sex,
        params.hospitalizationGuarantee,
        params.hospitalizationGuaranteeDays,
        params.paymentExemption,
        params.underwritingEasing,
        params.intentionRecommendInsurance,
        params.maxBudget,
        params.hospitalizationOnce,
        params.surgeryGuarantee,
        params.commutingDailyAmount,
        params.commutingDailyAmountDays,
        params.commutingOnce,
        params.advancedMedical,
        params.cancerSpecialContractAmount,
        params.specifiedDisease,
        params.deathSpecialContractAmount,
        params.congratulatoryMoney,
        params.baseCode,
        simpleDiagnose.diagnos_code
      );
      params.onSuccess && params.onSuccess();
      return data;
    },
    { enabled: false }
  );
};
