import { useState } from "react";
import { ArrowButton, ScrollBackBtn } from "../../../components/atoms/Button";
import { jpNumFormatter, numDecryptor } from "../../../utils/formatter";
interface Props {
  current: number;
  hasHouse: boolean;
  rent: number | undefined;
  setRent: (value: number) => void;
  onPrev: (value: number) => void;
}

export const Question4 = ({
  rent,
  current,
  hasHouse,
  setRent,
  onPrev,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState<string | undefined>(
    rent ? rent?.toString() : ""
    );
  const [errors, setErrors] = useState<string[]>([]);
  
  const validate = (value: string) => {
    const err: string[] = [];
    const rentText: string = hasHouse ? 'ローン': '家賃';
    if (value === "") {
      err.push(`${rentText}を入力してください`);
    } else if (value && parseInt(value) > 10000) {
      err.push("100,000万円以下を入力してください");
    }
    setErrors(err);
    return err.length > 0;
  };

  const onChange = (value: string) => {
    if (value === "" || /^-?\d+$/.test(value)) {
      validate(value);
      setValue(value);
      // setRent(parseInt(value));
    }
  };

  const onNext = () => {
    if (validate(value ?? '')){
      return;
    }
    
    if (errors.length > 0) return;
    setRent(parseInt(value!));
  };

  return (
    <section
      className={`cardContainer fade-in-slide-up ${
        current === 4 ? "currentCard" : ""
      }`}
      id="question-4"
    >
      <ScrollBackBtn onClick={() => onPrev(3)} />
      <div className="cardWrapper questionTxt">
        <div className="titleImage">
          <img src="/img/house.svg" alt="" />
        </div>
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            4&nbsp;/&nbsp;8
          </span>
          <h2 className="typographyAccentOrange typographyNumTextProgress">
            {hasHouse
              ? "月々の住宅ローンなどのお支払いはいくらですか"
              : "月々の家賃はいくらですか"}
          </h2>
        </div>
        <fieldset className={errors.length > 0 ? "error" : ""}>
          <div data-prefix="およそ" data-suffix="万円">
            <input
              type="text"
              inputMode="numeric"
              aria-invalid="true"
              name="rent"
              value={
                !isFocused && value
                  ? jpNumFormatter(parseInt(value), true)
                  : value
              }
              onChange={(e) => onChange(numDecryptor(e.target.value))}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </div>
          <div className="validation">
            {errors.map((error) => {
              return error;
            })}
          </div>
        </fieldset>
      </div>
      <div className="card-align">
        <ArrowButton onClick={onNext} />
      </div>
    </section>
  );
};
