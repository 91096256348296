/**  @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { Insurance } from "../../../../openApi/api";
import { ContentsColumnCommon } from "./ContentsColumnCommon";
import {
  COMMUTING_DAILY_OPTIONS,
  COMMUTING_DAYS_OPTIONS,
  COMMUTING_ONCE_OPTIONS,
  HOSPITALIZATION_GUARANTEE_DAYS_OPTIONS,
  HOSPITALIZATION_GUARANTEE_OPTIONS,
  HOSPITALIZATION_ONCE_OPTIONS,
  SPECIAL_CONTRACT_OPTIONS,
} from "./DropdownItem";
import { formatForDisplayAmountText, formatForDisplayDaysText, formatTextWithLineBreaks, formatForDisplaySubText } from "./utils";

interface Props {
  typeOfInsurance: number;
  insurance: Insurance;
  lowPriceRank: number;
  age: number;
  sex: number;
  hospitalizationGuarantee: number;
  setIsPopupOpen: (isPopupOpen: boolean) => void;
  setFocusedIndex: (focusedIndex: number) => void;
  insuranceListInCart: Insurance[];
  setInsuranceListInCart: (prevCart: Insurance[]) => void;
  insuranceIdListInCart: string[];
  setInsuranceIdListInCart: (insuranceIdListInCart: string[]) => void;
  hospitalizationGuaranteeDays: number;
  hospitalizationOnce: number;
  surgeryGuarantee: boolean;
  commutingDailyAmount: number;
  commutingDailyAmountDays: number;
  commutingOnce: number;
  advancedMedical: boolean;
  cancerSpecialContractAmount: number;
  specifiedDisease: boolean;
  deathSpecialContractAmount: number;
  congratulatoryMoney: boolean;
  paymentExemption: boolean;
  checkedRankList: string[];
  setCheckedRankList: (checkedRankList: string[]) => void;
  setIsContentsColumnRendered: (isContentsColumnRendered: boolean) => void;
  numberOfInsurances: number | undefined;
}

export const ContentsColumnMedical = ({
  typeOfInsurance,
  insurance,
  lowPriceRank,
  age,
  sex,
  hospitalizationGuarantee,
  setIsPopupOpen,
  setFocusedIndex,
  insuranceListInCart,
  setInsuranceListInCart,
  insuranceIdListInCart,
  setInsuranceIdListInCart,
  hospitalizationGuaranteeDays,
  hospitalizationOnce,
  surgeryGuarantee,
  commutingDailyAmount,
  commutingDailyAmountDays,
  commutingOnce,
  advancedMedical,
  cancerSpecialContractAmount,
  specifiedDisease,
  deathSpecialContractAmount,
  congratulatoryMoney,
  paymentExemption,
  checkedRankList,
  setCheckedRankList,
  setIsContentsColumnRendered,
  numberOfInsurances,
}: Props) => {
  useEffect(() => {
  	if (lowPriceRank === numberOfInsurances) setIsContentsColumnRendered(true);
  }, [lowPriceRank, setIsContentsColumnRendered, numberOfInsurances]);
  return (
    <div className="plan">
      <ContentsColumnCommon
        typeOfInsurance={typeOfInsurance}
        insurance={insurance}
        lowPriceRank={lowPriceRank}
        age={age}
        sex={sex}
        guaranteeAmount={hospitalizationGuarantee}
        setIsPopupOpen={setIsPopupOpen}
        setFocusedIndex={setFocusedIndex}
        insuranceListInCart={insuranceListInCart}
        setInsuranceListInCart={setInsuranceListInCart}
        insuranceIdListInCart={insuranceIdListInCart}
        setInsuranceIdListInCart={setInsuranceIdListInCart}
        hospitalizationGuaranteeDays={hospitalizationGuaranteeDays}
        hospitalizationOnce={hospitalizationOnce}
        surgeryGuarantee={surgeryGuarantee}
        commutingDailyAmount={commutingDailyAmount}
        commutingDailyAmountDays={commutingDailyAmountDays}
        commutingOnce={commutingOnce}
        advancedMedical={advancedMedical}
        cancerSpecialContractAmount={cancerSpecialContractAmount}
        specifiedDisease={specifiedDisease}
        deathSpecialContractAmount={deathSpecialContractAmount}
        congratulatoryMoney={congratulatoryMoney}
        paymentExemption={paymentExemption}
        checkedRankList={checkedRankList}
        setCheckedRankList={setCheckedRankList}
      />
      <div className="hospitalizationGuarantee listBody mh162">
        <span>
          {formatForDisplayAmountText(
            insurance.hospitalization_guarantee,
            HOSPITALIZATION_GUARANTEE_OPTIONS,
            hospitalizationGuarantee
          )}
        </span>
      </div>
      <div className="hospitalizationGuaranteeDays listBody mh100">
        <span>
          {formatForDisplayDaysText(
            insurance.hospitalization_guarantee,
            HOSPITALIZATION_GUARANTEE_DAYS_OPTIONS,
            hospitalizationGuaranteeDays
          )}
        </span>
      </div>
      <div className="hospitalizationOnce listBody mh100">
        <span>
          {formatForDisplayAmountText(
            insurance.hospitalization_once,
            HOSPITALIZATION_ONCE_OPTIONS,
            hospitalizationOnce
          )}
        </span>
      </div>
      <div className="surgeryGuarantee listBody mh100">
    	<span>
          {formatForDisplaySubText(insurance.surgery_guarantee)}
        </span>
      </div>
      <div className="commutingDailyAmount listBody mh162">
        <span>
          {formatForDisplayAmountText(
            insurance.commuting_daily,
            COMMUTING_DAILY_OPTIONS,
            commutingDailyAmount
          )}
        </span>
      </div>
      <div className="commutingDailyAmountDays listBody mh100">
        <span>
          {formatForDisplayDaysText(
            insurance.commuting_daily,
            COMMUTING_DAYS_OPTIONS,
            commutingDailyAmountDays
          )}
        </span>
      </div>
      <div className="commutingOnce listBody mh100">
        <span>
          {formatForDisplayAmountText(
            insurance.commuting_once,
            COMMUTING_ONCE_OPTIONS,
            commutingOnce
          )}
        </span>
      </div>
      <div className="advancedMedical listBody mh100">
    	<span>
          {formatForDisplaySubText(insurance.advanced_medical)}
        </span>
      </div>
      <div className="cancerSpecialContractAmount listBody mh100">
        <span>
          {formatForDisplayAmountText(
            insurance.cancer_special_contract,
            SPECIAL_CONTRACT_OPTIONS,
            cancerSpecialContractAmount
          )}
        </span>
      </div>
      <div className="specifiedDisease listBody mh100">
    	<span>
          {formatForDisplaySubText(insurance.specified_disease)}
        </span>
      </div>
      <div className="deathSpecialContractAmount listBody mh100">
        <span>
          {formatForDisplayAmountText(
            insurance.death_special_contract,
            SPECIAL_CONTRACT_OPTIONS,
            deathSpecialContractAmount
          )}
        </span>
      </div>
      <div className="congratulatoryMoney listBody mh100">
    	<span>
          {formatForDisplaySubText(insurance.congratulatory_money)}
        </span>
      </div>
      <div className="paymentExemption listBody mh100">
        <span>{insurance.is_insurance_payment_exemption ? "あり" : "なし"}</span>
      </div>
        <div className="attention listBody mh50 miniText">{formatTextWithLineBreaks(insurance.attention_text)}</div>
      <div className="condition listBody mh50 miniText">{insurance.condition_wording}</div>
      <div className="approvalNumber listBody mh100 approvalText">{insurance.approval_number}</div>
    </div>
  );
};
