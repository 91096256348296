interface Props {
  percentage: number;
}

const ProgressBar = ({ percentage }: Props) => {
  return (
    <div className="simpleProgressBar">
      <div
        style={{
          width: `${percentage}%`,
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
