import { ChangeEvent, useMemo } from "react";

interface Props {
  label: string;
  required?: boolean;
  placeholder?: string;
  value?: string;
  errors?: string[];
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
}

const LabelInput = ({
  label,
  required,
  placeholder,
  value,
  errors,
  onChange,
  onBlur,
  type = 'text',
}: Props) => {
  const validate = useMemo(() => {
    if (required && value === "") return `${label}を入力してください`;
    return "";
  }, [value, label, required]);

  return (
    <label
      htmlFor=""
      className={`flexColumn ${
        (validate !== "" || (errors && errors.length > 0)) && "error"
      }`}
    >
      <span className="typographyAnswerBtnDescription">
        {label}
        {required ? (
          <span className="typographyAccentOrange">（必須）</span>
        ) : (
          <span className="typographyAccentgree">（任意）</span>
        )}
      </span>
      <input
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
      />
      {validate !== "" && <span className="validation">{validate}</span>}
      {errors &&
        errors.length > 0 &&
        errors.map((error) => <span className="validation">{error}</span>)}
    </label>
  );
};

export default LabelInput;
