import { useQuery } from "@tanstack/react-query";
import { Api } from "../openApi";
import { GetApiPartnersPartnerCode200Response } from "../openApi/api";

interface Props {
  partnerCode?: string;
  callback: (data: GetApiPartnersPartnerCode200Response) => void;
}

export const useGetPartnerInfo = ({ partnerCode, callback }: Props) => {
  return useQuery({
    queryKey: ["useGetPartnerInfo"],
    queryFn: async () => {
      if (partnerCode !== undefined) {
        return Api.getApiPartnersPartnerCode(partnerCode);
      }
    },
    onSuccess: async (res: any) => {
      const data = await res;
      callback(data.data);
    },
    onError: (e: any) => {
      console.log(e);
    },
    enabled: !!partnerCode,
  });
};
