/**  @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { ApiWholeDeathInsurancesRankResponseInsurancesInner } from "../../../../openApi/api";
import { ContentsColumnCommon } from "./ContentsColumnCommon";
import { WHOLE_DEATH_BENEFIT_OPTIONS } from "./DropdownItem";
import { formatForDisplayAmountText, formatTextWithLineBreaks } from "./utils";

interface Props {
  typeOfInsurance: number;
  insurance: ApiWholeDeathInsurancesRankResponseInsurancesInner;
  lowPriceRank: number;
  age: number;
  sex: number;
  deathBenefit: number;
  setIsPopupOpen: (isPopupOpen: boolean) => void;
  setFocusedIndex: (focusedIndex: number) => void;
  insuranceListInCart: ApiWholeDeathInsurancesRankResponseInsurancesInner[];
  setInsuranceListInCart: (
    prevCart: ApiWholeDeathInsurancesRankResponseInsurancesInner[]
  ) => void;
  insuranceIdListInCart: string[];
  setInsuranceIdListInCart: (insuranceIdListInCart: string[]) => void;
  cancelRepayment: boolean;
  livingNeeds: boolean;
  paymentExemption: boolean;
  checkedRankList: string[];
  setCheckedRankList: (checkedRankList: string[]) => void;
  setIsContentsColumnRendered: (isContentsColumnRendered: boolean) => void;
  numberOfInsurances: number | undefined;
}

export const ContentsColumnWholeDeath = ({
  typeOfInsurance,
  insurance,
  lowPriceRank,
  age,
  sex,
  deathBenefit,
  setIsPopupOpen,
  setFocusedIndex,
  insuranceListInCart,
  setInsuranceListInCart,
  insuranceIdListInCart,
  setInsuranceIdListInCart,
  cancelRepayment,
  livingNeeds,
  paymentExemption,

  checkedRankList,
  setCheckedRankList,
  setIsContentsColumnRendered,
  numberOfInsurances,
}: Props) => {
  useEffect(() => {
    if (lowPriceRank === numberOfInsurances) setIsContentsColumnRendered(true);
  }, [lowPriceRank, setIsContentsColumnRendered, numberOfInsurances]);
  return (
    <div className="plan">
      <ContentsColumnCommon
        typeOfInsurance={typeOfInsurance}
        insurance={insurance}
        lowPriceRank={lowPriceRank}
        age={age}
        sex={sex}
        guaranteeAmount={deathBenefit}
        setIsPopupOpen={setIsPopupOpen}
        setFocusedIndex={setFocusedIndex}
        insuranceListInCart={insuranceListInCart}
        setInsuranceListInCart={setInsuranceListInCart}
        insuranceIdListInCart={insuranceIdListInCart}
        setInsuranceIdListInCart={setInsuranceIdListInCart}
        cancelRepayment={cancelRepayment}
        livingNeeds={livingNeeds}
        paymentExemption={paymentExemption}
        checkedRankList={checkedRankList}
        setCheckedRankList={setCheckedRankList}
      />
      <div className="deathBenefit listBody mh162">
        <span>
          {formatForDisplayAmountText(
            insurance.death_benefit,
            WHOLE_DEATH_BENEFIT_OPTIONS,
            deathBenefit
          )}
        </span>
      </div>
      <div className="cancelRepayment listBody mh100">
        <span>{insurance.is_cancellation_refund ? "あり" : "なし"}</span>
      </div>
      <div className="paymentExemption listBody mh100">
        <span>{insurance.is_insurance_payment_exemption ? "あり" : "なし"}</span>
      </div>
      <div className="livingNeeds listBody mh100">
        <span>{insurance.is_living_needs ? "あり" : "なし"}</span>
      </div>
      <div className="attention listBody mh50 miniText">{formatTextWithLineBreaks(insurance.attention_text)}</div>
      <div className="condition listBody mh50 miniText">{formatTextWithLineBreaks(insurance.condition_wording)}</div>
      <div className="approvalNumber listBody mh100 approvalText">{insurance.approval_number}</div>
    </div>
  );
};
