import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  Tick,
} from "chart.js";
import { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { jpNumFormatter } from "../../../utils/formatter";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export type ChartItem = {
  label: string;
  value: number;
};

interface Props {
  chartData: ChartItem[];
}

type ChartData = {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
  }[];
};

export const SummaryChart = ({ chartData }: Props) => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
    },
    scales: {
      y: {
        ticks: {
          callback: (
            tickValue: string | number,
            index: number,
            ticks: Tick[]
          ) => {
            return jpNumFormatter(
              typeof tickValue === "number"
                ? (tickValue as number)
                : parseInt(tickValue as string)
                , true
            );
          },
        },
      },
    },
  };

  const barData = useMemo(() => {
    const data: ChartData = {
      labels: [],
      datasets: [
        {
          label: "",
          data: [],
          backgroundColor: "rgb(0,102,255)",
        },
      ],
    };

    chartData.forEach((item, index) => {
      data.labels.push(`${item.label}`);
      data.datasets[0].data.push(item.value);
    });
    return data;
  }, [chartData]);

  return <Bar options={options} data={barData} />;
};
