/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const gridContainer = css`
	display: grid;
`
export const grid1Column = css`
	display: grid;
	grid-template-columns: 1fr;
	gap: 18px;
`
export const grid2Column = css`
	display: grid;
	grid-template-columns: repeat(2,1fr);
	gap: 18px;
`
export const grid2ColumnFull = css`
	grid-column: 1/3;
`
export const grid3Column = css`
	display: grid;
	grid-template-columns: repeat(3,1fr);
	gap: 18px;
`
export const grid3ColumnFull = css`
		grid-column: 1/3;
`
export const flexColumn = css`
	display: flex;
	flex-flow: column;
`
export const flexAlignCenter = css`
	display: flex;
	align-items: center;
`
export const comparisonGrid2Column = css`
	display: grid;
	grid-template-columns: 25% 75%;
`