import { ScrollBackBtn } from "../../../components/atoms/Button";

interface Props {
  current: number;
  childrenCount?: number;
  setChildrenCount: (value: number) => void;
  onPrev: (value: number) => void;
}

const Question5 = ({
  current,
  childrenCount,
  setChildrenCount,
  onPrev,
}: Props) => {
  return (
    <section
      className={`cardContainer fade-in-slide-up ${
        current === 6 ? "currentCard" : ""
      }`}
      id="question-5"
    >
      <ScrollBackBtn onClick={() => onPrev(5)} />
      <div className="cardWrapper question5">
        <div className="titleImage">
          <img src="/img/children.svg" alt="" />
        </div>
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            5&nbsp;/&nbsp;10
          </span>
          <h2 className="typographyPrimaryColor typographyTextSubTitle">
            お子さんはいますか
          </h2>
          <span>扶養しているお子さんの人数を教えてください</span>
        </div>
        <fieldset>
          <label>
            <input
              type="radio"
              value="0"
              aria-checked={childrenCount === 0}
              onClick={() => setChildrenCount(0)}
            />
            <div>
              <span>いない</span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              value="1"
              aria-checked={childrenCount === 1}
              onClick={() => setChildrenCount(1)}
            />
            <div>
              <span>1人</span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              value="2"
              aria-checked={childrenCount === 2}
              onClick={() => setChildrenCount(2)}
            />
            <div>
              <span>2人</span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              value="3"
              aria-checked={childrenCount === 3}
              onClick={() => setChildrenCount(3)}
            />
            <div>
              <span>3人</span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              value="4"
              aria-checked={childrenCount === 4}
              onClick={() => setChildrenCount(4)}
            />
            <div>
              <span>4人以上</span>
            </div>
          </label>
        </fieldset>
      </div>
    </section>
  );
};

export default Question5;
