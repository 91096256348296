import { PropsWithChildren } from "react";

interface Props {
  image: string;
  title?: string;
  badge?: string;
}

const ImageCard: React.FC<PropsWithChildren<Props>> = ({
  image,
  title,
  children,
  badge,
}) => {
  return (
    <div className="image-card">
      <img src={image} alt="" />
      <label>{title}</label>
      <span>{children}</span>
      {badge && <div className="badge">{badge}</div>}
    </div>
  );
};

export default ImageCard;
