import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Api } from "../openApi";
import { ApiContactSmsRequest } from "../openApi/api";

interface Props {
  onError?: (error: any) => void;
}

export const useContactSms = ({ onError }: Props) => {
  const navigate = useNavigate();
  return useMutation(
    ["postContactSms"],
    async (params: ApiContactSmsRequest) => {
      return Api.postApiContactSms(params);
    },
    {
      onSuccess: (res) => {
        navigate("/contact-complete?type=sms");
      },
      onError: (e: any) => {
        onError && onError(e.response.data.errors);
      },
    }
  );
};
