import { ScrollBackBtn } from "../../../components/atoms/Button";
interface Props {
  current: number;
  hasThreeMajorDiseases?: boolean;
  setHasThreeMajorDiseases: (value: boolean | undefined) => void;
  onPrev: (value: number) => void;
}

const Question10 = ({
  current,
  hasThreeMajorDiseases,
  setHasThreeMajorDiseases,
  onPrev,
}: Props) => {
  return (
    <section className={`cardContainer fade-in-slide-up`} id="question-10">
      <ScrollBackBtn onClick={() => onPrev(10)} />
      <div className="cardWrapper question1">
        <div className="titleImage">
          <img src="/img/hospital.svg" alt="" />
        </div>
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            10&nbsp;/&nbsp;10
          </span>
          <h2 className="typographyPrimaryColor typographyTextSubTitle">
            3大疾病の経験はありますか
          </h2>
          <span>
            がん・心疾患・脳血管疾患で、入院・手術・通院（投薬含む）を経験したことがあるか教えてください
          </span>
        </div>
        <fieldset>
          <label>
            <input
              type="radio"
              value="true"
              aria-checked={hasThreeMajorDiseases === false}
              onClick={() => setHasThreeMajorDiseases(false)}
            />
            <div>
              <span>ない</span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              value="false"
              aria-checked={hasThreeMajorDiseases === true}
              onClick={() => setHasThreeMajorDiseases(true)}
            />
            <div>
              <span>ある</span>
              <span>入院・手術・通院（投薬含む）いずれかを経験</span>
            </div>
          </label>
        </fieldset>
      </div>
    </section>
  );
};

export default Question10;
