import { PropsWithChildren } from "react";
import { ArrowButton } from "../../../components/atoms/Button";

export const ButtonType = {
  TEXT: 0,
  ARROW: 1,
};

interface Props {
  btnType?: number;
  btnText?: string;
  current?: number;
  onClick?: () => void;
}

const CalcContainer: React.FC<PropsWithChildren<Props>> = ({
  children,
  btnType,
  btnText,
  current,
  onClick,
}) => {
  return (
    <section>
      <div
        className={`calcContainer ${
          current === undefined ? "fade-in --3-sec" : "fade-in-slide-up"
        } ${current === 2 ? "currentCard" : ""}`}
      >
        <div className="calcMan">
          <img src="/img/calcManBlue.svg" alt="" width={118} height={160} />
        </div>
        <div className="blueCard">{children}</div>
        {btnType === ButtonType.TEXT ? (
          <div
            className="textBtn"
            onClick={onClick}
            style={{
              position: "absolute",
              bottom: -53,
              right: 25,
            }}
          >
            <button>{btnText}</button>
          </div>
        ) : btnType === ButtonType.ARROW ? (
          <div
            style={{
              marginTop: -14,
              marginRight: 28,
              textAlign: "right",
            }}
            onClick={onClick}
          >
            <ArrowButton />
          </div>
        ) : (
          <></>
        )}
      </div>
    </section>
  );
};

export default CalcContainer;
