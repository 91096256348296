interface Props {
  title: string;
}

const LoadingPopup = ({ title }: Props) => {
  return (
    <div
      className="loading-frame"
      style={{
        background: "#000000a0",
        backdropFilter: "blur(10px)",
      }}
    >
      <div className="content">
        <span className="content-notify">
          <img
            src="/img/loading/operator.svg"
            className="content-icon"
            alt=""
          />
          <img
            src="/img/loading/loading.svg"
            className="content-waiting"
            alt=""
          />
          <label>{title}</label>
        </span>
      </div>
    </div>
  );
};

export default LoadingPopup;
