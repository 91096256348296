import { useMemo, useState } from "react";
import { ArrowButton, ScrollBackBtn } from "../../../components/atoms/Button";
import { simpleDiagnoseStorage } from "../../../utils/storage";
import { jpNumFormatter, numDecryptor } from "../../../utils/formatter";
interface Props {
  current: number;
  partnerYearlyIncome: number | undefined;
  setPartnerYearlyIncome: (value: number | undefined) => void;
  onPrev: (value: number) => void;
}

export const Question5 = ({
  current,
  partnerYearlyIncome,
  setPartnerYearlyIncome,
  onPrev,
}: Props) => {
  const simpleDiagnose = useMemo(() => simpleDiagnoseStorage.get(), []);
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState<string | undefined>(
    partnerYearlyIncome ? partnerYearlyIncome?.toString() : ""
  );
  const [errors, setErrors] = useState<string[]>([]);

  // const errors = useMemo(() => {
  //   if (value && parseInt(value) > 100000) {
  //     return ["100000万以下を入力してください"];
  //   }

  //   return [];
  // }, [value]);

  const validate = (value: string, required: boolean = false) => {
    const err: string[] = [];
    if (required && value === "") {
      err.push("年収を入力してください");
    } else if (value && parseInt(value) > 100000) {
      err.push("100,000万円以下を入力してください");
    }
    setErrors(err);
    return err.length > 0;
  };

  const onChange = (value: string) => {
    if (value === "" || /^-?\d+$/.test(value)) {
      validate(value);
      setValue(value);
      // setPartnerYearlyIncome(value ? parseInt(value) : 0);
    }
  };

  const onNext = () => {
    if (validate(value ?? '', true)) return;
    setPartnerYearlyIncome(value ? parseInt(value) : 0);
  };

  return (
    <section
      className={`cardContainer fade-in-slide-up ${
        current === 5 ? "currentCard" : ""
      }`}
      id="question-5"
    >
      <ScrollBackBtn onClick={() => onPrev(4)} />
      <div className="cardWrapper questionTxt">
        <div className="titleImage">
          <img src="/img/money.svg" alt="" />
        </div>
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            5&nbsp;/&nbsp;8
          </span>
          <h2 className="typographyAccentOrange typographyNumTextProgress">
            {simpleDiagnose.request.sex === 1
              ? "奥さまの年収は"
              : "旦那さまの年収は"}
          </h2>
          <span>診断したい方の配偶者の年収を教えてください</span>
        </div>
        <fieldset className={errors.length > 0 ? "error" : ""}>
          <div data-prefix="およそ" data-suffix="万円">
            <input
              type="text"
              inputMode="numeric"
              aria-invalid="true"
              name="partnerYearlyIncome"
              value={
                !isFocused && value
                  ? jpNumFormatter(parseInt(value), true)
                  : value
              }
              onChange={(e) => onChange(numDecryptor(e.target.value))}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </div>
          <div className="validation">
            {errors.map((error) => {
              return error;
            })}
          </div>
          <div className="typographyAlignRight mt-16">
            覚えていない場合は
            <span
              className="hasSmokedLink"
              onClick={() => {
                setErrors([]);
                setValue('');
                setPartnerYearlyIncome(undefined);
              }}
            >
              回答をスキップ
            </span>
          </div>
        </fieldset>
      </div>
      <div className="card-align">
        <ArrowButton onClick={onNext} />
      </div>
    </section>
  );
};
