import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Api } from "../openApi";
import { ApiContactFpRequest } from "../openApi/api";

export const useContactFP = () => {
  const navigate = useNavigate();
  return useMutation(
    ["postContactFP"],
    async (params: ApiContactFpRequest) => {
      return Api.postApiContactFp(params);
    },
    {
      onSuccess: () => {
        navigate("/contact-complete");
      },
      onError: (e) => {
        console.log(e);
      },
    }
  );
};
