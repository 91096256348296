import { ScrollBackBtn } from "../../../components/atoms/Button";
import { useState, useEffect } from "react";

interface Props {
  current: number;
  married?: boolean;
  setIsMarried: (value: boolean) => void;
  onPrev: (value: number) => void;
  setLimitAge: (value: boolean) => void;
  setAgeValidate: (value: boolean) => void;
}

const Question4 = ({ current, married, setIsMarried, onPrev, setLimitAge, setAgeValidate }: Props) => {
  
  const [marryType, setMarryType] = useState<number>(0);
  
  useEffect(() => {
    if (married){
      setMarryType(1);
      setLimitAge(true);
    } else if (married !== undefined){
      setMarryType(2);
      setLimitAge(false);
    }
    setAgeValidate(true);
  }, [married, setMarryType, setLimitAge, setAgeValidate]);
  
  return (
    <section
      className={`cardContainer fade-in-slide-up ${
        current === 5 ? "currentCard" : ""
      }`}
      id="question-4"
    >
      <ScrollBackBtn onClick={() => onPrev(4)} />
      <div className="cardWrapper question">
        <div className="titleImage">
          <img src="/img/married.svg" alt="" />
        </div>
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            4&nbsp;/&nbsp;10
          </span>
          <h2 className="typographyPrimaryColor typographyTextSubTitle">
            結婚していますか
          </h2>
        </div>
        <fieldset className="checkSelectBox">
          <label className={(marryType === 1) ? 'checkedActive': ''}>
            <input
              type="radio"
              value="1"
              checked={marryType === 1}
              onChange={() => setIsMarried(true)}
            />
            <div>
              <span>既婚</span>
            </div>
          </label>
          <label className={(marryType === 2) ? 'checkedActive': ''}>
            <input
              type="radio"
              value="2"
              checked={marryType === 2}
              onChange={() => setIsMarried(false)}
            />
            <div>
              <span>未婚</span>
            </div>
          </label>
        </fieldset>
      </div>
    </section>
  );
};

export default Question4;
