/**  @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState, useCallback } from "react";
import AddInsuranceToCartPopup from "../../components/molecules/addInsuranceToCartPopup";
import BlackFilter from "../../components/molecules/blackFilter";
import {
  ApiCancerInsurancesRankResponse,
  ApiCancerInsurancesRankResponseInsurancesInner,
  ApiIncomeProtectionInsurancesRankResponse,
  ApiIncomeProtectionInsurancesRankResponseInsurancesInner,
  ApiTermDeathInsurancesRankResponse,
  ApiTermDeathInsurancesRankResponseInsurancesInner,
  ApiWholeDeathInsurancesRankResponse,
  ApiWholeDeathInsurancesRankResponseInsurancesInner,
  GetApiMedicalInsuranceRank200Response,
  Insurance,
} from "../../openApi/api";

//components
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {
  useGetCancerInsuranceRank,
  useGetIncomeProtectionInsuranceRank,
  useGetMedicalInsuranceRank,
  useGetTermDeathInsuranceRank,
  useGetWholeDeathInsuranceRank,
} from "../../hooks/useGetInsuranceRank";
import "../../styles/pages/comparison.scss";
import {
  cancerPDStorage,
  cartStorage,
  commonPDStorage,
  deathPDStorage,
  medicalPDStorage,
  searchConditionStorage,
  wholeDeathPDStorage,
} from "../../utils/storage";
import { EstimateResult } from "./components/EstimateResult";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { MobileEstimateResultButton } from "./components/MobileEstimateResultButton";
import { SearchCondition } from "./components/SearchCondition";
import { TableCancer } from "./components/comparisonTable/TableCancer";
import { TableIncomeProtection } from "./components/comparisonTable/TableIncomeProtection";
import { TableMedical } from "./components/comparisonTable/TableMedical";
import { TableTermDeath } from "./components/comparisonTable/TableTermDeath";
import { TableWholeDeath } from "./components/comparisonTable/TableWholeDeath";
import LoadingPopup from "../../components/molecules/LoadingPopup";

export type InsuranceRankResponse =
  | GetApiMedicalInsuranceRank200Response
  | ApiCancerInsurancesRankResponse
  | ApiWholeDeathInsurancesRankResponse
  | ApiTermDeathInsurancesRankResponse
  | ApiIncomeProtectionInsurancesRankResponse;

export type InsuranceRankResponseInner =
  | Insurance
  | ApiCancerInsurancesRankResponseInsurancesInner
  | ApiWholeDeathInsurancesRankResponseInsurancesInner
  | ApiTermDeathInsurancesRankResponseInsurancesInner
  | ApiIncomeProtectionInsurancesRankResponseInsurancesInner;

const Comparison = () => {
  useDocumentTitle("保デジWEB-保険商品かんたん比較");

  const LOADING_TIMEOUT = 20000;
  const searchCondition = useMemo(() => searchConditionStorage.get(), []);
  const medicalDetailSerachCondition = useMemo(
    () => medicalPDStorage.get(),
    []
  );
  const cancerDetailSerachCondition = useMemo(() => cancerPDStorage.get(), []);
  const deathDetailSerachCondition = useMemo(() => deathPDStorage.get(), []);
  const wholeDeathDetailSearchCondition = useMemo(
    () => wholeDeathPDStorage.get(),
    []
  );
  
  const [isContentsColumnRendered, setIsContentsColumnRendered] = useState<boolean>(false);

  const baseCode = process.env.REACT_APP_CODE;
  
  //全保険共通の変数
  //a.検索条件周り
  const [typeOfInsurance, setTypeOfInsurance] = useState<number>(
    searchCondition?.typeOfInsurance
  );
  const [maxBudget, setMaxBudget] = useState<number>(
    searchCondition?.maxBudget
  );
  const [order, setOrder] = useState<number>(searchCondition?.order);
  const [age, setAge] = useState<number>(searchCondition?.age);
  const [sex, setSex] = useState<number>(searchCondition?.sex);
  const [underwritingEasing, setUnderwritingEasing] = useState<boolean>(
    searchCondition?.underwritingEasing
  );
  const [intentionRecommendInsurance, setIntentionRecommendInsurance] = useState<boolean>(
    searchCondition?.intentionRecommendInsurance ?? true
  );
  //b.比較表内
  const [paymentExemption, setPaymentExemption] = useState<boolean>(
    commonPDStorage.get().paymentExemption ?? false
  );
  const [paymentExemptionCancer, setPaymentExemptionCancer] = useState<boolean>(
    commonPDStorage.get().paymentExemption ?? false
  );
  const [paymentExemptionTermDeath, setPaymentExemptionTermDeath] =
    useState<boolean>(commonPDStorage.get().paymentExemption ?? false);
  const [paymentExemptionWholeDeath, setPaymentExemptionWholeDeath] =
    useState<boolean>(commonPDStorage.get().paymentExemption ?? false);
  const [
    paymentExemptionIncomeProtection,
    setPaymentExemptionIncomeProtection,
  ] = useState<boolean>(commonPDStorage.get().paymentExemption ?? false);
  
  //c.カート周り
  const [checkedRankList, setCheckedRankList] = useState<string[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [focusedIndex, setFocusedIndex] = useState<number>(0);
  const [isCartOpen, setIsCartOpen] = useState<boolean>(false);
  const cart = useMemo(() => cartStorage.get(), []);
  const [insuranceListInCart, setInsuranceListInCart] = useState<
    InsuranceRankResponseInner[]
  >(cart?.insuranceList ?? []);
  const [insuranceIdListInCart, setInsuranceIdListInCart] = useState<string[]>(
    cart?.insuranceIdList ?? []
  );

  //医療保険用の変数
  const [hospitalizationGuarantee, setHospitalizationGuarantee] =
    useState<number>(searchCondition.hospitalizationGuarantee);
  const [hospitalizationGuaranteeDays, setHospitalizationGuaranteeDays] =
    useState<number>(
      medicalDetailSerachCondition?.hospitalizationGuaranteeDays ?? 0
    );
  const [hospitalizationOnce, setHospitalizationOnce] = useState<number>(
    medicalDetailSerachCondition?.hospitalizationOnce ?? 0
  );
  const [surgeryGuarantee, setSurgeryGuarantee] = useState<boolean>(
    medicalDetailSerachCondition?.surgeryGuarantee ?? false
  );
  const [commutingDailyAmount, setCommutingDailyAmount] = useState<number>(
    medicalDetailSerachCondition?.commutingDailyAmount ?? 0
  );
  const [commutingDailyAmountDays, setCommutingDailyAmountDays] =
    useState<number>(
      medicalDetailSerachCondition?.commutingDailyAmountDays ?? 0
    );
  const [commutingOnce, setCommutingOnce] = useState<number>(
    medicalDetailSerachCondition?.commutingOnce ?? 0
  );
  const [advancedMedical, setAdvancedMedical] = useState<boolean>(
    medicalDetailSerachCondition?.advancedMedical ?? false
  );
  const [cancerSpecialContractAmount, setCancerSpecialContractAmount] =
    useState<number>(
      medicalDetailSerachCondition?.cancerSpecialContractAmount ?? 0
    );
  const [specifiedDisease, setSpecifiedDiseaseInsurance] = useState<boolean>(
    medicalDetailSerachCondition?.specifiedDisease ?? false
  );
  const [deathSpecialContractAmount, setDeathSpecialContractAmount] =
    useState<number>(
      medicalDetailSerachCondition?.deathSpecialContractAmount ?? 0
    );
  const [congratulatoryMoney, setCongratulatoryMoney] = useState<boolean>(
    medicalDetailSerachCondition?.congratulatoryMoney ?? false
  );

  //がん保険用の変数
  const [malignantDiagnosticOnce, setMalignantDiagnosticOnce] =
    useState<number>(searchCondition?.malignantDiagnosticOnce ?? 0);
  const [intraepithelialDiagnosticOnce, setIntraepithelialDiagnosticOnce] =
    useState<number>(
      cancerDetailSerachCondition?.intraepithelialDiagnosticOnce ?? 0
    );
  const [malignantDiagnosticTreatment, setMalignantDiagnosticTreatment] =
    useState<number>(
      cancerDetailSerachCondition?.malignantDiagnosticTreatment ?? 0
    );
  const [intraepithelialDiagnosticTreatment, setIntraepithelialDiagnosticTreatment] = 
    useState<number>(
      cancerDetailSerachCondition?.intraepithelialDiagnosticTreatment ?? 0
    );
  const [hospitalizationDailyAmount, setHospitalizationDailyAmount] =
    useState<number>(
      cancerDetailSerachCondition?.hospitalizationDailyAmount ?? 0
    );
  const [hospitalizationDailyAmountDays, setHospitalizationDailyAmountDays] =
    useState<number>(
      cancerDetailSerachCondition?.hospitalizationDailyAmountDays ?? 0
    );
  const [surgery, setSurgery] = useState<number>(
    cancerDetailSerachCondition?.surgery ?? 0
  );
  const [leavingHospitalOnce, setLeavingHospitalOnce] = useState<number>(
    cancerDetailSerachCondition?.leavingHospitalOnce ?? 0
  );
  const [cancerAdvancedMedical, setCancerAdvancedMedical] = useState<boolean>(
    cancerDetailSerachCondition?.cancerAdvancedMedical ?? false
  );
  const [cancerHospitalDailyFee, setCancerHospitalDailyFee] = useState<number>(
    cancerDetailSerachCondition?.cancerHospitalDailyFee ?? 0
  );
  const [cancerHospitalDailyFeeDays, setCancerHospitalDailyFeeDays] = useState<number>(
    cancerDetailSerachCondition?.cancerHospitalDailyFeeDays ?? 0
  );
  const [radiotherapy, setRadiotherapy] = useState<number>(
    cancerDetailSerachCondition?.radiotherapy ?? 0
  );
  const [anticancerDrugTreatment, setAnticancerDrugTreatment] =
    useState<boolean>(
      cancerDetailSerachCondition?.anticancerDrugTreatment ?? false
    );
  const [femaleDiseases, setFemaleDiseases] = useState<boolean>(
    cancerDetailSerachCondition?.femaleDiseases ?? false
  );

  //死亡保険用の変数
  const [wholeDeathBenefit, setWholeDeathBenefit] = useState<number>(
    searchCondition?.wholeDeathBenefit
  );
  const [termDeathBenefit, setTermDeathBenefit] = useState<number>(
    searchCondition?.termDeathBenefit
  );
  const [cancelRepayment, setCancelRepayment] = useState<boolean>(
    deathDetailSerachCondition?.cancelRepayment ?? false
  );

  const [cancelRepaymentTermDeath, setCancelRepaymentTermDeath] =
    useState<boolean>(deathDetailSerachCondition?.cancelRepayment ?? false);

  const [cancelRepaymentWholeDeath, setCancelRepaymentWholeDeath] =
    useState<boolean>(
      wholeDeathDetailSearchCondition?.cancelRepayment ?? false
    );

  const [livingNeeds, setLivingNeeds] = useState<boolean>(
    deathDetailSerachCondition?.livingNeeds ?? false
  );

  const [livingNeedsTermDeath, setLivingNeedsTermDeath] = useState<boolean>(
    deathDetailSerachCondition?.livingNeeds ?? false
  );

  const [livingNeedsWholeDeath, setLivingNeedsWholeDeath] = useState<boolean>(
    wholeDeathDetailSearchCondition?.livingNeeds ?? false
  );

  // 収入保障保険用の変数
  const [monthlyBenefit, setMonthlyBenefit] = useState<number>(searchCondition?.monthlyBenefit);
  
  const [paymentMinGuaranteeTerm, setPaymentMinGuaranteeTerm] = useState<number>(0);

  //API発火制御用の変数
  const [triggerMedicalInsuranceRankAPI, setTriggerMedicalInsuranceRankAPI] =
    useState(false);
  const [triggerCancerInsuranceRankAPI, setTriggerCancerInsuranceRankAPI] =
    useState(false);
  const [
    triggerWholeDeathInsuranceRankAPI,
    setTriggerWholeDeathInsuranceRankAPI,
  ] = useState(false);
  const [
    triggerTermDeathInsuranceRankAPI,
    setTriggerTermDeathInsuranceRankAPI,
  ] = useState(false);
  const [
    triggerIncomeProtectionInsuranceRankAPI,
    setTriggerIncomeProtectionInsuranceRankAPI,
  ] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  
  const executeSearch = () => {
    setIsLoading(true);
    setIsContentsColumnRendered(false);
    
    switch (typeOfInsurance) {
      case 1:
        getMedicalInsuranceRank();
        break;
      case 2:
        getCancerInsuranceRank();
        break;
      case 3:
        getWholeDeathInsuranceRank();
        break;
      case 4:
        getTermDeathInsuranceRank();
        break;
      case 5:
        getIncomeProtectionInsuranceRank();
        break;
      default:
        break;
    }
  };

  /* eslint-disable */
  //医療保険
  const { medicalInsuranceResponse, getMedicalInsuranceRank } =
    useGetMedicalInsuranceRank({
      order,
      age,
      sex,
      hospitalizationGuarantee,
      hospitalizationGuaranteeDays,
      paymentExemption,
      underwritingEasing,
      intentionRecommendInsurance,
      maxBudget,
      hospitalizationOnce,
      surgeryGuarantee,
      commutingDailyAmount,
      commutingDailyAmountDays,
      commutingOnce,
      advancedMedical,
      cancerSpecialContractAmount,
      specifiedDisease,
      deathSpecialContractAmount,
      congratulatoryMoney,
      baseCode,
      onSuccess: () => {
        setIsLoading(false);
        setIsContentsColumnRendered(true);
      }
    });
  //がん保険
  const { cancerInsuranceResponse, getCancerInsuranceRank } =
    useGetCancerInsuranceRank({
      order,
      age,
      sex,
      paymentExemption: paymentExemptionCancer,
      underwritingEasing,
      intentionRecommendInsurance,
      maxBudget,
      malignantDiagnosticOnce,
      intraepithelialDiagnosticOnce,
      malignantDiagnosticTreatment,
      intraepithelialDiagnosticTreatment,
      hospitalizationDailyAmount,
      hospitalizationDailyAmountDays,
      surgery,
      leavingHospitalOnce,
      cancerHospitalDailyFee,
      cancerHospitalDailyFeeDays,
      commutingOnce,
      cancerAdvancedMedical,
      radiotherapy,
      anticancerDrugTreatment,
      femaleDiseases,
      baseCode,
      onSuccess: () => {
        setIsLoading(false);
        setIsContentsColumnRendered(true);
      }
    });
  //死亡保険（終身）
  const { wholeDeathInsuranceResponse, getWholeDeathInsuranceRank } =
    useGetWholeDeathInsuranceRank({
      order,
      age,
      sex,
      deathBenefit: wholeDeathBenefit,
      paymentExemption: paymentExemptionWholeDeath,
      underwritingEasing,
      intentionRecommendInsurance,
      maxBudget,
      cancelRepayment: cancelRepaymentWholeDeath,
      livingNeeds: livingNeedsWholeDeath,
      baseCode,
      onSuccess: () => {
        setIsLoading(false);
        setIsContentsColumnRendered(true);
      }
    });
  //死亡保険（定期）
  const { termDeathInsuranceResponse, getTermDeathInsuranceRank } =
    useGetTermDeathInsuranceRank({
      order,
      age,
      sex,
      deathBenefit: termDeathBenefit,
      paymentExemption: paymentExemptionTermDeath,
      underwritingEasing,
      intentionRecommendInsurance,
      maxBudget,
      cancelRepayment: cancelRepaymentTermDeath,
      livingNeeds: livingNeedsTermDeath,
      baseCode,
      onSuccess: () => {
        setIsLoading(false);
        setIsContentsColumnRendered(true);
      }
    });
  //収入保障保険
  const { incomeProtectionInsuranceResponse, getIncomeProtectionInsuranceRank} =
    useGetIncomeProtectionInsuranceRank({
      order,
      age,
      sex,
      monthlyBenefit,
      paymentExemption: paymentExemptionIncomeProtection,
      underwritingEasing,
      intentionRecommendInsurance,
      maxBudget,
      cancelRepayment,
      livingNeeds,
      paymentMinGuaranteeTerm,
      baseCode,
      onSuccess: () => {
        setIsLoading(false);
        setIsContentsColumnRendered(true);
      }
    });

  useEffect(() => {
    setIsLoading(true);
    setIsContentsColumnRendered(false);
    setTimeout(() => {
    	setIsLoading(false);
    }, LOADING_TIMEOUT);
    switch (typeOfInsurance) {
      case 1:
        getMedicalInsuranceRank();
        break;
      case 2:
        getCancerInsuranceRank();
        break;
      case 3:
        getWholeDeathInsuranceRank();
        break;
      case 4:
        getTermDeathInsuranceRank();
        break;
      case 5:
        getIncomeProtectionInsuranceRank();
        break;
      default:
        return;
    }
  }, [
    hospitalizationGuaranteeDays,
    paymentExemption,
    paymentExemptionCancer,
    paymentExemptionIncomeProtection,
    paymentExemptionTermDeath,
    paymentExemptionWholeDeath,
    hospitalizationOnce,
    surgeryGuarantee,
    commutingDailyAmount,
    commutingDailyAmountDays,
    commutingOnce,
    advancedMedical,
    cancerAdvancedMedical,
    cancerSpecialContractAmount,
    specifiedDisease,
    getMedicalInsuranceRank,
    getCancerInsuranceRank,
    intraepithelialDiagnosticOnce,
    malignantDiagnosticTreatment,
    intraepithelialDiagnosticTreatment,
    hospitalizationDailyAmount,
    hospitalizationDailyAmountDays,
    surgery,
    leavingHospitalOnce,
    cancerHospitalDailyFee,
    cancerHospitalDailyFeeDays,
    radiotherapy,
    anticancerDrugTreatment,
    femaleDiseases,
    deathSpecialContractAmount,
    congratulatoryMoney,
    getWholeDeathInsuranceRank,
    getTermDeathInsuranceRank,
    cancelRepayment,
    cancelRepaymentTermDeath,
    cancelRepaymentWholeDeath,
    livingNeeds,
    livingNeedsTermDeath,
    livingNeedsWholeDeath,
    getIncomeProtectionInsuranceRank,
    paymentMinGuaranteeTerm,
    triggerMedicalInsuranceRankAPI,
    triggerCancerInsuranceRankAPI,
    triggerWholeDeathInsuranceRankAPI,
    triggerTermDeathInsuranceRankAPI,
    triggerIncomeProtectionInsuranceRankAPI,
    termDeathBenefit,
    wholeDeathBenefit,
    monthlyBenefit,
  ]);
  /* eslint-enable */

  const searchConditionProps = useMemo(
    () => ({
      typeOfInsurance,
      maxBudget,
      underwritingEasing,
      intentionRecommendInsurance,
      order,
      age,
      sex,
      hospitalizationGuarantee,
      malignantDiagnosticOnce,
      wholeDeathBenefit,
      termDeathBenefit,
      monthlyBenefit,
      isOpenNotes: searchCondition?.isOpenNotes,
    }),
    [
      typeOfInsurance,
      maxBudget,
      underwritingEasing,
      intentionRecommendInsurance,
      order,
      age,
      sex,
      hospitalizationGuarantee,
      malignantDiagnosticOnce,
      wholeDeathBenefit,
      termDeathBenefit,
      monthlyBenefit,
      searchCondition,
    ]
  );

  const medicalPDProps = useMemo(
    () => ({
      hospitalizationGuarantee,
      hospitalizationGuaranteeDays,
      hospitalizationOnce,
      surgeryGuarantee,
      commutingDailyAmount,
      commutingDailyAmountDays,
      commutingOnce,
      advancedMedical,
      cancerSpecialContractAmount,
      specifiedDisease,
      deathSpecialContractAmount,
      congratulatoryMoney,
    }),
    [
      hospitalizationGuarantee,
      hospitalizationGuaranteeDays,
      hospitalizationOnce,
      surgeryGuarantee,
      commutingDailyAmount,
      commutingDailyAmountDays,
      commutingOnce,
      advancedMedical,
      cancerSpecialContractAmount,
      specifiedDisease,
      deathSpecialContractAmount,
      congratulatoryMoney,
    ]
  );
  const cancerPDProps = useMemo(
    () => ({
      malignantDiagnosticOnce,
      intraepithelialDiagnosticOnce,
      malignantDiagnosticTreatment,
      intraepithelialDiagnosticTreatment,
      hospitalizationDailyAmount,
      hospitalizationDailyAmountDays,
      surgery,
      leavingHospitalOnce,
      cancerHospitalDailyFee,
      cancerHospitalDailyFeeDays,
      commutingOnce,
      cancerAdvancedMedical,
      radiotherapy,
      anticancerDrugTreatment,
      femaleDiseases,
      paymentExemption: paymentExemptionCancer,
    }),
    [
      malignantDiagnosticOnce,
      intraepithelialDiagnosticOnce,
      malignantDiagnosticTreatment,
      intraepithelialDiagnosticTreatment,
      hospitalizationDailyAmount,
      hospitalizationDailyAmountDays,
      surgery,
      leavingHospitalOnce,
      cancerHospitalDailyFee,
      cancerHospitalDailyFeeDays,
      commutingOnce,
      cancerAdvancedMedical,
      radiotherapy,
      anticancerDrugTreatment,
      femaleDiseases,
      paymentExemptionCancer,
    ]
  );
  const deathPDProps = useMemo(
    () => ({
      wholeDeathBenefit,
      termDeathBenefit,
      cancelRepayment: cancelRepaymentTermDeath,
      livingNeeds: livingNeedsTermDeath,
      paymentExemption: paymentExemptionTermDeath,
    }),
    [
      wholeDeathBenefit,
      termDeathBenefit,
      cancelRepaymentTermDeath,
      livingNeedsTermDeath,
      paymentExemptionTermDeath,
    ]
  );
  const wholeDeathPDProps = useMemo(
    () => ({
      wholeDeathBenefit,
      termDeathBenefit,
      cancelRepayment: cancelRepaymentWholeDeath,
      livingNeeds: livingNeedsWholeDeath,
      paymentExemption: paymentExemptionWholeDeath,
    }),
    [
      wholeDeathBenefit,
      termDeathBenefit,
      cancelRepaymentWholeDeath,
      livingNeedsWholeDeath,
      paymentExemptionWholeDeath,
    ]
  );
  const incomeProtectionPDProps = useMemo(
    () => ({
      monthlyBenefit,
      paymentMinGuaranteeTerm,
      cancelRepayment,
      livingNeeds,
      paymentExemption: paymentExemptionIncomeProtection,
    }),
    [
      monthlyBenefit,
      paymentMinGuaranteeTerm,
      cancelRepayment,
      livingNeeds,
      paymentExemptionIncomeProtection,
    ]
  );
  const setSearchConditionProps = {
    setTypeOfInsurance,
    setMaxBudget,
    setUnderwritingEasing,
    setIntentionRecommendInsurance,
    setOrder,
    setAge,
    setSex,
    setHospitalizationGuarantee,
    setMalignantDiagnosticOnce,
    setWholeDeathBenefit,
    setTermDeathBenefit,
    setMonthlyBenefit,
    executeSearch,
  };
  const cartProps = {
    setFocusedIndex,
    insuranceListInCart,
    setInsuranceListInCart,
    insuranceIdListInCart,
    setInsuranceIdListInCart,
  };

  useEffect(() => {
    localStorage.setItem(
      "searchCondition",
      JSON.stringify(searchConditionProps)
    );
  }, [searchConditionProps]);

  const switchResponseByTypeOfInsurance = useCallback(
    (typeOfInsurance: number): InsuranceRankResponse | undefined => {
      switch (typeOfInsurance) {
        case 1:
          return medicalInsuranceResponse;
        case 2:
          return cancerInsuranceResponse;
        case 3:
          return wholeDeathInsuranceResponse;
        case 4:
          return termDeathInsuranceResponse;
        case 5:
          return incomeProtectionInsuranceResponse;
        default:
          return cancerInsuranceResponse;
      }
    },
    [
      medicalInsuranceResponse,
      cancerInsuranceResponse,
      wholeDeathInsuranceResponse,
      termDeathInsuranceResponse,
      incomeProtectionInsuranceResponse,
    ]
  );

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, LOADING_TIMEOUT);
  }, [
    medicalInsuranceResponse,
    cancerInsuranceResponse,
    wholeDeathInsuranceResponse,
    termDeathInsuranceResponse,
    incomeProtectionInsuranceResponse,
    cancerInsuranceResponse,
    typeOfInsurance,
    switchResponseByTypeOfInsurance,
  ]);

  return (
    <>
      {isPopupOpen || isCartOpen ? <BlackFilter /> : <></>}
      {isLoading && <LoadingPopup title={"検索中です..."} />}
      <Header setIsCartOpen={setIsCartOpen} />
      <MobileEstimateResultButton setIsCartOpen={setIsCartOpen} />
      <div className="contentsnew searchConditionSectionWrap">
      <SearchCondition
          {...searchConditionProps}
          {...setSearchConditionProps}
        />
      </div>
      <div className="contents">
        {(() => {
          switch (typeOfInsurance) {
            case 1:
              return (
                <TableMedical
                  {...searchConditionProps}
                  {...cartProps}
                  {...medicalPDProps}
                  setHospitalizationGuarantee={setHospitalizationGuarantee}
                  paymentExemption={paymentExemption}
                  setPaymentExemption={setPaymentExemption}
                  response={medicalInsuranceResponse}
                  setIsPopupOpen={setIsPopupOpen}
                  setHospitalizationGuaranteeDays={
                    setHospitalizationGuaranteeDays
                  }
                  setHospitalizationOnce={setHospitalizationOnce}
                  setSurgeryGuarantee={setSurgeryGuarantee}
                  setCommutingDailyAmount={setCommutingDailyAmount}
                  setCommutingDailyAmountDays={setCommutingDailyAmountDays}
                  setCommutingOnce={setCommutingOnce}
                  setAdvancedMedical={setAdvancedMedical}
                  setCancerSpecialContractAmount={
                    setCancerSpecialContractAmount
                  }
                  setSpecifiedDiseaseInsurance={setSpecifiedDiseaseInsurance}
                  setDeathSpecialContractAmount={setDeathSpecialContractAmount}
                  setCongratulatoryMoney={setCongratulatoryMoney}
                  triggerMedicalInsuranceRankAPI={
                    triggerMedicalInsuranceRankAPI
                  }
                  setTriggerMedicalInsuranceRankAPI={
                    setTriggerMedicalInsuranceRankAPI
                  }
                  checkedRankList={checkedRankList}
                  setCheckedRankList={setCheckedRankList}
                  isContentsColumnRendered={isContentsColumnRendered}
                  setIsContentsColumnRendered={setIsContentsColumnRendered}
                />
              );
            case 2:
              return (
                <TableCancer
                  {...searchConditionProps}
                  {...cartProps}
                  {...cancerPDProps}
                  setPaymentExemption={setPaymentExemptionCancer}
                  response={cancerInsuranceResponse}
                  setIsPopupOpen={setIsPopupOpen}
                  setMalignantDiagnosticOnce={setMalignantDiagnosticOnce}
                  setIntraepithelialDiagnosticOnce={setIntraepithelialDiagnosticOnce}
                  setMalignantDiagnosticTreatment={setMalignantDiagnosticTreatment}
                  setIntraepithelialDiagnosticTreatment={setIntraepithelialDiagnosticTreatment}
                  setHospitalizationDailyAmount={setHospitalizationDailyAmount}
                  setHospitalizationDailyAmountDays={setHospitalizationDailyAmountDays}
                  setSurgery={setSurgery}
                  setLeavingHospitalOnce={setLeavingHospitalOnce}
                  setCancerHospitalDailyFee={setCancerHospitalDailyFee}
                  setCancerHospitalDailyFeeDays={setCancerHospitalDailyFeeDays}
                  setCommutingOnce={setCommutingOnce}
                  setCancerAdvancedMedical={setCancerAdvancedMedical}
                  setRadiotherapy={setRadiotherapy}
                  setAnticancerDrugTreatment={setAnticancerDrugTreatment}
                  setFemaleDiseases={setFemaleDiseases}
                  triggerCancerInsuranceRankAPI={triggerCancerInsuranceRankAPI}
                  setTriggerCancerInsuranceRankAPI={
                    setTriggerCancerInsuranceRankAPI
                  }
                  checkedRankList={checkedRankList}
                  setCheckedRankList={setCheckedRankList}
                  isContentsColumnRendered={isContentsColumnRendered}
                  setIsContentsColumnRendered={setIsContentsColumnRendered}
                />
              );
            case 3:
              return (
                <TableWholeDeath
                  {...searchConditionProps}
                  {...cartProps}
                  {...wholeDeathPDProps}
                  paymentExemption={paymentExemptionWholeDeath}
                  setPaymentExemption={setPaymentExemptionWholeDeath}
                  response={wholeDeathInsuranceResponse}
                  setIsPopupOpen={setIsPopupOpen}
                  setFocusedIndex={setFocusedIndex}
                  setWholeDeathBenefit={setWholeDeathBenefit}
                  setCancelRepayment={setCancelRepaymentWholeDeath}
                  setLivingNeeds={setLivingNeedsWholeDeath}
                  triggerWholeDeathInsuranceRankAPI={
                    triggerWholeDeathInsuranceRankAPI
                  }
                  setTriggerWholeDeathInsuranceRankAPI={
                    setTriggerWholeDeathInsuranceRankAPI
                  }
                  checkedRankList={checkedRankList}
                  setCheckedRankList={setCheckedRankList}
                  isContentsColumnRendered={isContentsColumnRendered}
                  setIsContentsColumnRendered={setIsContentsColumnRendered}
                />
              );
            case 4:
              return (
                <TableTermDeath
                  {...searchConditionProps}
                  {...cartProps}
                  {...deathPDProps}
                  paymentExemption={paymentExemptionTermDeath}
                  setPaymentExemption={setPaymentExemptionTermDeath}
                  response={termDeathInsuranceResponse}
                  setIsPopupOpen={setIsPopupOpen}
                  setTermDeathBenefit={setTermDeathBenefit}
                  setCancelRepayment={setCancelRepaymentTermDeath}
                  setLivingNeeds={setLivingNeedsTermDeath}
                  triggerTermDeathInsuranceRankAPI={
                    triggerTermDeathInsuranceRankAPI
                  }
                  setTriggerTermDeathInsuranceRankAPI={
                    setTriggerTermDeathInsuranceRankAPI
                  }
                  checkedRankList={checkedRankList}
                  setCheckedRankList={setCheckedRankList}
                  isContentsColumnRendered={isContentsColumnRendered}
                  setIsContentsColumnRendered={setIsContentsColumnRendered}
                />
              );
            case 5:
              return (
                <TableIncomeProtection
                  {...searchConditionProps}
                  {...cartProps}
                  {...incomeProtectionPDProps}
                  setMonthlyBenefit={setMonthlyBenefit}
                  response={incomeProtectionInsuranceResponse}
                  setIsPopupOpen={setIsPopupOpen}
                  setPaymentExemption={setPaymentExemptionIncomeProtection}
                  setCancelRepayment={setCancelRepayment}
                  setLivingNeeds={setLivingNeeds}
                  setPaymentMinGuaranteeTerm={setPaymentMinGuaranteeTerm}
                  triggerIncomeProtectionInsuranceRankAPI={
                    triggerIncomeProtectionInsuranceRankAPI
                  }
                  setTriggerIncomeProtectionInsuranceRankAPI={
                    setTriggerIncomeProtectionInsuranceRankAPI
                  }
                  checkedRankList={checkedRankList}
                  setCheckedRankList={setCheckedRankList}
                  isContentsColumnRendered={isContentsColumnRendered}
                  setIsContentsColumnRendered={setIsContentsColumnRendered}
                />
              );
          }
        })()}
        
        {isPopupOpen ? (
          <AddInsuranceToCartPopup
            insurance={
              switchResponseByTypeOfInsurance(typeOfInsurance)?.insurances?.[
                focusedIndex
              ] ?? undefined
            }
            setIsPopupOpen={setIsPopupOpen}
          />
        ) : (
          <></>
        )}
        {isCartOpen ? (
          <EstimateResult setIsCartOpen={setIsCartOpen} {...cartProps} />
        ) : (
          <></>
        )}
        <section className="notesSection">
          <p>この表は商品内容の全てを記載しているわけではないため、必ず契約概要などをご覧になり、商品内容を全般的にご確認ください。また、保険料だけではなく保障内容など、他の要素もご検討ください。上記の保険料は被保険者が{sex === 1 ? "男性" : "女性"}で、{age}歳という前提ですので、実際に適用される保険料は保険会社に確認する必要があります。</p>
          <ul className="aboutThisSite">
            <li> 保険商品の内容についての詳細は、各商品ページをご覧ください。</li>
            <li>
              当サイトでは、すべての保険の商品を掲載しているものではございません。保険代理店フィナンシャル・エージェンシーが取扱可能な保険商品を比較して掲載しております。
            </li>
            <li>
              当サイトの保険商品一覧に記載されている内容は、個人用の保険となります。
            </li>
            <li>
              当ページのランキングは、選択された保障内容や主契約ならびにその他算出条件等を反映させた上で保険料が安い順でランキング表記しております。なお、当サイトの保険商品一覧は、保険商品の内容が全て記載されているものではなく、あくまで比較をしていただく上での参考情報としてご覧ください。
            </li>
            <li>
              保険商品一覧に記載されている内容は、条件等により適用されない場合があります。保険商品の内容については、必ず各保険会社が提供する「契約概要」「注意喚起情報」「ご契約のしおり抜粋」または「ご契約のしおり/約款」、また保険代理店からの案内にてご確認ください。
            </li>
          </ul>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default Comparison;
