/** @jsxImportSource @emotion/react */
// import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import "../../styles/pages/resultSimple.scss";
import { RequiredAmount } from "./components/RequiredAmount";
import { AnnualPremium } from "./components/AnnualPremium";
import { EstimatePremium } from "./components/EstimatePremium";
import { MoreDetails } from "./components/MoreDetails";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { partnerDataStorage } from "../../utils/storage";

const ResultSimple = () => {
  useDocumentTitle("保デジWEB-かんたん診断");
  const partnerData = partnerDataStorage.get();
  
  const [isDispFpButton, SetIsDispFpButton] = useState<boolean>(true);
  const [isDispSaveButton, SetIsDispSaveButton] = useState<boolean>(true);
  const [isDispComparisonButton, SetIsDispComparisonButton] = useState<boolean>(true);
  
  useEffect(() => {
    if (!partnerData){
      let isDispFpButtonTemp = (process.env.REACT_APP_FP_BUTTON !== 'false') ? true: false;
      SetIsDispFpButton(isDispFpButtonTemp ?? false);
      
      let isDispSaveButtonTemp = (process.env.REACT_APP_SAVE_BUTTON !== 'false') ? true: false;
      SetIsDispSaveButton(isDispSaveButtonTemp ?? false);
      
      let isDispComparisonButtonTemp = (process.env.REACT_APP_COMPARISON_BUTTON !== 'false') ? true: false;
      SetIsDispComparisonButton(isDispComparisonButtonTemp ?? false);
    } else {
      SetIsDispFpButton(partnerData.fpButtonDisp ?? false);
      SetIsDispSaveButton(partnerData.saveButtonDisp ?? false);
      SetIsDispComparisonButton(partnerData.comparisonButtonDisp ?? false);
    }
  }, [SetIsDispFpButton, SetIsDispSaveButton, partnerData]);
  
  return (
    <div id="result-simple">
      <main className="simpleAssessmentResult_layout">
        <section className="simpleAssessmentResult_title">
          <h1 className="assessmentTitle_header typographyTextTitle typographyPrimaryColor textsizeadd">
            かんたん診断結果ページ
          </h1>
        </section>
        <RequiredAmount />
        <AnnualPremium />
        <EstimatePremium isDispComparisonButton={isDispComparisonButton} />
        <MoreDetails 
          isDispFpButton={isDispFpButton}
          isDispSaveButton={isDispSaveButton}
        />
        {/* <section className="fixedBottomButton">
          <Link to="/detail/">もっと詳しく診断する</Link>
        </section>
        <section className="fixedBottomButton2">
          <Link to="/detail/">もっと詳しく診断する</Link>
        </section> */}
      </main>
    </div>
  );
};
export default ResultSimple;
