import { useState } from "react";
import { ArrowButton, ScrollBackBtn } from "../../../components/atoms/Button";
interface Props {
  current: number;
  currentInsuranceMonthTerm: number | undefined;
  setCurrentInsuranceMonthTerm: (value: number | undefined) => void;
  onPrev: (value: number) => void;
}

export const Question7 = ({
  current,
  currentInsuranceMonthTerm,
  setCurrentInsuranceMonthTerm,
  onPrev,
}: Props) => {
  const [value, setValue] = useState<string | undefined>(
    currentInsuranceMonthTerm?.toString()
  );
  const [errors, setErrors] = useState<string[]>([]);

  // const errors = useMemo(() => {
  //   if (value === "") {
  //     return ["加入期間を入力してください"];
  //   }
  //   return [];
  // }, [value]);

  const validate = (value: string) => {
    const err: string[] = [];
    if (value === "") {
      err.push("加入期間を入力してください");
      // return false;
    }

    if (parseInt(value) > 1000) {
      err.push("1000以下を入力してください");
      // return false;
    }
    setErrors(err);
    return true
  };


  const onChange = (value: string) => {
    if (!isNaN(parseInt(value)) || value === "") {
      validate(value);
      setValue(value);
    }
  };

  const onNext = () => {
    if(!validate(value ?? "")) return;
    setCurrentInsuranceMonthTerm(value ? parseInt(value) : 0);
  };

  return (
    <section
      className={`cardContainer fade-in-slide-up ${
        current === 7 ? "currentCard" : ""
      }`}
      id="question-7"
    >
      <ScrollBackBtn onClick={() => onPrev(6)} />
      <div className="cardWrapper questionTxt">
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            7&nbsp;/&nbsp;8
          </span>
          <h2 className="typographyAccentOrange typographyNumTextProgress">
            加入期間は
          </h2>
        </div>
        <fieldset className={errors.length > 0 ? "error" : ""}>
          <div data-prefix="およそ" data-suffix="ヶ月">
            <input
              type="text"
              inputMode="numeric"
              aria-invalid="true"
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
          <div className="validation">
            {errors.map((error) => {
              return error;
            })}
          </div>
          <div className="typographyAlignRight mt-16">
            覚えていない場合は
            <span
              className="hasSmokedLink"
              onClick={() => setCurrentInsuranceMonthTerm(undefined)}
            >
              回答をスキップ
            </span>
          </div>
        </fieldset>
      </div>
      <div className="card-align">
        <ArrowButton onClick={onNext} />
      </div>
    </section>
  );
};
