import { useMemo } from "react";
import { numFormatter } from "../../../utils/formatter";
import { simpleDiagnoseStorage } from "../../../utils/storage";
import { CompensationChart } from "./CompensationChart";
import { Link } from "react-router-dom";

export const RequiredAmount = () => {
  const { result: simpleDiagnose } = useMemo(
    () => simpleDiagnoseStorage.get(),
    []
  );

  return (
    <section>
      <div className="cardWrapper">
        <div className="cardContainer">
          <h2 className="typographyTextTitle typographyPrimaryColor">
            必要保障額は…
          </h2>
          <p className="typographyTextSupport typographySecondaryColor mb-4">
            用意しておくべき保険金の目安
          </p>
          <div className="mb-4 compensationChartContainer">
            <CompensationChart
              chartData={simpleDiagnose.compensation_by_age ?? []}
            />
          </div>
          <div className="compensationAmount_estimate">
            {simpleDiagnose.big_events?.map((data, index) => (
              <div
                className="compensationAmount_section"
                key={`bigEvent_${index}`}
              >
                <h3 className="typographyTextBody typographySecondaryColor mr-18 fw-bold">
                  {data.label}
                </h3>
                <h4>
                  <span className="typographyNumTextLarge typographyPrimaryColor">
                  {parseInt(data.value!) / 10000 === 0 ? 200 : numFormatter(parseInt(data.value!) / 10000)}万
                  </span>
                  <span className="compensationAmount_yen">円</span>
                </h4>
              </div>
            ))}
          </div>
          <p
            className="
              compensationAmount_supportText
              typographyTextSupport
              typographySecondaryColor
            "
          >
            {
            simpleDiagnose.who_assured === 1 ? 
            'あなた' :
            simpleDiagnose.who_assured === 2 ? 
            '旦那さま' :
            simpleDiagnose.who_assured === 3 ? 
            '奥さま' :
            '診断したい方'
            }
            に万が一のこと（死亡）があった場合、大きなまとまったお金が必要になります。
          </p>
          <div className="vstack center moreEstimateButton">
          <Link to="/detail/"><button type="button" className="p-3 btn btn-blue">もっと詳しく診断する</button></Link>
          </div>
          
        </div>
      </div>
    </section>
  );
};
