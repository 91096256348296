import { useQuery } from "@tanstack/react-query";
import { Api } from "../openApi";
import { GetApiIncomeProtectionInsurancesInsuranceCode200Response } from "../openApi/api";

export const useIncomeProtectionInsuranceDetail = (
  insuranceCode: string,
  setResponse: (
    response: GetApiIncomeProtectionInsurancesInsuranceCode200Response
  ) => void
) => {
  return useQuery(
    ["getIncomeProtectionInsuranceDetail"],
    async () => {
      const { data } = await Api.getApiIncomeProtectionInsurancesInsuranceCode(
        insuranceCode
      );
      setResponse(data);
      return data;
    },
    {
      enabled: false,
    }
  );
};
