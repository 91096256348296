import { useMemo, useState } from "react";
import { Button, Figure, Offcanvas, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { simpleDiagnoseStorage } from "../../../utils/storage";

interface Props {
  isDispSaveButton: boolean | undefined;
  isDispFpButton: boolean | undefined;
}

export const MoreDetails = ({
  isDispFpButton = true,
  isDispSaveButton = true
}: Props) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { result } = useMemo(() => simpleDiagnoseStorage.get(), []);

  return (
    <div className="fixedBottomButtonContainer">
    <section className="fixedBottomButtonnew">
      <Stack gap={3}>
        {isDispSaveButton ? (
          <Button
            variant="save"
            className="p-3"
            onClick={handleShow}
            style={{
              borderRadius: 12,
              fontSize:14,
            }}
          >
            診断結果を保存する
          </Button>
        ) : null}
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="bottom"
          className="saveDiagnosisResult_Offcanvas"
          style={{
            height: "auto",
          }}
        >
          <Offcanvas.Header className="justify-content-center">
            <Offcanvas.Title className="typographyTextBodyBold typographySecondaryColor typographyAlignCenter">
              保存する
              <br />
              <span className="typographyAnswerBtnDescription typographyAlignCenter mt-3">
                お好きな方法で診断結果をお送りいたします
              </span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Stack
              direction="horizontal"
              gap={4}
              className="justify-content-center"
            >
              <Figure
                onClick={() =>
                  navigate(
                    `/download-sms?diagnosCode=${result.diagnos_code}&fromComparison=false`
                  )
                }
              >
                <Figure.Image
                  width={87}
                  height={87}
                  alt="SMS"
                  src="/img/smsIcon.svg"
                />
                <Figure.Caption className="text-center">SMS</Figure.Caption>
              </Figure>
              <Figure
                onClick={() =>
                  navigate(
                    `/download-mail?diagnosCode=${result.diagnos_code}&fromComparison=false`
                  )
                }
              >
                <Figure.Image
                  width={87}
                  height={87}
                  alt="mail"
                  src="/img/mailIcon.svg"
                />
                <Figure.Caption className="text-center">メール</Figure.Caption>
              </Figure>
            </Stack>
          </Offcanvas.Body>
        </Offcanvas>
      </Stack>
      </section>
      <section className="fixedBottomButton2">
        {isDispFpButton ? (
          <Link
            to={`/download-fp?diagnosCode=${result.diagnos_code}&fromComparison=false`}
            className="m-auto"
            style={{ maxWidth: "100%" }}
          >
            <Button
              variant="outline-secondary"
              className="p-3"
              style={{
                width: "300px",
                maxWidth: "100%",
                borderRadius: 12,
                borderWidth: 2,
                fontSize:14,
              }}
            >
              無料相談・問い合わせ
            </Button>
          </Link>
        ) : null}
      </section>
    </div>
  );
};
