import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Api } from "../openApi";
import { ApiAfterContactResponse } from "../openApi/api";
import { useMemo } from "react";

export const useContactFPAfter = () => {
  const navigate = useNavigate();
  
  const queryParameters = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  
  return useMutation(
    ["postContactFP"],
    async (params: ApiAfterContactResponse) => {
      return Api.postAfterApiContactFp(params);
    },
    {
      onSuccess: () => {
        const type = queryParameters.get("type");
        
        if (!type){
          navigate("/contactafter-complete");
        } else {
          navigate("/saveafter-complete");
        }
      },
      onError: (e) => {
        console.log(e);
      },
    }
  );
};
