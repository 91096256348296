import { ChangeEvent, useEffect, useState } from "react";
import { ArrowButton, ScrollBackBtn } from "../../../components/atoms/Button";
import { jpNumFormatter, numDecryptor } from "../../../utils/formatter";

interface Props {
  current: number;
  income?: string;
  childrenCount?: number;
  setIncome: (value: string | undefined) => void;
  onNext: () => void;
  onPrev: (value: number) => void;
  setCurrentStep: (current: number) => void;
  scrollInto: (value: string) => void;
}

const Question7 = ({ current, income, childrenCount, setIncome, onNext, onPrev, setCurrentStep, scrollInto}: Props) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [PrevNo, setPrevNo] = useState<number>(7);

  useEffect(() =>{
    setPrevNo((childrenCount) ? 7 : 6);
  }, [childrenCount])

  const validate = (value: string) => {

    if (parseInt(value) > 100000) {
      setErrors(["100,000万円以下を入力してください"]);
      return;
    }
    setErrors([]);
  };

  const onValidateAndNext = () => {
    if (!income){
      setErrors(["年収を入力してください"]);
      return;
    }
    if (parseInt(income) > 100000) {
      setErrors(["100,000万円以下を入力してください"]);
      return;
    }
    setErrors([]);
    onNext();
  };


  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.value === "" || /^-?\d+$/.test(e.target.value)) {
        validate(numDecryptor(e.target.value));
        setIncome(numDecryptor(e.target.value));
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  return (
    <section
      className={`cardContainer fade-in-slide-up ${
        current === 8 ? "currentCard" : ""
      }`}
      id="question-7"
    >
      <ScrollBackBtn onClick={() => {onPrev(PrevNo)}} />
      <div className="cardWrapper questionTxt">
        <div className="titleImage">
          <img src="/img/money.svg" alt="" />
        </div>
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            7&nbsp;/&nbsp;10
          </span>
          <h2 className="typographyTextSubTitle">去年の年収は</h2>
          <span>診断したい方について教えてください</span>
        </div>
        <fieldset className={errors.length > 0 ? "error" : ""}>
          <div data-prefix="およそ" data-suffix="万円">
            <input
              type="text"
              inputMode="numeric"
              aria-invalid="true"
              name="yearlyIncome"
              value={
                !isFocused && income
                  ? jpNumFormatter(parseInt(income), true)
                  : income
              }
              onChange={onChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </div>
          <div className="validation">
            {errors.map((error) => {
              return error;
            })}
          </div>
        </fieldset>
        <div
          className="typographyAlignRight"
          style={{
            marginTop: 16,
          }}
        >
          覚えていない場合は
          <span
            className="hasSmokedLink"
            onClick={() => {
              setIncome(undefined);
              setErrors([]);
              if (current >= 9){
                scrollInto('question-8');
              } else {
                setCurrentStep(9);
                onNext();
              }
            }}
          >
            回答をスキップ
          </span>
        </div>
      </div>
      {current === 8 && (
        <div
          style={{
            marginTop: -14,
            marginRight: 28,
            textAlign: "right",
          }}
        >
          <ArrowButton onClick={() => onValidateAndNext()} />
        </div>
      )}
    </section>
  );
};

export default Question7;
