import { useEffect, useMemo, useState } from "react";
import { ScrollBackBtn } from "../../../components/atoms/Button";
import { GetApiInsuranceCompanies200ResponseInner } from "../../../openApi/api";
import { useInsuranceCompanies } from "../../../query/useInsuranceCompanies";

interface Props {
  current: number;
  currentInsuranceCompanyId: number | undefined;
  setCurrentInsuranceCompanyId: (value: number) => void;
  onPrev: (value: number) => void;
}

export const Question6 = ({
  current,
  currentInsuranceCompanyId,
  setCurrentInsuranceCompanyId,
  onPrev,
}: Props) => {
  const [list, setList] = useState<GetApiInsuranceCompanies200ResponseInner[]>(
    []
  );

  const callback = (data: Array<GetApiInsuranceCompanies200ResponseInner>) => {
    setList(data);
  };

  const { refetch: getInsuranceCompanies } = useInsuranceCompanies({
    callback,
  });

  useEffect(() => {
    getInsuranceCompanies();
  }, [getInsuranceCompanies]);

  const currentCompanyName = useMemo(() => {
    if (list.length === 0 || currentInsuranceCompanyId === undefined) {
      return "";
    }
    return list.find((x) => x.company_id === currentInsuranceCompanyId)
      ?.company_name;
  }, [list, currentInsuranceCompanyId]);

  return (
    <section
      className={`cardContainer fade-in-slide-up ${
        current === 6 && setCurrentInsuranceCompanyId === undefined
          ? "currentCard"
          : ""
      }`}
      id="question-6"
    >
      <ScrollBackBtn onClick={() => onPrev(5)} />
      <div className="cardWrapper">
        <div>
          <span className="typographyAccentOrange typographyNumTextProgress">
            6&nbsp;/&nbsp;8
          </span>
          <h2 className="typographyAccentOrange typographyNumTextProgress">
            保険会社はどちらですか
          </h2>
          <span>
            複数社加入している場合は、最も保険料が高い保険会社を教えてください
          </span>
        </div>
        <fieldset>
          <div data-prefix="" data-suffix="">
            <input
              type="text"
              inputMode="text"
              aria-invalid="true"
              name="currentInsuranceCompany"
              className="quesitonList"
              value={currentCompanyName}
            />
            <div className="currentInsuranceCompanyWrapInput">
              {list.map((company, index) => (
                <button
                  key={`insuranceCp_${index}`}
                  onClick={() =>
                    setCurrentInsuranceCompanyId(company.company_id!)
                  }
                >
                  {company.company_name}
                </button>
              ))}
            </div>
          </div>
        </fieldset>
        <div className="typographyAlignRight mt-16">
          <span
            className="hasSmokedLink typographyBlock"
            onClick={() => setCurrentInsuranceCompanyId(0)}
          >
            加入していない
          </span>
        </div>
      </div>
    </section>
  );
};
