import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Api } from "../openApi";
import { ApiDetailDiagnoseRequest } from "../openApi/api";
import { detailDiagnoseStorage } from "../utils/storage";

export const useDetailDiagnose = () => {
  const navigate = useNavigate();
  return useMutation(
    ["postDetailDiagnose"],
    async (params: ApiDetailDiagnoseRequest) => {
      return Api.postApiDetailDiagnose(params);
    },
    {
      onSuccess: (res) => {
        detailDiagnoseStorage.set({
          request: JSON.parse(res.config.data),
          result: res.data,
        });
        navigate("/result-detail");
      },
      onError: (e) => {
        console.log(e);
      },
    }
  );
};
