import { cartStorage } from "../../utils/storage";
import { InsuranceRankResponseInner } from "./comparison";

interface AddInsuranceToCartProps {
  setIsPopupOpen: (isPopupOpen: boolean) => void;
  insurance_code: string | undefined;
  focusedInsurance: InsuranceRankResponseInner;
  insuranceIdListInCart: string[];
  setInsuranceIdListInCart: (insuranceIdListInCart: string[]) => void;
  insuranceListInCart: InsuranceRankResponseInner[];
  setInsuranceListInCart: (
    insuranceListInCart: InsuranceRankResponseInner[]
  ) => void;
}

export const addInsuranceToCart = ({
  setIsPopupOpen,
  insurance_code,
  focusedInsurance,
  insuranceIdListInCart,
  setInsuranceIdListInCart,
  insuranceListInCart,
  setInsuranceListInCart,
}: AddInsuranceToCartProps) => {
  setIsPopupOpen(true);
  cartStorage.set({
    insuranceIdList: [
      ...(insuranceIdListInCart ? insuranceIdListInCart : []),
      insurance_code || "",
    ],
    insuranceList: [...insuranceListInCart, focusedInsurance],
  });
  const updatedIdListInCart = cartStorage.get().insuranceIdList;
  setInsuranceIdListInCart(updatedIdListInCart);
  const updatedListInCart = cartStorage.get().insuranceList;
  setInsuranceListInCart(updatedListInCart);
};
