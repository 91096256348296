const ArrowUpSvg = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="37"
        y="37"
        width="36"
        height="36"
        rx="18"
        transform="rotate(-180 37 37)"
        fill="white"
      />
      <path
        d="M26 18L19 11L12 18M19 11V27V11Z"
        stroke="var(--main_button_color)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="37"
        y="37"
        width="36"
        height="36"
        rx="18"
        transform="rotate(-180 37 37)"
        stroke="var(--main_button_color)"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ArrowUpSvg;
