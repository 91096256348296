import { useQuery } from "@tanstack/react-query";
import { Api } from "../openApi";
import { GetApiTermDeathInsurancesInsuranceCode200Response } from "../openApi/api";

export const useTermDeathInsuranceDetail = (
  insuranceCode: string,
  setResponse: (
    response: GetApiTermDeathInsurancesInsuranceCode200Response
  ) => void
) => {
  return useQuery(
    ["getTermDeathInsuranceDetail"],
    async () => {
      const { data } = await Api.getApiTermDeathInsurancesInsuranceCode(
        insuranceCode
      );
      setResponse(data);
      return data;
    },
    {
      enabled: false,
    }
  );
};
