
/****************************************************
*/

export const PHONE_DAY_OPEN_DEFAULT = [
  {label: "月", is_open: true},
  {label: "火", is_open: true},
  {label: "水", is_open: true},
  {label: "木", is_open: true},
  {label: "金", is_open: true},
  {label: "土", is_open: true},
  {label: "日", is_open: true},
];

export const PHONE_DAY_OPEN_TEST = [
  {label: "月", is_open: true},
  {label: "火", is_open: true},
  {label: "水", is_open: true},
  {label: "木", is_open: true},
  {label: "金", is_open: true},
  {label: "土", is_open: false},
  {label: "日", is_open: false},
];

export const PHONE_DAY_OPEN_FINANCIAL_AGENCY = [
  {label: "月", is_open: true},
  {label: "火", is_open: true},
  {label: "水", is_open: true},
  {label: "木", is_open: true},
  {label: "金", is_open: true},
  {label: "土", is_open: false},
  {label: "日", is_open: false},
];

export const PHONE_DAY_OPEN_SSNB = [
  {label: "月", is_open: true},
  {label: "火", is_open: true},
  {label: "水", is_open: false},
  {label: "木", is_open: true},
  {label: "金", is_open: true},
  {label: "土", is_open: true},
  {label: "日", is_open: true},
];

/****************************************************
*/

export const PHONE_TIME_DEFAULT = [
  "午前中（10〜12時）",
  "12〜14時",
  "14〜16時",
  "16〜18時",
  "18〜20時半",
];

export const PHONE_TIME_TEST = [
  "午前中（10〜12時）",
  "12〜16時",
  "16〜18時",
  "18〜20時半",
];

export const PHONE_TIME_FINANCIAL_AGENCY = [
  "午前中（10〜12時）",
  "12〜14時",
  "14〜16時",
  "16〜18時",
  "18〜20時半",
];

export const PHONE_TIME_SSNB = [
  "午前中（10〜12時）",
  "12〜14時",
  "14〜16時",
  "16〜18時",
  "18〜20時",
];
