/**  @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import * as txt from "../../../../components/atoms/Typografy";
import { BOOL_OPTIONS, MONTHLY_BENEFIT_OPTIONS } from "./DropdownItem";
import { IncomeProtectionTableRows, setHeightToTargetElement } from "./utils";

interface Props {
  paymentExemption: boolean;
  setPaymentExemption: (paymentExemption: boolean) => void;
  monthlyBenefit: number;
  setMonthlyBenefit: (monthlyBenefit: number) => void;
  cancelRepayment: boolean;
  setCancelRepayment: (cancelRepayment: boolean) => void;
  livingNeeds: boolean;
  setLivingNeeds: (livingNeeds: boolean) => void;
  paymentMinGuaranteeTerm: number;
  setPaymentMinGuaranteeTerm: (paymentMinGuaranteeTerm: number) => void;
  triggerIncomeProtectionInsuranceRankAPI: boolean;
  setTriggerIncomeProtectionInsuranceRankAPI: (_: boolean) => void;
  isContentsColumnRendered: boolean;
  setIsContentsColumnRendered: (isContentsColumnRendered: boolean) => void;
}

export const HeadColumnIncomeProtection = ({
  paymentExemption,
  setPaymentExemption,
  monthlyBenefit,
  setMonthlyBenefit,
  cancelRepayment,
  setCancelRepayment,
  livingNeeds,
  setLivingNeeds,
  paymentMinGuaranteeTerm,
  setPaymentMinGuaranteeTerm,
  triggerIncomeProtectionInsuranceRankAPI,
  setTriggerIncomeProtectionInsuranceRankAPI,
  isContentsColumnRendered,
  setIsContentsColumnRendered,
}: Props) => {
  
  useEffect(() => {
    if (isContentsColumnRendered) {
      IncomeProtectionTableRows.map((selectorName) => {
        setHeightToTargetElement(selectorName);
        return null;
      });
      
      setIsContentsColumnRendered(false);
    }
  }, [
    isContentsColumnRendered,
    setIsContentsColumnRendered,
  ]);
  
  return (
    <>
      <div
        id="monthlyBenefit"
        className="listHead mh162"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>月額給付金</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {MONTHLY_BENEFIT_OPTIONS[monthlyBenefit]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(MONTHLY_BENEFIT_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() => {
                  setMonthlyBenefit(Number(key));
                  setTriggerIncomeProtectionInsuranceRankAPI(
                    !triggerIncomeProtectionInsuranceRankAPI
                  );
                }}
              >
                {MONTHLY_BENEFIT_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="paymentMinGuaranteeTerm"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>支払い最低保証期間</span>
        {/* <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {paymentMinGuaranteeTerm === 0
              ? "指定なし"
              : `${paymentMinGuaranteeTerm}年`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setPaymentMinGuaranteeTerm(0)}>
              指定なし
            </Dropdown.Item>
            {Array.from({ length: 5 }, (_, i) => (
              <Dropdown.Item
                key={i}
                onClick={() => setPaymentMinGuaranteeTerm(i + 1)}
              >
                {i + 1}年
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown> */}
      </div>
      <div
        id="insurancePeriod"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>保険期間</span>
      </div>
      <div
        id="cancelRepayment"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>解約返戻金</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {cancelRepayment ? "あり" : "指定なし"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(BOOL_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() => setCancelRepayment(Number(key) ? true : false)}
              >
                {BOOL_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="paymentExemption"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>保険料払込免除</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {paymentExemption ? "あり" : "指定なし"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setPaymentExemption(false);
              }}
            >
              指定なし
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setPaymentExemption(true);
              }}
            >
              あり
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="livingNeeds"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>リビングニーズ特約</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {livingNeeds ? "あり" : "指定なし"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(BOOL_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() => setLivingNeeds(Number(key) ? true : false)}
              >
                {BOOL_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="attention"
        className="listHead mh50"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>備考</span>
      </div>
      <div
        id="condition"
        className="listHead mh50"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>その他（算出条件）</span>
      </div>
      <div
        id="approvalNumber"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>承認番号</span>
      </div>
    </>
  );
};
