import ImageCard from "../../../components/molecules/ImageCard";

const FpCardList = () => {
  return (
    <div
      className="typographyFontFeaturePalt card-list"
      style={{
        marginBottom: 40,
        display: "flex",
      }}
    >
      <ImageCard image="/img/download/icon_pc.svg" title="相談予約" badge="1">
        予約フォームに必要事項を記入してご予約ください。
      </ImageCard>
      <img src="/img/download/icon_play.svg" alt="" className="connector" />
      <ImageCard
        image="/img/download/icon_document.svg"
        title="現状のヒアリング"
        badge="2"
      >
        担当者より、現状のご不安やご希望をお伺いします。
      </ImageCard>
      <img src="/img/download/icon_play.svg" alt="" className="connector" />
      <ImageCard image="/img/download/icon_chart.svg" title="ご提案" badge="3">
        伺った内容をもとに、お客様に合った保険をご案内します。
      </ImageCard>
    </div>
  );
};

export default FpCardList;
