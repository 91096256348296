import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Api } from "../openApi";
import { ApiContactDocumentRequestRequest } from "../openApi/api";

export const useContactDocument = () => {
  const navigate = useNavigate();
  return useMutation(
    ["postContactDocument"],
    async (params: ApiContactDocumentRequestRequest) => {
      return Api.postApiContactDocumentRequest(params);
    },
    {
      onSuccess: () => {
        navigate("/contact-complete");
      },
      onError: (e) => {
        console.log(e);
      },
    }
  );
};
