/**  @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import * as txt from "../../../../components/atoms/Typografy";
import { BOOL_OPTIONS, TERM_DEATH_BENEFIT_OPTIONS } from "./DropdownItem";
import { TermDeathTableRows, setHeightToTargetElement } from "./utils";

interface Props {
  paymentExemption: boolean;
  setPaymentExemption: (paymentExemption: boolean) => void;
  deathBenefit: number;
  setDeathBenefit: (deathBenefit: number) => void;
  cancelRepayment: boolean;
  setCancelRepayment: (cancelRepayment: boolean) => void;
  livingNeeds: boolean;
  setLivingNeeds: (livingNeeds: boolean) => void;
  triggerTermDeathInsuranceRankAPI: boolean;
  setTriggerTermDeathInsuranceRankAPI: (_: boolean) => void;
  isContentsColumnRendered: boolean;
  setIsContentsColumnRendered: (isContentsColumnRendered: boolean) => void;
}

export const HeadColumnTermDeath = ({
  paymentExemption,
  setPaymentExemption,
  deathBenefit,
  setDeathBenefit,
  cancelRepayment,
  setCancelRepayment,
  livingNeeds,
  setLivingNeeds,
  triggerTermDeathInsuranceRankAPI,
  setTriggerTermDeathInsuranceRankAPI,
  isContentsColumnRendered,
  setIsContentsColumnRendered,
}: Props) => {
  
  useEffect(() => {
    if (isContentsColumnRendered) {
      TermDeathTableRows.map((selectorName) => {
        setHeightToTargetElement(selectorName);
        return null;
      });
      
      setIsContentsColumnRendered(false);
    }
  }, [
    isContentsColumnRendered,
    setIsContentsColumnRendered,
  ]);
  
  return (
    <>
      <div
        id="deathBenefit"
        className="listHead mh162"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>死亡保障金額</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {TERM_DEATH_BENEFIT_OPTIONS[deathBenefit]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(TERM_DEATH_BENEFIT_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() => {
                  setDeathBenefit(Number(key));
                  setTriggerTermDeathInsuranceRankAPI(
                    !triggerTermDeathInsuranceRankAPI
                  );
                }}
              >
                {TERM_DEATH_BENEFIT_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="cancelRepayment"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>解約返戻金</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {cancelRepayment ? "あり" : "指定なし"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(BOOL_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() => setCancelRepayment(Number(key) ? true : false)}
              >
                {BOOL_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="paymentExemption"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>保険料払込免除</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {paymentExemption ? "あり" : "指定なし"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setPaymentExemption(false);
              }}
            >
              指定なし
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setPaymentExemption(true);
              }}
            >
              あり
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="livingNeeds"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>リビングニーズ特約</span>
        <Dropdown>
          <Dropdown.Toggle variant="dropdown" className="listHeadDropdown">
            {livingNeeds ? "あり" : "指定なし"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(BOOL_OPTIONS).map((key, _) => (
              <Dropdown.Item
                key={key}
                onClick={() => setLivingNeeds(Number(key) ? true : false)}
              >
                {BOOL_OPTIONS[Number(key)]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="attention"
        className="listHead mh50"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>備考</span>
      </div>
      <div
        id="condition"
        className="listHead mh50"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>その他（算出条件）</span>
      </div>
      <div
        id="approvalNumber"
        className="listHead mh100"
        css={[txt.typographyAlignCenter]}
      >
        <span css={txt.typographyBlock}>承認番号</span>
      </div>
    </>
  );
};
